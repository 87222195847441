import React from "react";
import { observable } from "mobx";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { observer } from "mobx-react";
import BranchDropdown from "../../component/select/branchDropDown";
import { Flex } from "reflexbox";
import { getHeaders, getUserData } from "../../component/constant/Header";
import EmbedComponent from "./EmbedComponent";
import AppDateRangePicker from "../../component/DateRangePicker/AppDateRangePicker";
import moment from "moment";
import PartnerDropdown from "../../component/select/partnerDropDown";

interface IFilters {
  branchName: string[],
  branchCode: string[],
  branchId: string[],
  dateRange: Date[],
  partner: string[],
  currentValuePartner: string
}

@observer
class MonitoringSlaPickup extends React.Component {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  @observable activeTab = 0;

  @observable filters: IFilters = {
    branchName: [this.userData.branchName],
    branchCode: [this.userData.branchCode],
    branchId: [this.userData.branchId],
    dateRange: [new Date(), new Date()],
    partner: [],
    currentValuePartner: ""
  }

  // [2354, 12396, 12395, 12392, 12394, 12393]
  // [2354, 2619, 2620, 2621, 2622, 2623]
  @observable tabs = [
    {
      id: 2551,
      label: "Dashboard",
      url: '',
      token: '',
    },
    {
      id: 2573,
      label: "Total Resi",
      url: '',
      token: '',
    },
    {
      id: 2572,
      label: "On SLA",
      url: '',
      token: '',
    },
    {
      id: 2569,
      label: "Achieve",
      url: '',
      token: '',
    },
    {
      id: 2570,
      label: "Breach Internal",
      url: '',
      token: '',
    },
    {
      id: 2571,
      label: "Breach External",
      url: '',
      token: '',
    },
  ]

  get userData() {
    return getUserData()
  };

  get showFilterCabang() {
    return this.userData.branchCode === '3601001'
  }

  get tab() {
    return this.tabs[this.activeTab]
  }

  componentDidMount(): void {
    this.getUrlEmbed()
  }

  onChangeTab = (index: number) => {
    this.activeTab = index
  }

  getUrlEmbed = () => {
    let params = {
      // branch_code: this.filters.branchCode,
      branch_name: this.filters.branchName,
      start_date: moment(this.filters.dateRange[0]).format("YYYY-MM-DD"),
      end_date: this.filters.dateRange[1] ? moment(this.filters.dateRange[1]).format("YYYY-MM-DD") : moment(this.filters.dateRange[0]).format("YYYY-MM-DD"),
      partner: this.filters.partner
    };
    this.monitoringRestService.get("embed-new/" + this.tab.id, {
      params,
      headers: getHeaders(),
    }).subscribe((response) => {
      this.tab.url = response.url
      this.tab.token = response.token
    });
  }

  filterComponent = () => {
    return (
      <>
        <Flex className="w-full" column>
          {this.showFilterCabang &&
            <div className="form-group">
              <label className="col-md-1">Cabang:</label>
              <div className="col-md-5">
                <BranchDropdown
                  closeMenuOnSelect={false}
                  isMulti
                  style={{ width: '100%' }}
                  id="branch"
                  filters={null}
                  onChange={(value) => {
                    const branchCode = (value || []).map((val) => val.metadata.branchCode)
                    const branchId = (value || []).map((val) => val.metadata.branchId)
                    const branchName = (value || []).map((val) => val.metadata.branchName)
                    this.filters = {
                      ...this.filters,
                      branchCode,
                      branchId,
                      branchName
                    }
                  }}
                  template={`{{branchName}} - ({{branchCode}})`}
                />
              </div>
            </div>
          }

          <div className="form-group">
            <label className="col-md-1">Periode:</label>
            <div className="col-md-5">
              <AppDateRangePicker
                onChange={(e) => {
                  this.filters.dateRange = e.value
                }}
                value={this.filters.dateRange}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-md-1">Partner:</label>
            <div className="col-md-5">
              <div className="flex flex-wrap" style={{ flexDirection: 'column' }} >
                {/* <Chips
                  value={this.filters.partner}
                  onChange={(e) => {
                    this.filters.partner = e.value
                  }}
                /> */}
                <PartnerDropdown
                  closeMenuOnSelect={false}
                  isMulti
                  style={{ width: '100%' }}
                  id="partner"
                  onChange={(values)=>this.filters.partner=(values||[]).map(partner=>partner.label)}
                />
              </div>
            </div>
          </div>
        </Flex>
      </>
    );
  }

  render() {
    return (
      <EmbedComponent
        title={"Monitoring Sla Pickup"}
        filterComponent={this.filterComponent}
        tabs={this.tabs}
        getUrlEmbed={this.getUrlEmbed}
        activeTab={this.activeTab}
        onChangeTab={this.onChangeTab}
      />
    )
  }
}

export default MonitoringSlaPickup;