import React, { Component } from 'react';
import AsyncPaginate from 'react-select-async-paginate';
import _ from 'lodash';
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { getHeaders } from './constant/Header';

@observer
class mainDropDown extends Component<any, any> {
    @observable autoFocus = false;

    asyncPaginate;
    constructor(props: any) {
        super(props);
        this.state = {
            limit: 10,
            page: 1,
            filters: {},
            sortBy: '',
            isClearable: true,
            sortDir: '',
            url: '',
            placeholder: props.placeholder !== undefined ? props.placeholder : 'Pilih',
            template: '',
            selectAllLabel: 'Semua',
            key: '',
            selectAll: false,
            className: '',
        };

        this.asyncPaginate = React.createRef();
    }

    loadData = async () => {
        await this.loadOptions('', [], { page: 1, state: this.state, name: '' });
        this.forceUpdate();
    };

    async loadOptions(search, prevOptions, { page, state, name }) {
        const optionValue: any[] = [];
        const primaryKey = state.key;
        const data: any = {
            limit: state.limit,
            page,
            search,
            filters: state.filters,
            sortBy: state.sortBy,
            sortDir: state.sortDir,
        };
        let hasMore;

        const regexTemplate = /{{(.+?)}}/g;
        const templateKey = state.template.match(regexTemplate);
        const keyList: any[] = [];
        templateKey.map((val, index) => {
            keyList.push(val.replace('}}', '').replace('{{', ''));
        });

        if (page === 1 && state.selectAll) {
            optionValue.push({
                label: state.selectAllLabel,
                value: 0,
            });
        }

        await fetch(state.url, {
            method: 'POST',
            headers: getHeaders(),
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(response => {
                hasMore =
                    response.count > prevOptions.length + data.limit;
                response.data.map(val => {
                    let label = state.template;
                    templateKey.map((item2, key) => {
                        label = label.replace(item2, val[keyList[key]]);
                    });
                    optionValue.push({
                        label,
                        value: _.get(val, primaryKey),
                        metadata: val
                    });
                });
            }).then(() => { })
        return {
            options: optionValue,
            hasMore,
            additional: {
                page: page + 1,
                state,
                name,
            },
        };
    }

    loadOptionsExample = () => {

        const options: any[] = [];
        for (let i = 0; i < 50; ++i) {
            options.push({
                value: i + 1,
                label: `Option ${i + 1}`,
            });
        }
        return {
            options,
            hasMore: false,
        };
    }

    render() {
        const { onChange, id } = this.props;
        const { className } = this.state;
        return (
            <div
                style={{
                    marginTop: '5px',
                    fontSize: '14px',
                    width: (this.props.style && this.props.style.width) ? this.props.style.width : '220px'
                }}>
                <AsyncPaginate
                    {...this.props}
                    loadOptions={this.loadOptions}
                    onChange={onChange}
                    isSearchable={true}
                    ref={this.asyncPaginate}
                    id={id !== 'undefined' ? id : ''}
                    isLoading={true}
                    value={this.props.value}
                    className={className}
                    placeholder={this.state.placeholder}
                    additional={{
                        page: 1,
                        state: this.state,
                    }}
                    debounceTimeout={300}
                />
            </div>
        );
    }
}

export default mainDropDown;
