import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { RefObject } from 'react';
import qs from "qs";
import { Button } from "primereact/button";
import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { DateHelper } from '../../utils/DateHelper';
import { Growl } from 'primereact/growl';
import { Flex } from "reflexbox";
import { Fieldset } from 'primereact/fieldset';
import { TimeWindowPeriodSummaryContainer } from './TimeWindowPeriodSummaryContainer';
import { TimeWindowPeriodFilterContainer } from './TimeWindowPeriodFilterMongoContainer';
@observer
export class TimewindowPeriodContainer extends React.Component<any, any> {
    dataTableFilterService = DependencyContainer.get(DataTableFilterService);
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    data;
    growl;
    dataSource = new DataSource<any>();
    filterParams;
    refFilter: RefObject<TimeWindowPeriodFilterContainer> = React.createRef();
    refSummary: RefObject<TimeWindowPeriodSummaryContainer> = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            exportExcelLoading: false,
            showExportDialog: false,
            filterCollapsed: false,
            sortField: null,
            sortOrder: null
        }
    }

    componentDidMount() {

        const query = this.props.history.location.search
            ? qs.parse(this.props.history.location.search.substr(1)) as any
            : {} as any;

        this.dataSource.onPageChange.subscribe(() => {
            this.loadData();
        });

        // this.filterParams = qs.parse(location.search.replace("?", ""))
        this.dataSource.setPage(1);
    }

    loadData() {
        const fParams = this.refFilter.current!.formValues;

        this.refSummary.current!.loadData(fParams);
    }

    downloadCsv(filterParams: any) {
        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams);
        if (fParams.branch)
            fParams.branchId = fParams.branch.value

        if (fParams.partner)
            fParams.partnerId = fParams.partner.value

        if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);
        if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);

        const resPath = (this.filterParams.slaType! && this.filterParams.slaType === 'external')
            ? 'oversla-csv'
            : 'oversla-csv';

        this.monitoringRestService
            .post(resPath, this.filterParams)
            .subscribe(
                response => {
                    if (response && response.path) {
                        this.openCsv(response.path);
                    }
                },
                () => {
                    let msg = { severity: 'error', summary: 'Error Message', detail: 'Export failed' };
                    this.growl.show(msg);
                },
                () => {
                    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
                    this.growl.show(msg);
                },
            );
    }

    openCsv(url) {
        const win = window.open(url, '_blank');
        if (win) win.focus();
    }

    populateDataSource() {
        if (this.data.data && this.data.data.length) {
            this.dataSource.setData(this.data.data);
            this.dataSource.setTotal(this.data.count);
        } else {
            this.dataSource.setData([]);
            this.dataSource.setTotal(0);
        }
    }

    columnDateFormat = (rowData, column: ColumnProps) => {
        return (
            (rowData[column.field!] != null) ? moment(new Date(rowData[column.field!])).utc().format('DD-MM-YYYY HH:mm') : ''
        );
    }

    detailMonitoringNav = (row, column, url) => {

        url += "&branchName=" + row.branch_name;

        return (
            <a
                href="javascript:void(0);"
                onClick={async () => {
                    window.open(url, "_blank");
                }}
                style={{ textAlign: "center" }}
            >
                {row[column.field!]}
            </a>
        );
    }



    async onSorting(e) {
        if (e && e.sortField) {
            const sortOrder = (this.state.sortField === e.sortField)
                ? (this.state.sortOrder === 1) ? -1 : 1
                : 1;
            await this.setState({ sortField: e.sortField, sortOrder: sortOrder })
        } else {
            await this.setState({ sortField: null, sortOrder: null })
        }
        this.loadData();
    }

    render() {
        return (
            <div>
                <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />

                <Flex className="card w-full">
                    <Flex className="w-full" column>
                        <TimeWindowPeriodFilterContainer ref={this.refFilter} />
                        <br />
                        <Flex>
                            <Button
                                className="p-button-success mr-2"
                                label="Tampilkan"
                                onClick={() => {
                                    this.dataSource.setPage(1);
                                }}
                            />
                        </Flex>
                    </Flex>
                </Flex>
                <div className="row">
                    <div className="col-md-3">
                        <Fieldset legend="Summary" toggleable={true} collapsed={this.state.tujuanCollapsed} onToggle={(e) => this.setState({ tujuanCollapsed: e.value })}>
                            <TimeWindowPeriodSummaryContainer ref={this.refSummary} />
                        </Fieldset>
                    </div>
                </div>
            </div>
        );
    }
}
