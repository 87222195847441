import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";
import QueryString from "qs";
import { Panel } from "primereact/panel";
import { Box } from "@material-ui/core";


@observer
export class MonitoringImageContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      doid: props.match.params.id,
      urlBarang: "",
      urlSignature: "",
      url: "https://sicepatmasterdata.s3.amazonaws.com/"
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};
    console.log(this.state.doid, "doid")
    this.loadImage()
  }


  loadImage() {
    let urlBarang, urlSignature = ""
    const { doid } = this.state

    this.monitoringRestService.get("/Monitoring/image/signature/" + doid).subscribe(res => {
      urlSignature = res[0].pickup_signature
      console.log(urlSignature, res, "urlSignature")
      this.setState({ urlSignature: this.state.url + urlSignature })



    })
    this.monitoringRestService.get("/Monitoring/image/barang/" + doid).subscribe(res => {
      urlBarang = res[0].attachment_path

      console.log(urlBarang, res, "urlBarang")
      this.setState({ urlBarang: this.state.url + urlBarang })
    })


  }

  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );

    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }

    if (fParams.branch)
      fParams.branchId = fParams.branch.value

    if (fParams.partner)
      fParams.partnerId = fParams.partner.value

    fParams.format = "list";
    console.log(fParams, "fparams");

    this.dataSource.loading = true;
    this.monitoringRestService.post("/Monitoring/Pickup/List", fParams).subscribe(
      response => {
        this.data = response;
        this.populateDataSource();
      },
      err => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, null);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("/Monitoring/Pickup/List", fParams).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
        console.log(response, "response");
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }


  downloadPdf(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, null);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
        console.log(response, "response");
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      console.log(this.data.data, "data in data");
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = awbNumber => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };


  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };


  render() {
    return (
      <div>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <Panel
              header="Image"
              toggleable={true}
              collapsed={this.state.filterCollapsed}
              onToggle={e => this.setState({ filterCollapsed: e.value })}
            >
              <Flex wrap w={1}>
                <Flex column style={{ padding: '10px', flex: "0 0 50%" }}>
                  <h2>Barang</h2>
                  <br />
                  {this.state.urlBarang ?
                    <img title="Foto1" style={{ width: "100%", height: "500px" }} src={this.state.urlBarang} />
                    : null}
                </Flex>
                <Flex column style={{ padding: '10px', flex: "0 0 50%" }} >
                  <h2>Signature</h2>
                  <br />
                  {this.state.urlSignature ?
                    <img title="Foto2" style={{ width: "100%", height: "500px" }} src={this.state.urlSignature} />
                    : null}
                </Flex>
              </Flex>
            </Panel>
          </Flex>
        </Flex>

      </div>
    );
  }
}
