import { Injectable } from '../utils/DependencyInjection';
import { HttpClientAxiosInterceptedService } from './HttpClientAxiosInterceptedService';

@Injectable()
export class MonitoringRestService extends HttpClientAxiosInterceptedService {
  constructor() {
    super(`${process.env.REACT_APP_BACKEND_URL!}`);
  }

  GetUrl = () => {
    return process.env.REACT_APP_BACKEND_URL;
  }
}
