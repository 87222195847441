import { observable } from "mobx";
import React, { RefObject } from "react";
import { Tab } from "./interface";
import moment from "moment";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import DateRangePicker from "../../component/DateRangePicker/AppDateRangePicker";
import { observer } from "mobx-react";
import { Formik } from "formik";
import { Flex } from "reflexbox";
import EmbedComponent from "./EmbedComponent";

@observer
class MonitoringProblem extends React.Component {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  @observable activeTab = 0;
  @observable tabs: Tab[] = [
    {
      id: 2822,
      label: "Home",
      url: '',
      token: '',
    },
    {
      id: 2830,
      label: "Paket Unpick",
      url: '',
      token: '',
    },
    {
      id: 2830,
      label: "Paket Unpick Reschedule",
      url: '',
      token: '',
    },
    {
      id: 2830,
      label: "Paket Unpick Not Reschedule",
      url: '',
      token: '',
    },
    {
      id: 2829,
      label: "Paket Tanpa Status",
      url: '',
      token: '',
    },
    {
      id: 2827,
      label: "Paket Pick/Drop Non Manifest",
      url: '',
      token: '',
    },
    {
      id: 2826,
      label: "Paket Pending (XXX)",
      url: '',
      token: '',
    },
    {
      id: 2823,
      label: "Best Overtime",
      url: '',
      token: '',
    },
    {
      id: 2824,
      label: "Not Outbound",
      url: '',
      token: '',
    },
    {
      id: 2828,
      label: "Paket Stuck",
      url: '',
      token: '',
    },
  ];

  initialFilter = {
    tgl_req_pickup: [new Date(), new Date()],
    tgl_manifest: null,
    tgl_pick_or_drop: null,
  };

  refFormik: RefObject<Formik> = React.createRef();

  getUrlEmbed = () => {
    const userData = localStorage.getItem("userData") ? JSON.parse(localStorage.userData) : null;
    if (userData && this.refFormik.current) {
      let branch_code = userData.branchCode;
      let { tgl_req_pickup, tgl_manifest, tgl_pick_or_drop } = this.refFormik.current.state.values

      let params: any = {
        branch_code,
      };

      if (tgl_req_pickup) {
        params.tgl_req_pickup = `${moment(tgl_req_pickup[0]).format("YYYY-MM-DD")}~${tgl_req_pickup[1]?moment(tgl_req_pickup[1]).format("YYYY-MM-DD"):moment(tgl_req_pickup[0]).format("YYYY-MM-DD")}`
      }
      if (tgl_manifest) {
        params.tgl_manifest = `${moment(tgl_manifest[0]).format("YYYY-MM-DD")}~${tgl_manifest[1]?moment(tgl_manifest[1]).format("YYYY-MM-DD"):moment(tgl_manifest[0]).format("YYYY-MM-DD")}`
      }
      if (tgl_pick_or_drop) {
        params.tgl_pick_or_drop = `${moment(tgl_pick_or_drop[0]).format("YYYY-MM-DD")}~${tgl_pick_or_drop[1]?moment(tgl_pick_or_drop[1]).format("YYYY-MM-DD"):moment(tgl_pick_or_drop[0]).format("YYYY-MM-DD")}`
      }

      let tab = this.tabs[this.activeTab]

      if (tab.label === 'Paket Unpick Reschedule') {
        params.reschedule = 'Ya'
      }
      if (tab.label === 'Paket Unpick Not Reschedule') {
        params.reschedule = 'Tidak'
      }
      // console.log(params)
      this.monitoringRestService
        .get("embed-new/" + tab.id, {
          params,
          headers: {
            'Accept': 'application/json',
            "Authorization": "Bearer " + userData.accessToken,
          },
        })
        .subscribe((response) => {
          tab.url = response.url
          tab.token = response.token
        });
    }
  }

  filterComponent = () => {
    return (
      <Formik
        ref={this.refFormik}
        enableReinitialize
        initialValues={this.initialFilter}
        onSubmit={() => { }}
      >
        {(formikProps) => (
          <Flex style={{ width: "100%" }}>
            <Flex column>
              <div className="form-group">
                <label className="col-md-5">Tanggal Request Pickup:</label>
                <Flex row className="col-md-6 items-center">
                  <div>
                    <DateRangePicker
                      onChange={formikProps.handleChange("tgl_req_pickup")}
                      value={formikProps.values.tgl_req_pickup}
                      showButtonBar
                    />
                  </div>
                </Flex>
              </div>
              <div className="form-group">
                <label className="col-md-5">Tanggal Manifest:</label>
                <Flex row className="col-md-6">
                  <DateRangePicker
                    onChange={formikProps.handleChange("tgl_manifest")}
                    value={formikProps.values.tgl_manifest}
                    showButtonBar
                  />
                </Flex>
              </div>
            </Flex>
            <Flex column>
              <div className="form-group">
                <label className="col-md-5">Tanggal Pick/Drop:</label>
                <Flex row className="col-md-6">
                  <DateRangePicker
                    onChange={formikProps.handleChange("tgl_pick_or_drop")}
                    value={formikProps.values.tgl_pick_or_drop}
                    showButtonBar
                  />
                </Flex>
              </div>
            </Flex>
          </Flex>
        )}
      </Formik>
    );
  }

  onChangeTab = (index: number) => {
    this.activeTab = index
  }

  render() {
    return (
      <EmbedComponent
        componentHowTo
        title="Monitoring Problem"
        tabs={this.tabs}
        getUrlEmbed={this.getUrlEmbed}
        filterComponent={this.filterComponent}
        activeTab={this.activeTab}
        onChangeTab={this.onChangeTab}
      />
    )
  }
}

export default MonitoringProblem;