import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";

import qs from "querystring";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import QueryString from "qs";
import { observer } from "mobx-react";
import { Button } from "primereact/button";
import { Observable, Subscription } from "rxjs";
import { tap, map, delay } from "rxjs/operators";
import Loader from "react-loader-spinner";
import { saveAs } from "file-saver";
import { Fieldset } from "primereact/fieldset";
import { Link } from "react-router-dom";

import { observable } from "mobx";
import { AxiosRequestConfig } from "axios";

@observer
export class TimeWindowDetailAwb extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  data;
  unpickSummaryData;
  dt;
  growl;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  filterParams: any;

  loadSubs: Subscription | null = null;
  loadUnpickSubs: Subscription | null = null;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      total: 0,
    };
  }

  componentDidMount() {
    this.filterParams = QueryString.parse(location.search.replace("?", ""));
    if (this.filterParams.name) {
      let statusName = "";
      let title = "";
      switch (this.filterParams.name.toLowerCase()) {
        //summary
        case "summary jumlah resi kemarin": {
          statusName = "spk_yesterday";
          title = "Jumlah Resi Kemarin";
          break;
        }
        case "summary jumlah spk hari ini": {
          statusName = "spk_today";
          title = "Jumlah SPK Hari Ini";
          break;
        }
        case "summary total spk hari ini": {
          statusName = "spk";
          title = "Total Resi Hari Ini";
          break;
        }
        case "summary pick": {
          statusName = "picked";
          title = "Pick";
          break;
        }
        case "summary unpick": {
          statusName = "unpick";
          title = "Unpick";
          break;
        }

        //detail
        case "detail jumlah resi kemarin": {
          statusName = "spk_yesterday";
          title = "Jumlah Resi Kemarin";
          break;
        }
        case "detail jumlah spk hari ini": {
          statusName = "spk_today";
          title = "Jumlah SPK Hari Ini";
          break;
        }
        case "detail total spk hari ini": {
          statusName = "spk";
          title = "Total Resi Hari Ini";
          break;
        }
        case "detail pick": {
          statusName = "picked";
          title = "Pick";
          break;
        }
        case "detail unpick": {
          statusName = "unpick";
          title = "Unpick";
          break;
        }
        case "detail drop": {
          statusName = "drop";
          title = "Drop";
          break;
        }
        case "detail spk assign": {
          statusName = "assign";
          title = "Assign";
          break;
        }
        case "detail spk not assign": {
          statusName = "not_assign";
          title = "Not Assign";
          break;
        }
        case "detail unpick reschedule": {
          statusName = "unpick_reschedule";
          title = "Unpick Reschedule";
          break;
        }

        case "detail unpick not reschedule": {
          statusName = "unpick_not_reschedule";
          title = "Unpick Not Reschedule";
          break;
        }

        //pickup
        case "pickup pick": {
          statusName = "rpu";
          title = "Pick Plan";
          break;
        }

        case "pickup drop": {
          statusName = "rpu_drop";
          title = "Drop Plan";
          break;
        }

        default: {
          statusName = this.filterParams.name;
          break;
        }
      }

      this.filterParams.status = statusName;
      this.filterParams.title = title;
    }

    this.dataSource.rows = 50;
    this.dataSource.setPage(1);

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData(this.filterParams, null);
    });

    this.loadData(this.filterParams, null);
    this.loadUnpickSummary();
  }

  processFilter(filterParams) {
    const filters = Object.assign({}, filterParams);

    if (filterParams.startDate) {
      filters.startDate = DateHelper.formatLocalDateForQueryParam(
        filterParams.startDate
      );
    }

    if (filterParams.endDate) {
      filters.endDate = DateHelper.formatLocalDateForQueryParam(
        filterParams.endDate
      );
    }

    filters.branchId = !!filterParams.branchId ? filterParams.branchId : null;
    filters.partnerId = !!filterParams.partnerId
      ? filterParams.partnerId
      : null;

    filters.customPeriod = !!filterParams.customPeriod
      ? filterParams.customPeriod
      : null;

    return { filters: filters };
  }

  loadData(filterParams: any, timeout: number | null = null) {
    if (this.loadSubs) {
      this.loadSubs.unsubscribe();
      this.loadSubs = null;
    }

    this.filterParams = filterParams;
    const aFilters = this.processFilter(filterParams);

    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = Object.assign({}, pagingParams, sortingParams, aFilters);

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };

    const url = "/time-window-new/detail";
    let obs = this.monitoringRestService.post(url, fParams, axiosConfig);

    if (timeout && timeout > 0) {
      obs = obs.pipe(delay(timeout));
    }

    this.dataSource.loading = true;
    this.loadSubs = obs.subscribe(
      (response) => {
        this.data = response;
        this.setState({ data: response });
        this.populateDataSource();
        this.dataSource.loading = false;
      },
      (error) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  loadUnpickSummary() {
    if (this.loadUnpickSubs) {
      this.loadUnpickSubs.unsubscribe();
      this.loadUnpickSubs = null;
    }

    if (!this.filterParams || this.filterParams.status !== "unpick") {
      this.unpickSummaryData = null;
      return;
    }

    const fParams = this.processFilter(this.filterParams);

    const url = "/time-window-new/summary/unpick";
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };
    let obs = this.monitoringRestService.post(url, fParams, axiosConfig);

    // this.dataSource.loading = true;
    this.loadUnpickSubs = obs.subscribe(
      (response) => {
        this.unpickSummaryData = response;
        // this.dataSource.loading = false;
      },
      (error) => {
        // this.dataSource.loading = false;
      },
      () => {
        // this.dataSource.loading = false;
      }
    );
  }

  resetUnpickFilterParams() {
    if (!this.filterParams) {
      return;
    }

    this.filterParams.unpick_reschedule = null;
    this.filterParams.unpick_reason_id = null;
  }

  onUnpickSummaryClicked(target) {
    this.resetUnpickFilterParams();
    if (target === "reschedule") {
      this.filterParams.unpick_reschedule = true;
      this.filterParams.title = "Unpick | Reschedule";
    } else if (target === "unreschedule") {
      this.filterParams.unpick_reschedule = false;
      this.filterParams.title = "Unpick | Not Reschedule";
    } else {
      this.filterParams.title = "Unpick";
    }

    this.loadData(this.filterParams);
  }

  onUnpickSummaryReasonClicked(reasonId, reasonName) {
    this.resetUnpickFilterParams();
    this.filterParams.unpick_reason_id = reasonId;
    this.filterParams.title = `Unpick | ${reasonName}`;
    this.loadData(this.filterParams);
  }

  openCsv(url) {
    const win = window.open(url, "_blank");
    if (win) win.focus();
  }

  downloadCsv(filterParams: any) {
    this.filterParams = filterParams;
    const fParams = this.processFilter(this.filterParams);
    const self = this;

    const url = "/time-window-new/detail/export";

    this.setState({ exportExcelLoading: true });
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.monitoringRestService.post(url, fParams, axiosConfig).subscribe(
      (response) => {

        this.openCsv(response.url);


        self.growl.show({
          severity: "success",
          summary: "Success Message",
          detail: "Export Success",
        });
      },
      () => {
        self.growl.show({
          severity: "error",
          summary: "Error Message",
          detail: "Export failed",
        });
      },
      () => {
        this.setState({ exportExcelLoading: false });
      }
    );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  awbNumberFilter = (filters) => {
    if (filters.filters.awb_number) {
      this.filterParams.awb_number = filters.filters.awb_number.value;
    } else {
      this.filterParams.awb_number = "";
    }

    this.loadData(this.filterParams, 500);
  };

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    this.loadData(this.filterParams, 300);
  }

  renderUnpickSummaryNoData() {
    return (
      <div>
        {this.state.loadOverSlaData == "load" ? (
          <Loader type="ThreeDots" color="#c8c8c8" height="50" width="50" />
        ) : (
          <p>Tidak ada data</p>
        )}
      </div>
    );
  }

  renderUnpickSummaryRow(title, value, linkParams) {
    return (
      <div>
        <span className="font-bold">{title}: </span>
        <a
          href="javascript:void(0);"
          onClick={() => {
            if (linkParams.reasonId) {
              this.onUnpickSummaryReasonClicked(linkParams.reasonId, title);
            } else if (linkParams.target) {
              this.onUnpickSummaryClicked(linkParams.target);
            }
          }}
        >
          {value}
        </a>
        {/* <Link
          to={{
            pathname: "/admin/time_window_mongo/detail/awb/mongo",
            search: qs.stringify(
              Object.assign({}, this.filterParams, linkParams)
            ),
          }}
          target="_blank"
        >
          {value}
        </Link> */}
      </div>
    );
  }

  renderUnpickSummary() {
    if (!this.filterParams || this.filterParams.status !== "unpick") {
      return null;
    }

    const rescheduleDatas =
      this.unpickSummaryData &&
        this.unpickSummaryData.reschedules &&
        this.unpickSummaryData.reschedules.length > 0
        ? this.unpickSummaryData.reschedules
        : null;

    const unrescheduleDatas =
      this.unpickSummaryData &&
        this.unpickSummaryData.unreschedules &&
        this.unpickSummaryData.unreschedules.length > 0
        ? this.unpickSummaryData.unreschedules
        : null;

    return (
      <div className="row mt-8">
        <div className="col-md-4">
          <Fieldset
            legend="Summary"
            toggleable={true}
            collapsed={this.state.unpickSummaryCollapsed}
            onToggle={(e) => this.setState({ unpickSummaryCollapsed: e.value })}
          >
            {this.unpickSummaryData ? (
              <div>
                {this.renderUnpickSummaryRow(
                  "Total Unpick",
                  this.unpickSummaryData.total,
                  { target: "total" }
                )}
                {this.renderUnpickSummaryRow(
                  "Total Unpick Reschedule",
                  this.unpickSummaryData.totalReschedule,
                  { target: "reschedule" }
                )}
                {this.renderUnpickSummaryRow(
                  "Total Unpick Unreschedule",
                  this.unpickSummaryData.totalUnreschedule,
                  { target: "unreschedule" }
                )}
              </div>
            ) : (
              <div>{this.renderUnpickSummaryNoData()}</div>
            )}
          </Fieldset>
        </div>

        <div className="col-md-4">
          <Fieldset
            legend="Reschedule"
            toggleable={true}
            collapsed={this.state.unpickRescheduleCollapsed}
            onToggle={(e) =>
              this.setState({ unpickRescheduleCollapsed: e.value })
            }
          >
            {rescheduleDatas ? (
              rescheduleDatas.map((d, index) => (
                <div key={index}>
                  {this.renderUnpickSummaryRow(d.reason_name, d.count, {
                    reasonId: d.reason_id,
                  })}
                </div>
              ))
            ) : (
              <div>{this.renderUnpickSummaryNoData()}</div>
            )}
          </Fieldset>
        </div>

        <div className="col-md-4">
          <Fieldset
            legend="Not Reschedule"
            toggleable={true}
            collapsed={this.state.unpickUnrescheduleCollapsed}
            onToggle={(e) =>
              this.setState({ unpickUnrescheduleCollapsed: e.value })
            }
          >
            {unrescheduleDatas ? (
              unrescheduleDatas.map((d, index) => (
                <div key={index}>
                  {this.renderUnpickSummaryRow(d.reason_name, d.count, {
                    reasonId: d.reason_id,
                  })}
                </div>
              ))
            ) : (
              <div>{this.renderUnpickSummaryNoData()}</div>
            )}
          </Fieldset>
        </div>
      </div>
    );
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;
    const title =
      this.filterParams && this.filterParams.title
        ? `STATUS: ${this.filterParams.title}`
        : "-";
    const branch =
      this.filterParams && this.filterParams.branchName
        ? `CABANG: ${this.filterParams.branchName}`
        : "";
    const driver =
      this.filterParams && this.filterParams.employeeName
        ? `SIGESIT: ${this.filterParams.employeeName}`
        : "";

    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        {this.state.exportExcelLoading ? (
          <Loader
            type="Puff"
            color="#00BFFF"
            height={300}
            width={300}
            visible={this.state.exportExcelLoading}
          />
        ) : (
          <div>
            <div className="card">
              <div className="row">
                <div className="col-md-6">
                  <h3>{title}</h3>
                  <h5>{branch}</h5>
                  <h5>{driver}</h5>
                </div>

                <div className="col-md-6">
                  <Button
                    type="button"
                    className="mt-8 p-button-info p-button-raised"
                    style={{ float: "right", marginRight: "10px" }}
                    label="Export To Csv"
                    onClick={() => this.downloadCsv(this.filterParams)}
                  />
                </div>
              </div>

              {this.renderUnpickSummary()}

              <div className="mt-8">
                <DataTable
                  emptyMessage={"Tidak ada data untuk ditampilkan."}
                  ref={(el) => (this.dt = el)}
                  loading={this.dataSource.loading}
                  scrollable={true}
                  scrollHeight="550px"
                  style={{ width: "100%" }}
                  className="mt-4"
                  value={this.dataSource.data}
                  onSort={(e) => {
                    this.onSorting(e);
                  }}
                  onFilter={this.awbNumberFilter}
                  footer={footer}
                  rows={50}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                >
                  <Column
                    field="awb_number"
                    filter
                    filterMatchMode="custom"
                    sortable={true}
                    header="No Resi"
                    body={this.columnAwbNumber}
                    style={{ width: "150px", "vertical-align": "text-top" }}
                  />
                  <Column
                    field="partner_name"
                    header="Partner"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="pickup_request_name"
                    header="Merchant"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="pickup_request_address"
                    header="Alamat"
                    sortable={true}
                    style={{
                      width: "260px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="driver_name"
                    header="SiGesit"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="driver_nik"
                    header="SiGesit NIK"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="branch_name"
                    header="Nama Cabang"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="driver_assign_date"
                    header="Tgl Assign SiGesit"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="branch_assign_date"
                    header="Tgl Assign Cabang"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="pickup_request_type"
                    header="Plan Pickup Method"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="ori_pickup_schedule_date_time"
                    header="Tgl Req. Pickup"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="pickup_schedule_date_time"
                    header="Tgl Schedule Pickup"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />

                  <Column
                    field="pickup_sla_date_time"
                    header="SLA Pickup"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="merchant_pickup_schedule_date_time"
                    header="Merchant Scheduler"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="time_window_str"
                    header="Time Window"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="pop_date"
                    header="TGL POP"
                    sortable={true}
                    filter={false}
                    body={this.columnDateFormat}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="pop_status_name"
                    header="Status POP"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="unpicked_reason_name"
                    header="Reason"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />

                  <Column
                    field="status_name"
                    header="Status Saat Ini"
                    filter={false}
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="updated_time"
                    header="Tgl Status Saat ini"
                    filter={false}
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="is_auto_pop"
                    header="Auto POP"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    filter={false}
                  />
                  <Column
                    field="is_manifested"
                    header="Manifested"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    filter={false}
                  />
                  <Column
                    field="manifested_date"
                    header="TGL Manifest"
                    filter={false}
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                    body={this.columnDateFormat}
                  />
                  <Column
                    field="gabung_paket"
                    header="Gabung Paket"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="out_first_mile"
                    header="Out First Mile"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                  <Column
                    field="oversla"
                    header="Oversla 1x24"
                    sortable={true}
                    style={{
                      width: "130px",
                      textAlign: "center",
                      "vertical-align": "text-top",
                    }}
                  />
                </DataTable>
                <Paginator
                  pageLinkSize={50}
                  totalRecords={this.dataSource.total}
                  first={this.dataSource.offset}
                  rows={this.dataSource.rows}
                  rowsPerPageOptions={this.dataSource.rowsOptions}
                  onPageChange={(e) => this.dataSource.fromPageState(e)}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
