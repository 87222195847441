import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import QueryString from "qs";
import { observer } from "mobx-react";

@observer
export class MonitoringPickupDetailBranchContainer extends React.Component<RouterProps, any> {
    dataTableFilterService = DependencyContainer.get(DataTableFilterService);
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    data;
    growl;
    dataSource = new DataSource<any>();
    refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
    filterParams: any;

    constructor(props) {
        super(props);
        this.state = {
            exportExcelLoading: false,
            showExportDialog: false,
            filterCollapsed: false,
            sortField: null,
            sortOrder: null
        };
    }

    componentDidMount() {
        this.dataSource.onPageChange.subscribe(() => {
            this.loadData(this.filterParams);
        });
        this.filterParams = QueryString.parse(location.search.replace("?", ""))
        this.dataSource.setPage(1);
    }

    loadData(filterParams: any) {
        const pagingParams = {
            limit: this.dataSource.rows,
            page: this.dataSource.page
        };

        const sortingParams = {
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
        };

        const fParams = Object.assign(
            {},
            filterParams,
            pagingParams,
            sortingParams
        );

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        fParams.filterParams = filterParams;

        this.filterParams = filterParams;


        // fParams.fParameters = JSON.stringify(filterParams);


        this.dataSource.loading = true;
        this.monitoringRestService.post(`/Monitoring/pickup/list/detail/driver`, fParams).subscribe(
            response => {
                this.data = response;
                this.populateDataSource();
            },
            err => {
                this.dataSource.loading = false;
            },
            () => {
                this.dataSource.loading = false;
            }
        );
    }

    downloadCsv(filterParams: any) {
        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams);

        fParams.filterParams = this.filterParams;
        fParams.format = "xls";

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        const resPath =
            this.filterParams.slaType! && this.filterParams.slaType === "external"
                ? "over-sla/detail"
                : "over-sla/detail";
        console.log(resPath, "res path")


        this.monitoringRestService.post(resPath, this.filterParams).subscribe(
            response => {
                if (response && response.path) {
                    this.openCsv(response.path);
                }
            },
            () => {
                let msg = {
                    severity: "error",
                    summary: "Error Message",
                    detail: "Export failed"
                };
                this.growl.show(msg);
            },
            () => {
                let msg = {
                    severity: "success",
                    summary: "Success Message",
                    detail: "Export Success"
                };
                this.growl.show(msg);
            }
        );
    }

    openCsv(url) {
        const win = window.open(url, "_blank");
        if (win) win.focus();
    }

    populateDataSource() {
        if (this.data.data && this.data.data.length) {
            this.dataSource.setData(this.data.data);
            this.dataSource.setTotal(this.data.count);
        } else {
            this.dataSource.setData([]);
            this.dataSource.setTotal(0);
        }
    }

    onClickAwbNumber = awbNumber => {
        this.refAwbDetailModal.current!.handleShow(awbNumber);
    };

    columnAwbNumber = (rowData, column: ColumnProps) => {
        return (
            <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
                {rowData[column.field!]}
            </a>
        );
    };

    columnDateFormat = (rowData, column: ColumnProps) => {
        return rowData[column.field!] != null
            ? moment(new Date(rowData[column.field!]))
                .utc()
                .format("DD-MM-YYYY HH:mm")
            : "";
    };

    async onSorting(e) {
        if (e && e.sortField) {
            const sortOrder =
                this.state.sortField === e.sortField
                    ? this.state.sortOrder === 1
                        ? -1
                        : 1
                    : 1;
            await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
        } else {
            await this.setState({ sortField: null, sortOrder: null });
        }

        console.log(e);
        console.log(this.state);
        this.loadData(this.filterParams);
    }

    render() {
        const footer = "Total data: " + this.dataSource.total;

        return (
            <div>
                <AwbDetailModalComponent ref={this.refAwbDetailModal} />
                <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />
                <div className="card">
                    <DataTable
                        emptyMessage={"Tidak ada data untuk ditampilkan."}
                        scrollable={true}
                        scrollHeight="550px"
                        style={{ width: "100%" }}
                        className="mt-4"
                        value={this.dataSource.data}
                        loading={this.dataSource.loading}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        onSort={e => {
                            this.onSorting(e);
                        }}
                        footer={footer}
                    >
                        <Column
                            field="fullname"
                            header="Si Gesit"
                            sortable={true}
                            style={{ width: "130px", "vertical-align": "text-top" }}
                            body={this.columnAwbNumber}
                        />
                        <Column
                            field="total_spk_today"
                            header="Sisa Kemarin"
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_must_assign"
                            header="SPK Hari ini"
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_spk"
                            header="Total SPK hari ini"
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_picked"
                            header="Pick"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_unpicked"
                            header="Unpick"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />

                        <Column
                            field="total_not_popup"
                            header="Tidak POP"
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_dropped"
                            header="Drop"
                            sortable={false}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_unpicked_reschedule"
                            header="Reschedule"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="total_unpicked_not_reschedule"
                            header="Not Reschedule"
                            sortable={true}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
                        />

                        <Column
                            field="total_manifested"
                            header="Manifest"
                            sortable={false}
                            style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}

                        />

                        <Column
                            field="total_not_manifested"
                            header="Not Manifest"
                            sortable={false}
                            style={{ width: "130px", "vertical-align": "text-top" }}
                        />

                        <Column
                            field="total_unfinish"
                            header="Sisa SPK"
                            sortable={false}
                            style={{ width: "130px", "vertical-align": "text-top" }}
                        />

                        <Column
                            field="total_spk_today_plus_drop"
                            header="SPK + Drop"
                            sortable={false}
                            style={{ width: "130px", "vertical-align": "text-top" }}
                        />
                    </DataTable>

                    <Paginator
                        totalRecords={this.dataSource.total}
                        first={this.dataSource.offset}
                        rows={this.dataSource.rows}
                        rowsPerPageOptions={this.dataSource.rowsOptions}
                        onPageChange={e => this.dataSource.fromPageState(e)}
                    />
                </div>
            </div>
        );
    }
}
