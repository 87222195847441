import { observer } from "mobx-react";
import { Button } from "primereact/button";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import {
  ITimeWindowListFilterContainerValue,
  TimeWindowFilterContainer,
} from "./TimeWindowFilterContainer";
import { TabView, TabPanel } from "primereact/tabview";
import { TimeWindowSummaryContainer } from "./TimeWindowSummaryContainer";
import { TimeWindowBranchSummaryContainer } from "./TimeWindowBranchSummaryContainer";
import { DateHelper } from "../../utils/DateHelper";

export interface ITimeWindowListContainerQuery {
  filters: ITimeWindowListFilterContainerValue;
}

@observer
export class TimeWindowContainer extends React.Component<RouterProps, any> {
  refFilter: RefObject<TimeWindowFilterContainer> = React.createRef();
  refSummary: RefObject<TimeWindowSummaryContainer> = React.createRef();
  refBranchSummary: RefObject<TimeWindowBranchSummaryContainer> = React.createRef();

  initLoaded: boolean = false;

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
      activeTabIndex: 1,
    };

    this.loadData = this.loadData.bind(this);
    this.changeTab = this.changeTab.bind(this);
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
      }
    }
  }

  private processFilter(filterParams) {
    if (!filterParams) filterParams = {};
    const fParams: any = {};

    const strCurrDate = DateHelper.getCurrentWibMomentTime().format(
      "YYYY-MM-DD"
    );
    if (!!filterParams.startDate) {
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        !!filterParams.startDate ? filterParams.startDate : strCurrDate
      );
    }

    if (!!filterParams.timeWindowStartHour)
      fParams.timeWindowStartHour = !!filterParams.timeWindowStartHour
        ? filterParams.timeWindowStartHour
        : null;
    if (!!filterParams.rpuPickupToday)
      fParams.rpuPickupToday = !!filterParams.rpuPickupToday ? filterParams.rpuPickupToday : null;

    console.log(filterParams.partner, "filter partner");
    if (!!filterParams.partner && !!filterParams.partner.value)
      fParams.partnerId =
        !!filterParams.partner && !!filterParams.partner.value
          ? filterParams.partner.value
          : null;

    if (!!filterParams.province && !!filterParams.province.value)
      fParams.provinceId = !!filterParams.province
        ? filterParams.province.value
        : null;


    if (!!filterParams.branch && !!filterParams.branch.value)
      fParams.branchId = !!filterParams.branch ? filterParams.branch.value : null;

    if (!!filterParams.city && !!filterParams.city.value)
      fParams.cityId = !!filterParams.city ? filterParams.city.value : null;

    console.log(fParams, "fparams");
    return fParams;
  }

  loadData(filterParams: any) {
    const fParams = this.processFilter(
      !!filterParams ? filterParams : this.refFilter.current!.formValues
    );

    if (this.refSummary.current!) this.refSummary.current!.reset();
    if (this.refBranchSummary.current!) this.refBranchSummary.current!.reset();

    if ((this.state as any).activeTabIndex === 1) {
      this.refBranchSummary.current!.loadData(fParams);
    } else {
      this.refSummary.current!.loadData(fParams);
    }

    this.initLoaded = true;
  }

  changeTab(tabIndex) {
    const initLoaded = this.initLoaded;
    this.setState({ activeTabIndex: tabIndex }, () => {
      if (initLoaded) {
        if (tabIndex === 1) {
          if (!this.refBranchSummary.current!.loaded) {
            // this.refBranchSummary.current!.loadData(null);
          }
        } else {
          if (!this.refSummary.current!.loaded) {
            // this.refSummary.current!.loadData(null);
          }
        }
      }
    });
    // setTimeout(() => this.loadData(null, false), 500);
  }

  // openCsv(url) {
  //   // const win = window.open(url, '_blank');
  //   // if (win) win.focus();
  //   window.location = url;
  // }

  // downloadPdf(filterParams: any = null) {
  //   this.setState({ exportExcelLoading: true, showExportDialog: true });

  //   const fParams = filterParams
  //     ? Object.assign({}, filterParams)
  //     : Object.assign({}, this.refFilter.current!.formValues);

  //   if (fParams.startDate)
  //     fParams.startDate = DateHelper.formatLocalDateForQueryParam(
  //       fParams.startDate
  //     );
  //   if (fParams.endDate)
  //     fParams.endDate = DateHelper.formatLocalDateForQueryParam(
  //       fParams.endDate
  //     );

  //   fParams.fParameters = JSON.stringify(fParams);
  //   fParams.format = "xls";
  //   this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
  //     (response) => {
  //       if (response) {
  //         this.openCsv(response);
  //       }
  //       console.log(response, "response");
  //     },
  //     () => {
  //       let msg = {
  //         severity: "error",
  //         summary: "Error Message",
  //         detail: "Export failed",
  //       };
  //       this.growl.show(msg);
  //       this.setState({ exportExcelLoading: false, showExportDialog: false });
  //     },
  //     () => {
  //       let msg = {
  //         severity: "success",
  //         summary: "Success Message",
  //         detail: "Export Success",
  //       };
  //       this.growl.show(msg);
  //       this.setState({ exportExcelLoading: false, showExportDialog: false });
  //     }
  //   );
  // }

  render() {
    return (
      <div>
        {/* <AwbDetailModalComponent ref={this.refAwbDetailModal} /> */}

        {/* <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog> */}

        {/* <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} /> */}

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <TimeWindowFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.loadData(null);
                }}
              />
              {/* <Button
                className="p-button-primary mr-2"
                label="Export Csv"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                onClick={() => this.downloadCsv()}
                disabled={this.state.exportExcelLoading}
              /> */}
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <TabView
            activeIndex={this.state.activeTabIndex}
            onTabChange={(e) => {
              this.changeTab(e.index);
            }}
          >
            <TabPanel header="Summary">
              <TimeWindowSummaryContainer ref={this.refSummary} />
            </TabPanel>

            <TabPanel header="Detail">
              <TimeWindowBranchSummaryContainer ref={this.refBranchSummary} />
            </TabPanel>
          </TabView>
        </div>
      </div>
    );
  }
}
