import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import CityDropDown from "../../component/select/cityDropDown";

export interface ICapacityPickupFilterContainerValue {
  startDate?: Date;
  partner: any;
  branch: any;
  tipeGerai: any;
  city: any;
  province: any;

}

export class CapacityPickupFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: ICapacityPickupFilterContainerValue = {
    startDate: new Date(),
    partner: "",
    branch: "",
    tipeGerai: "",
    city: "",
    province: "",
  };
  userData: any;
  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }


  async componentDidMount() {
    this.userData = await (localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null);

  }
  filterCity = param => {
    let filterList: any = [];
    if (param.province) {
      filterList.push({
        field: 'provinceId',
        operator: 'eq',
        value: param.province.value,
      })
    }
    return filterList;
  };

  filterBranch = (param) => {
    let filterList: any = [];
    if (param.province) {
      filterList.push({
        field: 'provinceId',
        operator: 'eq',
        value: param.province.value,
      })
    }
    if (param.city) {
      filterList.push({
        field: 'cityId',
        operator: 'eq',
        value: param.city.value,
      })
    }
    return filterList;
  };

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  optionTipeGerai = [
    { label: "semua", value: "", selected: true },
    { label: "Branch", value: "branch" },
    { label: "Shop", value: "shop" },
    { label: "Transit", value: "transit" },

  ]

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={e => this.setState({ filterCollapsed: e.value })}
      >
        {this.userData ?
          <Formik
            ref={this.refFormik}
            enableReinitialize
            initialValues={this.userData.isHeadOffice ? this.initialFormValues : {
              startDate: new Date(),
              partner: "",
              branch: !this.userData.isHeadOffice ? { label: this.userData.branchName, value: this.userData.branchId } : "",
              tipeGerai: "",
              city: "",
              province: "",
            }}
            onSubmit={() => { }}
          >
            {formikProps => (
              <Flex wrap w={1}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-3">Periode</label>
                    <div className="col-md-9">
                      <Flex column>
                        <Calendar
                          inputStyle={{ width: "220px" }}
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        />
                      </Flex>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3">Province</label>
                    <div className="col-md-9">
                      <ProvinceDropdown
                        id="branch"
                        isOptionDisabled={() => !this.userData.isHeadOffice}
                        selectAll={this.userData.isHeadOffice}
                        isClearable={this.userData.isHeadOffice}
                        value={formikProps.values.province}
                        onChange={e => {

                          formikProps.setFieldValue("city", "")
                          formikProps.setFieldValue("branch", "")
                          formikProps.setFieldValue("province", "")
                          setTimeout(() => {
                            formikProps.setFieldValue("province", e)
                          }, 50)
                        }}
                      />
                    </div>
                  </div>


                  <div className="form-group">
                    <label className="col-md-3">Kota</label>
                    <div className="col-md-9">
                      {formikProps.values.province && (
                        <CityDropDown
                          id="city"
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          isClearable={this.userData.isHeadOffice}
                          value={formikProps.values.city}
                          onChange={e => {
                            formikProps.setFieldValue("branch", "")
                            formikProps.setFieldValue("city", "")
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e)
                            }, 50)
                          }}
                          selectAllLabel="Semua Kota"
                          filters={this.filterCity(formikProps.values)}
                        />)}
                      {!formikProps.values.province &&
                        (<CityDropDown
                          id="city"
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          isClearable={this.userData.isHeadOffice}
                          value={formikProps.values.city}
                          onChange={e => {
                            formikProps.setFieldValue("branch", "")
                            formikProps.setFieldValue("city", "")
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e)
                            }, 50)
                          }}
                          selectAllLabel="Semua Kota"
                          filters={null}
                        />)}
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Cabang</label>
                    <div className="col-md-9">
                      {(formikProps.values.province && !formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          onChange={formikProps.handleChange("branch")}
                          isClearable={this.userData.isHeadOffice}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {(formikProps.values.city && !formikProps.values.province) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          onChange={formikProps.handleChange("branch")}
                          isClearable={this.userData.isHeadOffice}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {(formikProps.values.province && formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          onChange={formikProps.handleChange("branch")}
                          isClearable={this.userData.isHeadOffice}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {!(formikProps.values.province || formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          onChange={formikProps.handleChange("branch")}
                          isClearable={this.userData.isHeadOffice}
                          selectAllLabel="Semua branch"
                          filters={null}
                        />}
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3">Tipe Gerai</label>
                    <div className="col-md-9">
                      <Dropdown
                        style={{ width: "220px" }}
                        options={this.optionTipeGerai}
                        onChange={formikProps.handleChange('tipeGerai')}
                        value={formikProps.values.tipeGerai}
                      />
                    </div>
                  </div>
                </Flex>
                {/* <Flex column>

                  <div className="form-group">
                    <label className="col-md-8">Total PU Cap</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_pu_cap}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Total PU Ops terdaftar</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_pu_ops_cap}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Total PU Ops Caps Assign</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_pu_ops_cap_assign}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Jumlah Paket</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_jumlah_paket}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Total Vol Pick</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_vol_pick}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-8">Total Vol Drop</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_vol_drop}</p>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-8">Total Vol Cash</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_cash}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Sigesit Reguler Terdaftar</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_sigesit}</p>
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-8">Sigesit Reguler Assign</label>
                    <div className="col-md-4">
                      <p>{this.props.footerTotal.total_sigesit_assign}</p>
                    </div>
                  </div>
                </Flex> */}

              </Flex>
            )}
          </Formik>

          : null}
      </Panel>
    );
  }
}
