import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import CityDropDown from "../../component/select/cityDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import TimePicker from "../../component/time-picker/time-picker";

export interface ITimeWindowPeriodFilterContainerValue {
  startDate?: Date;
  endDate?: Date,
  partner: any;
  branch: any;
  jamSpk: any;
}

export class TimeWindowPeriodFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: ITimeWindowPeriodFilterContainerValue = {
    startDate: new Date(),
    endDate: new Date(),
    partner: "",
    branch: "",
    jamSpk: ""
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }


  optionTipeJAMSPK = [
    { label: "Semua Jadwal", value: "", selected: true },
    { label: "09:00 - 10:00", value: "9-10" },
    { label: "10:00 - 11:00", value: "10-11" },
    { label: "11:00 - 12:00", value: "11-12" },
    { label: "12:00 - 13:00", value: "12-13" },
    { label: "13:00 - 14:00", value: "13-14" },
    { label: "14:00 - 15:00", value: "14-15" },
    { label: "15:00 - 16:00", value: "15-16" },
    { label: "16:00 - 17:00", value: "16-17" },
    { label: "17:00 - 18:00", value: "17-18" },
    { label: "18:00 - 19:00", value: "18-19" },
    { label: "19:00 - 20:00", value: "19-20" },
    { label: "20:00 - 21:00", value: "20-21" },
    { label: "21:00 - 22:00", value: "21-22" },
  ];

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={e => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {formikProps => (
            <Flex wrap w={1}>
              <Box w={800}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-3">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        />
                        <div
                          className="mt-2 mb-2 text-center"
                          style={{ width: "172px" }}
                        >
                          s/d
                        </div>
                        <Calendar
                          name="endDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("endDate")}
                          value={formikProps.values.endDate}
                        />
                      </Flex>
                    </div>
                  </div>



                  <div className="form-group">
                    <label className="col-md-3">Cabang</label>
                    <div className="col-md-7">
                      <BranchDropdown
                        id="branch"
                        filters={null}
                        value={formikProps.values.branch}
                        onChange={formikProps.handleChange("branch")}
                      />
                    </div>
                  </div>


                  <div className="form-group">
                    <label className="col-md-3">Partner</label>
                    <div className="col-md-7">
                      <PartnerDropdown
                        value={formikProps.values.partner}
                        onChange={formikProps.handleChange("partner")}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <label className="col-md-3">Jam SPK</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionTipeJAMSPK}
                        onChange={formikProps.handleChange('jamSpk')}
                        value={formikProps.values.jamSpk}
                      />
                    </div>
                  </div>



                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
