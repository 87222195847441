import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import CityDropDown from "../../component/select/cityDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import { observer } from "mobx-react";

export interface ITimeWindowListFilterContainerValue {
  startDate?: Date;
  partner: any;
  branch: any;
  jamSpk: any;
  city: any;
  province: any;
}

@observer
export class TimeWindowFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: ITimeWindowListFilterContainerValue = {
    startDate: new Date(),
    partner: "",
    branch: "",
    city: "",
    province: "",
    jamSpk: "",
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  filterCity = param => {
    console.log(param, "param")
    let filterList: any = [];
    if (param.province) {
      filterList.push({
        field: 'provinceId',
        operator: 'eq',
        value: param.province.value,
      })
    }
    return filterList;
  };

  filterBranch = (param) => {
    console.log(param, "param branch")
    let filterList: any = [];
    if (param.province) {
      filterList.push({
        field: 'provinceId',
        operator: 'eq',
        value: param.province.value,
      })
    }
    if (param.city) {
      filterList.push({
        field: 'cityId',
        operator: 'eq',
        value: param.city.value,
      })
    }
    return filterList;
  };



  optionTipeJAMSPK = [
    { label: "Semua Jadwal", value: "", selected: true },
    { label: "09:00 - 10:00", value: "9-10" },
    { label: "10:00 - 11:00", value: "10-11" },
    { label: "11:00 - 12:00", value: "11-12" },
    { label: "12:00 - 13:00", value: "12-13" },
    { label: "13:00 - 14:00", value: "13-14" },
    { label: "14:00 - 15:00", value: "14-15" },
    { label: "15:00 - 16:00", value: "15-16" },
    { label: "16:00 - 17:00", value: "16-17" },
    { label: "17:00 - 18:00", value: "17-18" },
    { label: "18:00 - 19:00", value: "18-19" },
    { label: "19:00 - 20:00", value: "19-20" },
    { label: "20:00 - 21:00", value: "20-21" },
    { label: "21:00 - 22:00", value: "21-22" },
  ];


  onCityChange(e, formik): void {
    formik.handleChange(e)
  }

  onProvinceChange(formik): void {
    formik.handleChange("province")
  }


  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={e => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {formikProps => (
            <Flex wrap w={1}>
              <Box w={800}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-3">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Provinsi</label>
                    <div className="col-md-7">
                      <ProvinceDropdown
                        id="province"
                        value={formikProps.values.province}
                        onChange={e => {

                          formikProps.setFieldValue("city", "")
                          formikProps.setFieldValue("branch", "")
                          formikProps.setFieldValue("province", "")
                          setTimeout(() => {
                            formikProps.setFieldValue("province", e)
                          }, 50)
                          console.log("update province")
                        }}
                        selectAllLabel="Semua Provinsi"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Kota</label>
                    {formikProps.values.province && (
                      <div className="col-md-7">
                        <CityDropDown
                          id="city"
                          value={formikProps.values.city}
                          onChange={e => {
                            formikProps.setFieldValue("branch", "")
                            formikProps.setFieldValue("city", "")
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e)
                            }, 50)
                          }}
                          selectAllLabel="Semua Kota"
                          filters={this.filterCity(formikProps.values)}
                        />
                      </div>)}
                    {!formikProps.values.province &&
                      (<div className="col-md-7">
                        <CityDropDown
                          id="city"
                          value={formikProps.values.city}
                          onChange={e => {
                            formikProps.setFieldValue("branch", "")
                            formikProps.setFieldValue("city", "")
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e)
                            }, 50)
                          }}
                          selectAllLabel="Semua Kota"
                          filters={null}
                        />
                      </div>)}
                  </div>

                  <div className="form-group">
                    <label className="col-md-3">Cabang</label>
                    <div className="col-md-7">
                      {(formikProps.values.province && !formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          onChange={formikProps.handleChange("branch")}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {(formikProps.values.city && !formikProps.values.province) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          onChange={formikProps.handleChange("branch")}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {(formikProps.values.province && formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          onChange={formikProps.handleChange("branch")}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />}
                      {!(formikProps.values.province || formikProps.values.city) &&
                        <BranchDropdown
                          id="branch"
                          value={formikProps.values.branch}
                          onChange={formikProps.handleChange("branch")}
                          selectAllLabel="Semua branch"
                          filters={null}
                        />}

                    </div>
                  </div>


                  <div className="form-group">
                    <label className="col-md-3">Partner</label>
                    <div className="col-md-7">
                      <PartnerDropdown
                        value={formikProps.values.partner}
                        onChange={formikProps.handleChange("partner")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Jam SPK</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionTipeJAMSPK}
                        onChange={formikProps.handleChange("jamSpk")}
                        value={formikProps.values.jamSpk}
                      />
                    </div>
                  </div>

                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
