import { observable } from "mobx";
import { observer } from "mobx-react";
import React from "react";
import { Panel } from "primereact/panel";
import { TabPanel, TabView } from "primereact/tabview";
import { Tab } from "./interface";
import { Button } from "primereact/button";
import { Flex } from "reflexbox";

interface EmbedComponentProps {
  title: string;
  tabs: Tab[] | Tab;
  getUrlEmbed: () => void;
  filterComponent?: ()=> JSX.Element;
  activeTab?: number;
  onChangeTab?: (index: number) => void;
  componentHowTo?: boolean | JSX.Element;
}

@observer
class EmbedComponent extends React.Component<EmbedComponentProps, {}> {

  @observable filterCollapsed= false

  componentDidMount(): void {
    this.props.getUrlEmbed();
  }

  onChangeTab(index: number) {
    if(Array.isArray(this.props.tabs) && this.props.onChangeTab){
      this.props.onChangeTab(index);
      if (!this.props.tabs[index].token) {
        this.props.getUrlEmbed();
      }
    }
  }

  onClickTampilkan=()=>{
    if(Array.isArray(this.props.tabs)){
      this.props.tabs.forEach((tab)=>{
        tab.url=''
        tab.token=''
      })
      this.props.getUrlEmbed();
      return;
    }

    if(typeof this.props.tabs==='object'){
      this.props.tabs.url=''
      this.props.tabs.token=''
      this.props.getUrlEmbed();
      return;
    }
  }

  componentIframe=()=>{
    if(Array.isArray(this.props.tabs)){
      return (
        <TabView
          activeIndex={this.props.activeTab}
          onTabChange={(e) => {
            this.onChangeTab(e.index);
          }}
          renderActiveOnly={false}
        >
        {this.props.tabs.map((tab) => {
          return (
            <TabPanel header={tab.label}>
              {
                tab.url && tab.token &&
                <iframe title={tab.label} width="100%" height={750} src={tab.url + tab.token} />
              }
            </TabPanel>
          );
        })}
        </TabView>
      )
    }

    if(typeof this.props.tabs==='object'){
      return <iframe title={this.props.tabs.label} width="100%" height={750} src={this.props.tabs.url + this.props.tabs.token} />
    }

  }

  componentHowTo=()=>{
    if(!this.props.componentHowTo) return null;

    if(this.props.componentHowTo===true){
      return <Flex className="card w-full">
        <Flex className="w-full" column>
          <Panel header="Note">
            <ul style={{ listStyle: "initial", paddingLeft: "1.5em" }}>
              <li>Cara <b>Melihat List Resi</b>: Harap klik <b>Tab</b> dibawah ini untuk melihat <b>List Resi</b></li>
              <li>
                Cara <b>Download</b> Data Resi :
                <ul style={{ listStyle: "circle", paddingLeft: "1.5em" }}>
                  <li>Pilih <b>Tab</b> yang akan dilihat</li>
                  <li>Arahkan <b>Cursor/Panah</b> ke tabel</li>
                  <li>
                    <Flex row>
                      Klik Icon
                      <svg
                        style={{ color: "#b8bbc3" }}
                        className="Icon Icon-download e621b520 css-4g44w1 e621b521 ml-2"
                        viewBox="0 0 52 32"
                        width="20"
                        height="20"
                        fill="currentcolor"
                        role="img"
                        aria-label="download icon"
                      >
                        <path d="M26 0c7.264 0 13.323 5.164 14.704 12.022C47.004 12.384 52 17.609 52 24c0 6.627-5.373 12-12 12H12C5.373 36 0 30.627 0 24c0-6.391 4.997-11.616 11.297-11.98C12.677 5.164 18.736 0 26 0zm0 6a3 3 0 00-3 3v13.649l-5.232-5.511-.13-.128a2.409 2.409 0 00-3.406.128c-.976 1.028-.976 2.696 0 3.724l10 10.533.13.129a2.409 2.409 0 003.406-.129l10-10.533.122-.138c.852-1.034.812-2.602-.122-3.586l-.13-.128a2.409 2.409 0 00-3.406.128L29 22.649V9a3 3 0 00-3-3z"></path>
                      </svg>
                    </Flex>
                  </li>
                </ul>
              </li>
              <li>Untuk <b>Kembali </b>ke Halaman Awal, Klik  <b>Tab “Home”</b></li>
            </ul>
          </Panel>
        </Flex>
      </Flex>
    }
    
    if(typeof this.props.componentHowTo==='object') return this.props.componentHowTo;
  }


  render() {
    return (
      <div>
        {
          this.props.filterComponent && <Flex className="card w-full">
            <Flex className="w-full" column>
              <Panel
                header={this.props.title}
                toggleable={true}
                collapsed={this.filterCollapsed}
                onToggle={(e) => { this.filterCollapsed=!this.filterCollapsed }}
              >
                <div className="p-4">{this.props.filterComponent()}</div>
              </Panel>
              <br />
              <Flex>
                <Button
                  className="p-button-success mr-2"
                  label="Tampilkan"
                  onClick={this.onClickTampilkan}
                />
              </Flex>
            </Flex>
          </Flex>
        }

        { this.componentHowTo() }

        <div className="card">
          { this.componentIframe() }
        </div>
      </div>
    );
  }
}

export default EmbedComponent;