import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import CityDropDown from "../../component/select/cityDropDown";

export interface ICourierDeliverySummaryFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  branch: any;
}

export class CourierPickupSummaryFilterContainer extends React.Component<
  any,
  any
  > {
  dateRange: any = null;
  userData: any;

  initialFormValues: ICourierDeliverySummaryFilterContainerValue = this.userData ? {
    startDate: new Date(),
    endDate: new Date(),
    branch:
      !this.userData.isHeadOffice ? { label: this.userData.branchName, value: this.userData.branchId } : ""
  } : {
      startDate: new Date(),
      endDate: new Date(),
      branch: ""
    };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false,
    };
  }

  async componentDidMount() {
    this.userData = await (localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null);

  }


  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  optionTipeGerai = [
    { label: "semua", value: "", selected: true },
    { label: "Branch", value: "branch" },
    { label: "Shop", value: "shop" },
    { label: "Transit", value: "transit" },
  ];

  render() {

    return <Panel
      header="Filter"
      toggleable={true}
      collapsed={this.state.filterCollapsed}
      onToggle={(e) => this.setState({ filterCollapsed: e.value })}
    >
      {this.userData ? (
        <Formik
          ref={this.refFormik}
          enableReinitialize={true}
          initialValues={this.userData.isHeadOffice ? this.initialFormValues : {
            startDate: new Date(),
            endDate: new Date(),
            branch:
              !this.userData.isHeadOffice ? { label: this.userData.branchName, value: this.userData.branchId } : ""
          }}
          onSubmit={() => { }}
        >
          {(formikProps) => (
            <Flex wrap w={1}>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-3">Periode</label>
                  <div className="col-md-7">
                    <Flex column>
                      <Calendar
                        inputStyle={{ width: "220px" }}
                        name="startDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        maxDate={formikProps.values.endDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange("startDate")}
                        value={formikProps.values.startDate}
                      />
                      <div
                        className="mt-2 mb-2 text-center"
                        style={{ width: "172px" }}
                      >
                        s/d
                      </div>
                      <Calendar
                        inputStyle={{ width: "220px" }}
                        name="endDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        minDate={formikProps.values.startDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange("endDate")}
                        value={formikProps.values.endDate}
                      />
                    </Flex>
                  </div>
                </div>

                { !this.props.isHideFilterCabang &&
                  <div className="form-group">
                    <label className="col-md-3">Cabang</label>
                    <div className="col-md-7">
                      <BranchDropdown
                        id="branch"
                        value={formikProps.values.branch}
                        isOptionDisabled={() => !this.userData.isHeadOffice}
                        selectAll={this.userData.isHeadOffice}
                        isClearable={this.userData.isHeadOffice}
                        filters={null}
                        onChange={formikProps.handleChange("branch")}
                      />
                    </div>
                  </div>
                }
              </Flex>
            </Flex>
          )}
        </Formik>
      ) : null}
    </Panel>

  }
}
