import { Formik } from 'formik';
import { observable } from 'mobx';
import { observer } from 'mobx-react';
import qs from 'qs';
import React, { RefObject } from 'react';
import { Link } from 'react-router-dom';
import { DependencyContainer } from '../../../utils/DependencyInjection';
import { MonitoringRestService } from '../../../services/MonitoringRestService';
import _ from 'lodash';
import { DateHelper } from '../../../utils/DateHelper';
import Loader from 'react-loader-spinner';
import { AxiosRequestConfig } from 'axios';

@observer
export class TimeWindowDetailUnpickRescheduleContainer extends React.Component<any, any> {
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    @observable userData = localStorage.getItem("userData")
        ? JSON.parse(localStorage.userData)
        : null;

    refFormik: RefObject<Formik> = React.createRef();

    @observable data;
    filterParams;

    constructor(props) {
        super(props);

        this.state = {
            loadOverSlaData: false
        };
    }

    public async loadData(filterParams) {
        this.data = [];
        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams);
        this.setState({ loadOverSlaData: 'load' });

        if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);

        if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);

        if (fParams.branch)
            fParams.branchId = fParams.branch.value;

        if (fParams.partner)
            fParams.partnerId = fParams.partner.value;

        const axiosConfig: AxiosRequestConfig = {
            headers: {
                Authorization: "Bearer " + this.userData.accessToken,
                Accept: 'application/json'
            },
        };

        this.monitoringRestService
            .post('/time-window-new/detail/unpick/reschedule', fParams, axiosConfig)
            .subscribe(
                response => {
                    this.data = response.result;
                },
                () => {
                    this.setState({ loadOverSlaData: false });
                },
                () => {
                    this.setState({ loadOverSlaData: true });
                },
            );
    }

    render() {
        return (
            <div>
                {this.data && this.data.length > 0 ? (
                    <div className="mt-5">
                        <p className="font-bold" style={{ fontSize: '18px', margin: '10px 0' }}>Total Resi: {(this.data ? _.sumBy(this.data, 'value.count') : 0).toLocaleString()}</p>
                        <hr />
                    </div>
                ) : ''}
                <div>
                    {this.data && this.data.length > 0 ? (
                        this.data.map((d, index) => (
                            d != null && d.value != null ?
                                <div key={index}>
                                    <span className="font-bold">{d.value.reason_name}: </span>
                                    <Link
                                        to={{
                                            pathname: '/admin/time-window-new/detail/awb',
                                            search: qs.stringify(Object.assign({}, this.filterParams, { reason: d.value.reason_id ? d.value.reason_id : 0 })),
                                        }}
                                        target="_blank">
                                        {d.value.count}
                                    </Link>
                                </div> : null
                        ))
                    ) : (
                        <div>
                            {this.state.loadOverSlaData == 'load' ?
                                (
                                    <Loader
                                        type="ThreeDots"
                                        color="#c8c8c8"
                                        height="50"
                                        width="50"
                                    />
                                )
                                : (
                                    <p>Tidak ada data</p>
                                )
                            }</div>
                    )}
                </div>
            </div>
        );
    }
}
