import React, { Component } from 'react';
import { BrowserRouter as Router, Redirect, Route } from 'react-router-dom';

import { AuthRouter } from './routers/AuthRouter';
import { DashboardRouter } from './routers/DashboardRouter';
import { MonitoringRouter } from './routers/MonitoringRouter';

class App extends Component {
  render() {
    return (
      <Router>
        <Route exact path="/" render={() => <Redirect to="/auth/login" />} />
        <AuthRouter />
        <DashboardRouter />        
        <MonitoringRouter />
      </Router>
    );
  }
}

export default App;
