import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import qs from "qs";
import { observer } from "mobx-react";

@observer
export class TimeWindowDetailBranchDriver extends React.Component<RouterProps, any> {
    dataTableFilterService = DependencyContainer.get(DataTableFilterService);
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    data;
    growl;
    dataSource = new DataSource<any>();
    refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
    filterParams: any;
    urlDetail = "/admin/time_window/detail/awb";
    constructor(props) {
        super(props);
        this.state = {
            exportExcelLoading: false,
            showExportDialog: false,
            filterCollapsed: false,
            sortField: null,
            sortOrder: null
        };
    }

    componentDidMount() {
        this.dataSource.onPageChange.subscribe(() => {
            this.loadData(this.filterParams);
        });
        this.filterParams = qs.parse(location.search.replace("?", ""))
        this.dataSource.setPage(1);


    }

    loadData(filterParams: any) {
        const pagingParams = {
            limit: this.dataSource.rows,
            page: this.dataSource.page
        };
        const sortingParams = {
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
        };

        const fParams = Object.assign(
            {},
            filterParams,
            pagingParams,
            sortingParams
        );

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        fParams.filterParams = filterParams;

        this.filterParams = filterParams;


        // fParams.fParameters = JSON.stringify(filterParams);


        this.dataSource.loading = true;
        this.monitoringRestService.post(`/TimeWindow-pg/list/detail/branch/driver`, fParams).subscribe(
            response => {
                this.data = response;
                this.populateDataSource();
            },
            err => {
                this.dataSource.loading = false;
            },
            () => {
                this.dataSource.loading = false;
            }
        );
    }

    downloadCsv(filterParams: any) {
        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams);

        fParams.filterParams = this.filterParams;
        fParams.format = "xls";

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        const resPath =
            this.filterParams.slaType! && this.filterParams.slaType === "external"
                ? "over-sla/detail"
                : "over-sla/detail";
        console.log(resPath, "res path")


        this.monitoringRestService.post(resPath, this.filterParams).subscribe(
            response => {
                if (response && response.path) {
                    this.openCsv(response.path);
                }
            },
            () => {
                let msg = {
                    severity: "error",
                    summary: "Error Message",
                    detail: "Export failed"
                };
                this.growl.show(msg);
            },
            () => {
                let msg = {
                    severity: "success",
                    summary: "Success Message",
                    detail: "Export Success"
                };
                this.growl.show(msg);
            }
        );
    }

    openCsv(url) {
        const win = window.open(url, "_blank");
        if (win) win.focus();
    }

    populateDataSource() {
        if (this.data.data && this.data.data.length) {
            this.dataSource.setData(this.data.data);
            this.dataSource.setTotal(this.data.count);
        } else {
            this.dataSource.setData([]);
            this.dataSource.setTotal(0);
        }
    }

    onClickAwbNumber = awbNumber => {
        this.refAwbDetailModal.current!.handleShow(awbNumber);
    };

    getPerc = (obj, div) => {
        return div > 0 ? (obj / div * 100).toFixed(2) : "0"
    }

    columnAwbNumber = (rowData, column: ColumnProps) => {
        return (
            <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
                {rowData[column.field!]}
            </a>
        );
    };

    detailMonitoringNav = (row, column, url) => {
        url += "&employeeId=" + row.employee_id_driver;
        if (!this.filterParams.timeStart)
            url += "&timeStart=" + row.time_window_hour_start;
        return (
            <a
                href="javascript:void(0);"
                onClick={async () => {
                    window.open(url, "_blank");
                }}
                style={{ textAlign: "center" }}
            >
                {row[column.field!]}
            </a>
        );
    }


    columnDateFormat = (rowData, column: ColumnProps) => {
        return rowData[column.field!] != null
            ? moment(new Date(rowData[column.field!]))
                .format("DD-MM-YYYY")
            : "";
    };



    columnDateTimeFormat = (rowData, column: ColumnProps) => {
        return rowData[column.field!] != null
            ? moment(new Date(rowData[column.field!]))
                .format("DD-MM-YYYY HH:mm")
            : "";
    };
    async onSorting(e) {
        if (e && e.sortField) {
            const sortOrder =
                this.state.sortField === e.sortField
                    ? this.state.sortOrder === 1
                        ? -1
                        : 1
                    : 1;
            await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
        } else {
            await this.setState({ sortField: null, sortOrder: null });
        }

        console.log(e);
        console.log(this.state);
        this.loadData(this.filterParams);
    }

    render() {
        const footer = "Total data: " + this.dataSource.total;

        return (
            <div>
                <AwbDetailModalComponent ref={this.refAwbDetailModal} />
                <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />
                <div className="card">
                    <div className="row">
                        {this.filterParams ? (
                            <h3 style={{ float: "left", marginLeft: "10px" }}>
                                Branch - {this.filterParams.branchName}
                            </h3>
                        ) : (
                                <h3></h3>
                            )}
                    </div>
                    <DataTable
                        emptyMessage={"Tidak ada data untuk ditampilkan."}
                        scrollable={true}
                        scrollHeight="550px"
                        style={{ width: "100%" }}
                        className="mt-4"
                        value={this.dataSource.data}
                        loading={this.dataSource.loading}
                        sortField={this.state.sortField}
                        sortOrder={this.state.sortOrder}
                        onSort={e => {
                            this.onSorting(e);
                        }}
                        footer={footer}
                    >
                        <Column
                            field="sigesit"
                            header="Si Gesit"
                            sortable={true}
                            style={{ width: "130px", "vertical-align": "text-top" }}
                        />

                        <Column
                            field="time_start"
                            header="Time Start"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                const content = String(row.time_window_hour_start).padStart(2, '0') + ":00";
                                return <p>{content}</p>;
                            }}
                        />

                        <Column
                            field="pickup_points"
                            header="Pickup Poin"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}

                        />
                        <Column
                            field="total_spk_yesterday"
                            header="Jumlah Resi Kemarin"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=spk_yes&title=SPK Yesterday&" + filterString;
                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="total_spk"
                            header="Jumlah SPK Hari ini"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;

                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=spk_today&title=SPK Today&" + filterString;
                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="total_spk_today"
                            header="Jumlah Req Pickiup"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=total_awb&title=Total AWB&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                        <Column
                            field="total_assigned"
                            header="SPK Assign"
                            filter={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=assign&title=Assign&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="total_not_assigned"
                            header="SPK Not Assign"
                            filter={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=not_assign&title=Not Assign&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                        <Column
                            field="total_dropped"
                            header="Drop"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=drop&title=Drop&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                        <Column
                            field="drop_percentage"
                            header="% Drop"
                            sortable={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_dropped, row.total_spk_today)}</p>;
                            }}
                        />
                        <Column
                            field="total_picked"
                            header="Pick"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=pick&title=Pick&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="pick_percentage"
                            header="% Pick"
                            sortable={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_picked, row.total_spk_today)}</p>;
                            }}
                        />
                        <Column
                            field="total_pick_on_time"
                            header="Pick On Time"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url +=
                                    "?status=pick_on_time&title=Pick On Time&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="pick_on_time_percentage"
                            header="% Drop"
                            sortable={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_pick_on_time, row.total_picked)}</p>;
                            }}
                        />
                        <Column
                            field="total_pick_late_time"
                            header="Pick Late Time"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url +=
                                    "?status=pick_late_time&title=Pick Late Time&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="pick_late_time_percentage"
                            header="% Pick Late Time"
                            sortable={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_pick_late_time, row.total_picked)}</p>;
                            }}
                        />
                        <Column
                            field="total_unpicked"
                            header="Unpick"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=unpick&title=Unpick&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="unpick_percetage"
                            header="% Unpick"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_unpicked, row.total_spk_today)}</p>;
                            }}
                        />
                        <Column
                            field="total_pickup_request_type"
                            header="RPU Plan Pickup"
                            filter={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=rpu&title=RPU Pick Plan&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                        <Column
                            field="total_auto_pop"
                            header="Auto POP"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=auto_pop&title=Auto POP&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="auto_pop_percentage"
                            header="% Auto POP"
                            sortable={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                return <p>{this.getPerc(row.total_auto_pop, row.total_spk_today)}</p>;
                            }}
                        />
                        <Column
                            field="total_not_pop"
                            header="Not POP"
                            // body={this.columnDateFormat}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=not_pop&title=Not POP&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                        <Column
                            field="total_manifested"
                            header="Manifest"
                            filter={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url += "?status=manifest&title=Manifest&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />

                        <Column
                            field="total_not_manifested"
                            header="Not Manifest"
                            filter={false}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={(row, column: ColumnProps) => {
                                let url = this.urlDetail;
                                let filterString = qs.stringify(this.filterParams);
                                url +=
                                    "?status=not_manifest&title=Not Manifest&" + filterString;

                                return this.detailMonitoringNav(row, column, url);
                            }}
                        />
                    </DataTable>
                    <Paginator
                        totalRecords={this.dataSource.total}
                        first={this.dataSource.offset}
                        rows={this.dataSource.rows}
                        rowsPerPageOptions={this.dataSource.rowsOptions}
                        onPageChange={e => this.dataSource.fromPageState(e)}
                    />
                </div>
            </div>
        );
    }
}
