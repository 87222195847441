import "../../css/login.css";
import { Button } from "primereact/button";
import React from "react";
import { Redirect } from "react-router-dom";
import { Flex } from "reflexbox";

import { MonitoringRestService } from "../../services/MonitoringRestService";
import { MonitoringRestAuthService } from "../../services/MonitoringRestAuthService";
import { DependencyContainer } from "../../utils/DependencyInjection";

interface ILoginState {
  userName: string;
  password: string;
  [key: string]: any;
}
export class LoginContainer extends React.Component<{}, ILoginState> {
  loginData;
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  monitoringRestAuthService = DependencyContainer.get(
    MonitoringRestAuthService
  );

  constructor(props) {
    super(props);

    this.state = {
      userName: "",
      password: "",
      redirect: false,
      loading: false,
    };

    this.handleUser = this.handleUser.bind(this);
    this.handlePassword = this.handlePassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleUser(e) {
    this.setState({ userName: e.target.value });
  }

  handlePassword(e) {
    this.setState({ password: e.target.value });
  }

  handleSubmit() {
    if (this.state.userName.length > 0 && this.state.password.length > 0) {
      this.login();
    } else {
      return alert("Login gagal, username dan password tidak boleh kosong!");
    }
  }

  login() {
    this.setState({ loading: true });

    if (this.state.userName === "*branchmode*") {
      localStorage.setItem("userData", JSON.stringify(this.loginData));
      localStorage.removeItem("branchCode");
      this.setState({ redirect: true });
      this.setState({ loading: false });
      return;
    }

    const loginParams = Object.assign({
      username: this.state.userName,
      clientId: "monitoring",
      password: this.state.password, //Crypto.createHash('md5').update(this.state.password).digest('hex')
    });

    this.monitoringRestAuthService.post("auth/login", loginParams).subscribe(
      (response) => {
        this.loginData = response;
      },
      (error) => {
        alert(error.response.data.message);
        this.setState({ loading: false });
      },
      () => {
        if (this.loginData) {
          // if (this.loginData.user_id === 1) {
          //   this.loginData.role_name = "Superadmin";
          // }

          localStorage.setItem("userData", JSON.stringify(this.loginData));
          localStorage.removeItem("branchCode");

          // this.setLoginRole(this.loginData)

          this.setState({ redirect: true });
        }

        this.setState({ loading: false });
      }
    );
  }

  render() {
    if (this.state.redirect || localStorage.getItem("userData")) {
      // if (userData.role_name == "Admin Hub") {
      //   return <Redirect to={"/admin/attendance-driver"} />;
      // } else {
      //   return <Redirect to={"/admin/dashboard"} />;
      // }
      return <Redirect to={"/admin/role"} />;
    }

    return (
      <div className="login-container">
        <div className="login-box">
          <img className="login-logo" src="/assets/logo-sicepat.png" />
          <Flex column className="login-form">
            <div className="form-group">
              <input
                type="text"
                className="form-control"
                name="userName"
                placeholder="Username"
                value={this.state.userName}
                onChange={this.handleUser}
              />
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                name="password"
                placeholder="Password"
                value={this.state.password}
                onChange={this.handlePassword}
              />
            </div>
            <div className="text-center">
              <Button
                className="p-button-danger login-btn"
                label="Masuk"
                icon={this.state.loading ? "pi pi-spin pi-spinner" : ""}
                disabled={this.state.loading}
                onClick={this.handleSubmit}
              />
            </div>
          </Flex>
        </div>
      </div>
    );
  }
}
