import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import qs from "qs";
import { observer } from "mobx-react";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { AxiosRequestConfig } from "axios";
import { observable } from "mobx";

@observer
export class TimeWindowBranchDetailContainer extends React.Component<
RouterProps,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  data;
  growl;
  dataSource = new DataSource<any>();
  filterParams: any;
  branchId;
  branchName;

  refDataTable;

  urlDetail = "/admin/time-window-new/detail/awb";
  urlDetailSigesit = "/admin/time-window-new/driver-detail";

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
    };

    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {
    console.log(location.search);
    this.filterParams = qs.parse(location.search.replace("?", ""), { plainObjects: true });
    console.log(this.filterParams, "params");
    this.branchId = this.filterParams.branchId;
    this.branchName = this.filterParams.branchName;

    this.loadData(this.filterParams);

    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData(this.filterParams);
    // });

    // this.dataSource.setPage(1);
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    const fParams = {
      filters: filterParams,
    };

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.dataSource.loading = true;
    this.monitoringRestService
      .post(`/time-window-new/branch-detail/${this.branchId}`, fParams, axiosConfig)
      .subscribe(
        (response) => {
          this.data = response;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  public reset() {
    this.data = null;
    this.dataSource.setData([]);
    this.dataSource.setTotal(0);
  }

  async exportCsv() {
    this.setState({ exportExcelLoading: true });
    try {
      await this.refDataTable.exportCSV();
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this.setState({ exportExcelLoading: false });
    }
  }

  gotoDriverDetail = (row, column) => {
    const mParams = Object.assign(
      {},
      !!this.filterParams ? this.filterParams : {}
    );

    mParams.branchId = this.branchId;
    mParams.branchName = row["branch_name"];
    const timeWindowStartDate = row["time_window_start_date"];
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      mParams.timeWindowStartDate = moment(timeWindowStartDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    const qParams = qs.stringify(mParams);

    const url = `/admin/time-window-new/driver-detail/?${qParams}`;

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  gotoAwbDetail = (row, column, params) => {
    const mParams = Object.assign(
      {},
      !!this.filterParams ? this.filterParams : {},
      !!params ? params : {}
    );

    mParams.branchId = this.branchId;
    mParams.branchName = this.branchName;
    const timeWindowStartDate = row["time_window_start_date"];
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      mParams.timeWindowStartDate = moment(timeWindowStartDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    const qParams = qs.stringify(mParams);

    const url = `/admin/time-window-new/detail/awb/?${qParams}`;

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY")
      : "";
  };

  columnDateTimeFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY HH:mm")
      : "";
  };

  getPerc = (obj) => {
    // return !!obj ? obj.toFixed(2) : "0";
    return obj ? obj.toFixed(2) : 0;
  };

  render() {
    var header = (
      <div style={{ textAlign: "left" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="CSV"
          onClick={this.exportCsv}
        ></Button>
      </div>
    );

    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <div className="row">
          {this.filterParams ? (
            <h3 style={{ float: "left", marginLeft: "10px" }}>
              {this.filterParams.branchName}
            </h3>
          ) : (
            <h3></h3>
          )}
        </div>

        <DataTable
          ref={(el) => (this.refDataTable = el)}
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          style={{ width: "100%" }}
          className="mt-4"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          header={header}
          footer={footer}
        >
          <Column
            field="branch_name"
            header="Cabang"
            filter={true}
            filterMatchMode="contains"
            sortable={true}
            style={{ width: "150px", "vertical-align": "text-top" }}
          />
          <Column
            field="branch_code"
            header="Kode Analytics Gerai"
            filter={true}
            filterMatchMode="contains"
            style={{ width: "150px", "vertical-align": "text-top" }}
          />
          <Column
            field="time_window_start_date"
            header="Pickup Schedule"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.columnDateFormat(row, column);
            }}
          />
          <Column
            field="time_window_start_date"
            header="Time Window"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              const timeWindowStartDate = row["time_window_start_date"];
              const timeWindowEndDate = row["time_window_end_date"];
              if (timeWindowStartDate && timeWindowEndDate) {
                return `${moment(timeWindowStartDate).hour()} - ${moment(
                  timeWindowEndDate
                ).hour()}`;
              }
              return "-";
            }}
          />
          <Column
            field="sigesit"
            header="Sigesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoDriverDetail(row, column);
            }}
          />

          <Column
            field="pickup_point"
            header="Titik Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="rpu"
            header="Plan Pickup + Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "rpu",
                title: "RPU (Pickup + Drop)",
              });
            }}
          />

          <Column
            field="spk_yesterday"
            header="SPK Kemarin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk_yesterday",
                title: "SPK Kemarin",
              });
            }}
          />

          <Column
            field="spk_today"
            header="SPK Hari ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk_today",
                title: "SPK Hari Ini",
              });
            }}
          />

          <Column
            field="spk"
            header="Total SPK"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk",
                title: "Total SPK",
              });
            }}
          />
          <Column
            field="assigned"
            header="SPK Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "assign",
                title: "SPK Assign Sigesit",
              });
            }}
          />

          <Column
            field="not_assigned"
            header="SPK Belum Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "not_assign",
                title: "SPK Belum Assign Sigesit",
              });
            }}
          />

          <Column
            field="picked_all"
            header="Pick (Plan Pickup + Drop)"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick_all",
                title: "Pick (Plan Pickup + Drop)",
              });
            }}
          />
          <Column
            field="picked"
            header="Pick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick",
                title: "Pick",
              });
            }}
          />
          <Column
            field="pick_percentage"
            header="% Pick"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="unpicked"
            header="Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "unpick",
                title: "Unpick",
              });
            }}
          />
          <Column
            field="unpick_percentage"
            header="% Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="not_pop"
            header="Belum POP"
            sortable={true}
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "not_pop",
                title: "Belum POP",
              });
            }}
          />
          <Column
            field="not_pop_percentage"
            header="% Belum POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="auto_pop"
            header="Auto POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "auto_pop",
                title: "Auto POP",
              });
            }}
          />
          <Column
            field="auto_pop_percentage"
            header="% Auto POP"
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="pop_on_time"
            header="POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pop_on_time",
                title: "POP On Time",
              });
            }}
          />
          <Column
            field="pop_on_time_percentage"
            header="% POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="pop_late_time"
            header="POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pop_late_time",
                title: "POP Tidak On Time",
              });
            }}
          />

          <Column
            field="pop_late_time_percentage"
            header="% POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="dropped"
            header="Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "drop",
                title: "Drop",
              });
            }}
          />
          <Column
            field="drop_percentage"
            header="% Drop"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            // style={{ width: "4vw" }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="manifested"
            header="Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "manifest",
                title: "Manifest",
              });
            }}
          />

          <Column
            field="pick_not_manifested"
            header="Pick/Drop Belum Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick_not_manifested",
                title: "Pick/Drop Belum Manifest",
              });
            }}
          />
        </DataTable>
      </div>
    );
  }
}
