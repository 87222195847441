import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  IPickupListFilterContainerValue,
  MonitoringPikcupFilterContainer
} from "./MonitoringPickupFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";
import QueryString from "qs";

export interface IAwbListContainerQuery {
  filters: IPickupListFilterContainerValue;
}

@observer
export class MonitoringPickupContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<MonitoringPikcupFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
    this.dataSource.setPage(1);
  }

  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );

    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }

    if (fParams.branch)
      fParams.branchId = fParams.branch.value

    if (fParams.partner)
      fParams.partnerId = fParams.partner.value

    fParams.format = "list";
    console.log(fParams, "fparams");

    this.dataSource.loading = true;
    this.monitoringRestService.post("/Monitoring/Pickup/List", fParams).subscribe(
      response => {
        this.data = response;
        this.populateDataSource();
      },
      err => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("/Monitoring/Pickup/List", fParams).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
        console.log(response, "response");
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }


  downloadPdf(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
        console.log(response, "response");
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      console.log(this.data.data, "data in data");
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = awbNumber => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };


  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <MonitoringPikcupFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary mr-2"
                label="Export Csv"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                disabled={this.state.exportExcelLoading}
                onClick={() => this.downloadCsv()}
              />
              <Button
                className="p-button-danger"
                label="Export Pdf"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                disabled={this.state.exportExcelLoading}
                onClick={() => this.downloadPdf()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={el => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={e => {
              this.onSorting(e);
            }}
            // onValueChange={() => this.loadData()}
            footer={footer}
          >
            <Column
              field="branch_name"
              header="Cabang"
              sortable={true}
              style={{ width: "150px", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/branch";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                console.log(filterString, "filter string")
                url += "?" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_spk_yesterday"
              header="Sisa Kemarin"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                console.log(filterString, "filter string")
                url +=
                  "?status=totalspkyes&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_spk"
              header="SPK Hari ini"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=totalspk&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_spk_today"
              header="Total SPK hari ini"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=totalspktod&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />

            <Column
              field="total_assign"
              header="Assign"
              // body={this.columnDateFormat}
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=assigned&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_not_assign"
              header="Not Assign"
              // body={this.columnDateFormat}
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=notAssigned&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_picked"
              header="Pick"
              // body={this.columnDateFormat}
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=pick&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_unpicked"
              header="Unpick"
              // body={this.columnDateFormat}
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=unpick&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_unpicked_reschedule"
              header="Reschedule"
              // body={this.columnDateFormat}
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=unpickres&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_unpicked_not_reschedule"
              header="Not Reschedule"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=unpicknotres&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_not_popup"
              header="Tidak POP"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=notpop&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_dropped"
              header="Drop"
              sortable={false}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=drop&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />
            <Column
              field="total_cancel"
              header="Cancel"
              sortable={false}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=cancel&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />

            <Column
              field="total_manifested"
              header="Manifest"
              sortable={false}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=manifested&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />

            <Column
              field="total_not_manifested"
              header="Not Manifest"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=notmanifested&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />

            <Column
              field="total_unfinish"
              header="Sisa SPK"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                let url = "/admin/monitoring-pickup/detail/awb";
                let filterString = QueryString.stringify(this.refFilter.current!.formValues);
                url +=
                  "?status=unfinish&" + filterString
                url += "&branchId=" + row.branch_id;

                return this.detailMonitoringNav(row, column, url);
              }}
            />

            <Column
              field="total_spk_today_plus_drop"
              header="SPK + Drop"
              sortable={false}
              style={{ width: "130px", "vertical-align": "text-top" }}
            />
          </DataTable>
          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={e => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
