import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import CityDropDown from "../../component/select/cityDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import { observer } from "mobx-react";

export interface ITimeWindowListFilterContainerValue {
  startDate?: Date;
  partner: any;
  timeWindowStartHour: any;
  rpuPickupToday: any;
  branch: any;
  city: any;
  province: any;
}

@observer
export class TimeWindowFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  userData: any;
  initialFormValues: ITimeWindowListFilterContainerValue = {
    startDate: new Date(),
    partner: null,
    timeWindowStartHour: "",
    rpuPickupToday: "",
    branch: null,
    city: null,
    province: null,
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.userData = (localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null);
    this.state = {
      filterCollapsed: false,
    };
  }

  async componentDidMount() {
    this.userData = (localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null);

  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  filterCity = (param) => {
    let filterList: any = [];
    if (!!param.province && !!param.province.value) {
      filterList.push({
        field: "provinceId",
        operator: "eq",
        value: param.province.value,
      });
    }
    return filterList;
  };

  filterBranch = (param) => {
    let filterList: any = [];
    if (!!param.province && !!param.province.value) {
      filterList.push({
        field: "provinceId",
        operator: "eq",
        value: param.province.value,
      });
    }
    if (!!param.city && !!param.city.value) {
      filterList.push({
        field: "cityId",
        operator: "eq",
        value: param.city.value,
      });
    }
    return filterList;
  };

  optionTimeWindow = [
    { label: "Semua Jadwal", value: "", selected: true },
    { label: "08:00 - 09:00", value: "8" },
    { label: "09:00 - 10:00", value: "9" },
    { label: "10:00 - 11:00", value: "10" },
    { label: "11:00 - 12:00", value: "11" },
    { label: "12:00 - 13:00", value: "12" },
    { label: "13:00 - 14:00", value: "13" },
    { label: "14:00 - 15:00", value: "14" },
    { label: "15:00 - 16:00", value: "15" },
    { label: "16:00 - 17:00", value: "16" },
    { label: "17:00 - 18:00", value: "17" },
    { label: "18:00 - 19:00", value: "18" },
    { label: "19:00 - 20:00", value: "19" },
    { label: "20:00 - 21:00", value: "20" },
    { label: "21:00 - 22:00", value: "21" },
    { label: "22:00 - 06:00", value: "22" },
  ];

  optionYesNo = [
    { label: "Semua", value: "", selected: true },
    { label: "Ya", value: "Y" },
    { label: "Tidak", value: "N" },
  ];

  onCityChange(e, formik): void {
    formik.handleChange(e);
  }

  onProvinceChange(formik): void {
    formik.handleChange("province");
  }

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }
  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({ filterCollapsed: e.value })}
      >
        {this.userData ?
          <Formik
            ref={this.refFormik}
            enableReinitialize
            initialValues={this.userData.isHeadOffice ? this.initialFormValues : {
              startDate: new Date(),
              endDate: new Date(),
              branch:
                !this.userData.isHeadOffice ? { label: this.userData.branchName, value: this.userData.branchId } : ""
            }}
            onSubmit={() => { }}
          >
            {(formikProps) => (
              <Flex wrap w={1}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-4">Periode</label>
                    <div className="col-md-8">
                      <Flex column>
                        <Calendar
                          inputStyle={{ width: "220px" }}
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Jam SPK</label>
                    <div className="col-md-8">
                      <Dropdown
                        style={{ width: "220px" }}
                        options={this.optionTimeWindow}
                        onChange={formikProps.handleChange("timeWindowStartHour")}
                        value={formikProps.values.timeWindowStartHour}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">SPK Hari Ini</label>
                    <div className="col-md-8">
                      <Dropdown
                        style={{ width: "220px" }}
                        options={this.optionYesNo}
                        onChange={formikProps.handleChange("rpuPickupToday")}
                        value={formikProps.values.rpuPickupToday}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Partner</label>
                    <div className="col-md-8">
                      <PartnerDropdown
                        value={formikProps.values.partner}
                        onChange={formikProps.handleChange("partner")}
                      />
                    </div>
                  </div>
                </Flex>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-4">Provinsi</label>
                    <div className="col-md-8">
                      <ProvinceDropdown
                        id="province"
                        isOptionDisabled={() => !this.userData.isHeadOffice}
                        selectAll={this.userData.isHeadOffice}
                        isClearable={this.userData.isHeadOffice}
                        value={formikProps.values.province}
                        onChange={(e) => {
                          formikProps.setFieldValue("city", "");
                          formikProps.setFieldValue("branch", "");
                          formikProps.setFieldValue("province", "");
                          setTimeout(() => {
                            formikProps.setFieldValue("province", e);
                          }, 50);
                          console.log("update province");
                        }}
                        selectAllLabel="Semua Provinsi"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-4">Kota</label>
                    {formikProps.values.province && (
                      <div className="col-md-8">
                        <CityDropDown
                          id="city"
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          isClearable={this.userData.isHeadOffice}
                          value={formikProps.values.city}
                          onChange={(e) => {
                            formikProps.setFieldValue("branch", "");
                            formikProps.setFieldValue("city", "");
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e);
                            }, 50);
                          }}
                          selectAllLabel="Semua Kota"
                          filters={this.filterCity(formikProps.values)}
                        />
                      </div>
                    )}
                    {!formikProps.values.province && (
                      <div className="col-md-8">
                        <CityDropDown
                          id="city"
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          isClearable={this.userData.isHeadOffice}
                          value={formikProps.values.city}
                          onChange={(e) => {
                            formikProps.setFieldValue("branch", "");
                            formikProps.setFieldValue("city", "");
                            setTimeout(() => {
                              formikProps.setFieldValue("city", e);
                            }, 50);
                          }}
                          selectAllLabel="Semua Kota"
                          filters={null}
                        />
                      </div>
                    )}
                  </div>

                  <div className="form-group">
                    <label className="col-md-4">Cabang</label>
                    <div className="col-md-8">
                      {formikProps.values.province &&
                        !formikProps.values.city && (
                          <BranchDropdown
                            id="branch"
                            isClearable={this.userData.isHeadOffice}
                            isOptionDisabled={() => !this.userData.isHeadOffice}
                            selectAll={this.userData.isHeadOffice}
                            value={formikProps.values.branch}
                            onChange={formikProps.handleChange("branch")}
                            selectAllLabel="Semua Cabang"
                            filters={this.filterBranch(formikProps.values)}
                          />
                        )}
                      {formikProps.values.city &&
                        !formikProps.values.province && (
                          <BranchDropdown
                            id="branch"
                            isOptionDisabled={() => !this.userData.isHeadOffice}
                            selectAll={this.userData.isHeadOffice}
                            value={formikProps.values.branch}
                            onChange={formikProps.handleChange("branch")}
                            isClearable={this.userData.isHeadOffice}
                            selectAllLabel="Semua Cabang"
                            filters={this.filterBranch(formikProps.values)}
                          />
                        )}
                      {formikProps.values.province && formikProps.values.city && (
                        <BranchDropdown
                          id="branch"
                          isOptionDisabled={() => !this.userData.isHeadOffice}
                          selectAll={this.userData.isHeadOffice}
                          isClearable={this.userData.isHeadOffice}
                          value={formikProps.values.branch}
                          onChange={formikProps.handleChange("branch")}
                          selectAllLabel="Semua Cabang"
                          filters={this.filterBranch(formikProps.values)}
                        />
                      )}
                      {!(
                        formikProps.values.province || formikProps.values.city
                      ) && (
                          <BranchDropdown
                            id="branch"
                            isOptionDisabled={() => !this.userData.isHeadOffice}
                            selectAll={this.userData.isHeadOffice}
                            isClearable={this.userData.isHeadOffice}
                            value={formikProps.values.branch}
                            onChange={formikProps.handleChange("branch")}
                            selectAllLabel="Semua branch"
                            filters={null}
                          />
                        )}
                    </div>
                  </div>
                </Flex>
              </Flex>
            )}
          </Formik>
          : null}
      </Panel>
    );
  }
}
