import React from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { LoginContainer } from '../containers/auth/LoginContainer';
import { DirectAccess } from '../containers/auth/DirectAccess';
import RoleAccessContainer from '../containers/auth/RoleAccessContainer';


export const AuthRouter = () => {
  return (
    <Switch>
      <Route exact path="/auth/login" component={LoginContainer} />

      <Route
        exact
        path="/auth/direct-access"
        component={DirectAccess}
      />

      <Route
        exact
        path="/auth/access"
        component={DirectAccess}
      />

      <Route
        exact
        path="/admin/role"
        component={RoleAccessContainer}
      />

      <Route
        exact
        path="/AccountAccess"
        render={() => (
          <div>
            <Redirect to={'auth/login'} />
          </div>
        )}
      />
    </Switch>
  );
};
