// import '../../css/role.css';
// import { Button } from 'primereact/button';
import React from 'react';
import { Redirect } from 'react-router-dom';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { MonitoringRestAuthService } from '../../services/MonitoringRestAuthService';
import { DependencyContainer } from '../../utils/DependencyInjection'
// import { Card } from "primereact/card";
import { observer } from 'mobx-react';
import { observable } from 'mobx';
import { CardContent, Grid, Card, Button } from '@material-ui/core';

interface IRoleState {
  isLoading: false,
}

type Role = {
  branchCode: string,
  branchId: string,
  branchName: string,
  isHeadOffice: boolean,
  roleId: string,
  roleName: string
}

@observer
class RoleAccessContainer extends React.Component<{}, IRoleState> {

  loginData;
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  monitoringRestAuthService = DependencyContainer.get(MonitoringRestAuthService);
  @observable roles: Role[]= []
  @observable selected_role_name= ""
  @observable redirect=false

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
    };
    this.loginData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    this.onSelectRole.bind(this)
  }

  componentDidMount(): void {
    this.monitoringRestAuthService.post('auth/permissionRoles', {
      clientId: "monitoring"
    }, {
      headers: {
        'Accept': 'application/json',
        "Authorization": "Bearer " + this.loginData.accessToken,
      },
    }).subscribe((response) => {
      this.roles=response.roles
    }, (err) => {
      alert(err)
    }, () => {
      if(this.loginData.userId==="1"){
        this.roles.push({
          branchCode: '1201002',//"1101011",
          branchId: "744",
          branchName: 'Bandung Timur',//"Jakpus Kemayoran",
          isHeadOffice: true,
          roleId: "1",
          roleName: "Superadmin"
        })
      }
      // console.log(this.roles)
    })
  }

  onSelectRole(role: Role) {
    Object.assign(this.loginData,role)
    this.loginData.role_name = role.roleName
    delete this.loginData.roleName
    localStorage.setItem("userData", JSON.stringify(this.loginData));
    this.selected_role_name=role.roleName
    this.redirect=true
  }

  logout() {
    localStorage.setItem("userData", "");
    localStorage.setItem("branchCode", "");

    localStorage.clear();
    this.loginData=null
    this.redirect=true
  }

  render() {
    if (this.selected_role_name) {
      if (this.loginData.role_name === "Admin Hub") {
        return <Redirect to={"/admin/attendance-driver"} />;
      } else {
        return <Redirect to={"/admin/dashboard"} />;
      }
    }
    if(this.redirect && !this.loginData){
      return <Redirect to={"/"} />;
    }

    // return (
    //   <div className="login-container">
    //     <div className="login-box">
    //       <img className="login-logo" src="/assets/logo-sicepat.png" alt="logo sicepat" />
    //       <Card title="Pilih Akses Anda">
    //         <div
    //         style={{
    //           overflowY: "auto",
    //           height: 400
    //         }}>
    //           {
    //             this.roles.map((data) => {
    //               return (
    //                 <>
    //                   <div className="p-row-12"
    //                     style={{
    //                       border:"1px solid #ddd"
    //                     }}
    //                   >
    //                     <div className="p-col-12">
    //                       <div className="p-col-12">Rolename:  <b>{data.roleName}</b></div>
    //                       <div className="p-col-12">Branch:  <b>{data.branchName}</b></div>
    //                       <Button className='ml-3' label="Pilih" onClick={() => this.onSelectRole(data)}></Button>
    //                     </div>
    //                   </div>
    //                 </>
    //               )
    //             })
    //           }
    //         </div>
    //       </Card>
    //     </div>
    //   </div>
    // );

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Card
          style={{
            minWidth: 600,
            margin: "10em",
            padding: "50px 25px",
            borderRadius: "13px",
            boxShadow: "0px 0px 30px 0px #565656",
          }}
        >
          <Button
            variant='contained'
            color='primary'
            style={{
              float: "right",
              width: "20%",
              height: "35px",
              fontSize: "13px",
            }}
            onClick={(e) => {
              this.logout();
            }}
          >
            Keluar
          </Button>
          <CardContent>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <div
                style={{
                  fontSize: "23px",
                  textAlign: "center",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                Pilih Akses Anda
              </div>
            </Grid>
            <Grid>
              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  overflowX: "hidden",
                }}
              >                

                {this.roles.map((data) => {
                  return (
                    <>
                        <div
                          style={{
                            borderBottom: "1px solid #cccccc",
                            padding: "10px 0px 10px 0px",
                          }}
                        >
                          <Grid container spacing={8}>
                            <Grid item xs={9}>
                              {`${data.roleName} (${data.branchCode} - ${data.branchName})`}
                            </Grid>
                            <Grid item xs={3}>
                              <Button
                                variant='contained'
                                color='secondary'
                                style={{
                                  float: "right",
                                  width: "20%",
                                  height: "35px",
                                  fontSize: "13px",
                                }}
                                onClick={(e) => {
                                  this.onSelectRole(data);
                                }}
                              >
                                Masuk
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                    </>
                  )
                })
              }
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default RoleAccessContainer