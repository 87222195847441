import PropTypes from "prop-types";
import React, { RefObject } from "react";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import "../../css/CustomAdmin.css";
import { AwbDetailModalComponent } from "../../containers/awb/AwbDetailModalComponent";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { AutoComplete } from "primereact/autocomplete";
import { Growl } from "primereact/growl";
import _ from "lodash";
import { AxiosRequestConfig } from "axios";
import { MonitoringRestAuthService } from "../../services/MonitoringRestAuthService";

export class LayoutAdminTopbar extends React.Component<any, any> {
  restService = DependencyContainer.get(MonitoringRestService);
  monitoringRestAuthService = DependencyContainer.get(
    MonitoringRestAuthService
  );

  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  trackingSites;
  userData;
  growl;
  adminRoles: string[] = [
    "Superadmin",
    "Developer",
    "Control Tower",
    "Developer Mode",
  ];

  static defaultProps = {
    onToggleMenu: null,
  };

  static propTypes = {
    onToggleMenu: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);

    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    this.suggestTrackingSites = this.suggestTrackingSites.bind(this);

    const allowChangeTrackingSite = _.includes(
      this.adminRoles,
      this.userData.role_name
    );
    const allowSearchAwb = !(this.userData.role_name == "Admin Hub");
    this.state = {
      awbNumber: "",
      selectedTrackingSite: null,
      trackingSiteSuggestions: null,
      allowChangeTrackingSite: allowChangeTrackingSite,
      allowSearchAwb: allowSearchAwb,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + userData.accessToken,
      },
    };

    // this.monitoringRestAuthService
    //   .post('auth/permissionRoles', { "clientId": "web" }, axiosConfig)
    //   .subscribe(
    //     async response => {
    //       const roles = await response.roles.map(role => {
    //         role.autocomplete = role.roleName + " " +
    //           role.branchCode + " " + role.branchName
    //         console.log(role, "role");
    //         return role
    //       });

    //       this.trackingSites = roles;
    //     },
    //     () => { },
    //     () => { },
    //   );
  }

  handleTrackingAwbChange = (e) => {
    this.setState({ awbNumber: e.target.value });
  };

  onClickAwbNumber() {
    this.refAwbDetailModal.current!.handleShow(this.state.awbNumber);
  }

  suggestTrackingSites(event) {
    if (this.trackingSites && this.trackingSites.length) {
      let results = this.trackingSites.filter((trackingSite) => {
        return trackingSite.autocomplete
          .toLowerCase()
          .includes(event.query.toLowerCase());
      });

      this.setState({ trackingSiteSuggestions: results });
    }
  }

  onChangeTrackingSite(selectedTrackingSite = null) {
    const ts = selectedTrackingSite
      ? selectedTrackingSite
      : this.state.selectedTrackingSite;
    if (ts && ts.branchCode) {
      let msg = {
        severity: "success",
        summary: "",
        detail: "Cabang telah diubah.",
      };
      this.growl.show(msg);
    }

    this.setState({
      selectedTrackingSite: null,
      trackingSiteSuggestions: null,
    });
  }

  render() {
    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <div className="layout-topbar clearfix">
          <button
            className="p-link layout-menu-button"
            onClick={this.props.onToggleMenu}
          >
            <span className="pi pi-bars" />
          </button>

          {this.state.allowSearchAwb ? (
            <span className="awb-topbar-search">
              <div className="p-inputgroup">
                <InputText
                  type="text"
                  placeholder="Search Awb"
                  className="awb-topbar-search-input"
                  onChange={this.handleTrackingAwbChange}
                />
                <Button
                  icon="pi pi-search"
                  className="awb-topbar-search-btn"
                  onClick={() => this.onClickAwbNumber()}
                />
              </div>
            </span>
          ) : (
              ""
            )}

          {false ? (
            <span className="awb-topbar-search">
              <div className="p-inputgroup">
                <AutoComplete
                  value={this.state.selectedTrackingSite}
                  suggestions={this.state.trackingSiteSuggestions}
                  completeMethod={this.suggestTrackingSites}
                  field="autocomplete"
                  size={25}
                  placeholder="Ubah Role"
                  minLength={1}
                  onChange={(e) =>
                    this.setState({ selectedTrackingSite: e.value })
                  }
                  onSelect={(e) => this.onChangeTrackingSite(e.value)}
                />
                {/* <Button icon="pi pi-check" className="awb-topbar-search-btn" onClick={() => this.onChangeTrackingSite()} /> */}
              </div>
            </span>
          ) : (
              <span></span>
            )}

          {/* <div className="layout-topbar-icons">
            <button className="p-link">
              <span className="layout-topbar-item-text">Settings</span>
              <span className="layout-topbar-icon pi pi-cog" />
            </button>
            <button className="p-link">
              <span className="layout-topbar-item-text">User</span>
              <span className="layout-topbar-icon pi pi-user" />
            </button>
          </div> */}
        </div>
      </div>
    );
  }
}
