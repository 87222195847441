import React, { Component } from 'react';
import style from './time-picker.module.scss';
import { Calendar } from 'primereact/calendar';

class TimePicker extends Component<any, any> {
    constructor(props: any) {
        super(props);
        this.state = {
            value: props.value !== undefined ? props.value : new Date(),
        };
    }

    onChange = (val: any) => {
        this.setState({ value: val.value });
        this.props.onChange(val);
    };

    render() {
        const { value, ...others } = this.props;
        return (
            <Calendar
                {...others}
                value={this.state.value}
                onChange={this.onChange}
                showTime={true}
                showSeconds={false}
                className={style.timepicker}
                timeOnly={true}
                showWeek={false}
            />
        );
    }
}

export default TimePicker;
