import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";
import _ from "lodash";

import { Link } from "react-router-dom";
import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  CourierPickupSummaryFilterContainer,
  ICourierDeliverySummaryFilterContainerValue,
} from "./CourierPickupFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";
import { Formik } from "formik";
import { Grid, FormGroup, Input, FormControl } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { observable } from "mobx";
import { ColumnGroup } from "primereact/columngroup";
import { Row } from "primereact/row";
import { AxiosRequestConfig } from "axios";

export interface IAwbListContainerQuery {
  filters: ICourierDeliverySummaryFilterContainerValue;
}

@observer
export class CourierPickupSummaryContainer extends React.Component<
  RouterProps,
  any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable public total: number = 0;


  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<CourierPickupSummaryFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      showCapacityDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      modal: null,
      expandedRows: null,
      rowDays: [],
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
      }
    }
    console.log(this.refFilter.current!.formValues, "new filter");





    this.dataSource.onPageChange.subscribe(() => {
      setTimeout(() => { this.loadData(); }, 1000);

    });
    this.dataSource.setPage(1);
  }

  dateCalculate = (startDate, endDate) => {
    const dt1 = new Date(startDate);
    const dt2 = new Date(endDate);
    return Math.floor(
      (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
        Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
      (1000 * 60 * 60 * 24)
    );
  };

  dateColumnCalculate = () => {
    let arrDate: any[] = [];
    const filter = this.refFilter.current!.formValues;
    const diffDate = this.dateCalculate(filter.startDate, filter.endDate);
    for (var i = 0; i < diffDate + 1; i++) {
      arrDate.push(
        moment(filter.startDate).add(i, "days").format("YYYY-MM-DD")
      );
    }
    this.setState({ rowDays: arrDate });
  };

  loadData(filterParams: any = null) {
    this.setState({ rowDays: [] });
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    this.setState({ expandedRows: null });

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }
    if (fParams.branch && fParams.branch.value) fParams.branchId = fParams.branch.value;

    if (fParams.partner && fParams.partner.value) fParams.partnerId = fParams.partner.value;

    if (fParams.city && fParams.city.value) fParams.cityId = fParams.city.value;

    if (fParams.province && fParams.province.value) fParams.provinceId = fParams.province.value;

    fParams.format = "list";
    console.log(fParams, "fparams");

    //code for calculate date column
    this.dateColumnCalculate();

    this.dataSource.loading = true;
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };

    this.monitoringRestService.post("/CourierDelivery/list", fParams, axiosConfig).subscribe(
      (response) => {
        this.data = response;
        this.populateDataSource();
      },
      (err) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any = null, loadDataAll: boolean = false) {
    console.log(filterParams, loadDataAll, "load data all");
    
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    this.setState({ expandedRows: null });

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }
    if (fParams.branch && fParams.branch.value) fParams.branchId = fParams.branch.value;

    if (fParams.partner && fParams.partner.value) fParams.partnerId = fParams.partner.value;

    if (fParams.city && fParams.city.value) fParams.cityId = fParams.city.value;

    if (fParams.province && fParams.province.value) fParams.provinceId = fParams.province.value;

    // fParams.format = "xls";
    console.log(fParams, "fparams");

    this.dataSource.loading = true;
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
      responseType: 'blob',
    };
    this.monitoringRestService
      .post("/CourierDelivery/list/excel", fParams, axiosConfig).subscribe((response)=>{
        this.exportFile(response,`List Courier Pickup ${fParams.startDate} sd ${fParams.endDate}`)
      },
      (err) => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      })
  }

  exportFile = async (response: any, fileName: string) => {
    let type = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    const blob = new Blob([response], { type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName.split(".").join("");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });
    console.log(podData, "pod data");

    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = {
        severity: "success",
        summary: "Success Message",
        detail: "Export Success",
      };
      this.growl.show(msg);
    }
  }

  populateDataSource() {
    for (const dt of this.data.data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource();
    }
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

  //row expand

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  getPerc = (obj, div) => {
    return div > 0 ? ((obj / div) * 100).toFixed(2) : "0";
  };

  render() {
    const footer = "Total data: " + this.dataSource.total;

    let dateColumn = this.state.rowDays.map((day) => {
      const colPickField = "pick-" + day;
      const colAddressField = "address-" + day;
      const colManualField = "manual-" + day;

      return [
        // <Column
        //   field={colPickField}
        //   header="Pick"
        //   style={{
        //     width: "70px",
        //     textAlign: "center",
        //     "vertical-align": "text-right",
        //   }}
        //   body={(row) => {
        //     let url = "/admin/courier-pickup-mongo/detail";

        //     url +=
        //       "?status=pick&title=Pick&nik=" + row.nik + "&startDate=" + day;

        //     return (
        //       <a
        //         href="javascript:void(0);"
        //         onClick={async () => {
        //           window.open(url, "_blank");
        //         }}
        //         style={{ textAlign: "center" }}
        //       >
        //         {row[colPickField]}
        //       </a>
        //     );
        //   }}
        // />,
        // <Column
        //   field={colAddressField}
        //   header="Address"
        //   style={{
        //     width: "70px",
        //     textAlign: "center",
        //     "vertical-align": "text-right",
        //   }}
        //   body={(row) => {
        //     let url = "/admin/courier-pickup-mongo/detail/address";

        //     url +=
        //       "?status=pick&title=Address&nik=" + row.nik + "&startDate=" + day;
        //     return (
        //       <a
        //         href="javascript:void(0);"
        //         onClick={async () => {
        //           window.open(url, "_blank");
        //         }}
        //         style={{ textAlign: "center" }}
        //       >
        //         {row[colAddressField]}
        //       </a>
        //     );
        //   }}
        // />,
        <Column
          field={colManualField}
          header="Titik Manual"
          style={{
            width: "70px",
            textAlign: "center",
            "vertical-align": "text-right",
          }}
          body={(row) => {
            let url = "/admin/courier-pickup-mongo/detail/manual";

            url +=
              "?status=pick&title=Manual&nik=" + row.nik + "&startDate=" + day;
            return (
              <a
                href="javascript:void(0);"
                onClick={async () => {
                  window.open(url, "_blank");
                }}
                style={{ textAlign: "center" }}
              >
                {row[colManualField]}
              </a>
            );
          }}
        />,
      ];
    });

    let dateColumnHeader = this.state.rowDays.map((day) => {
      return <Column header={day} colSpan={1} />;
    });

    let dateColumnHeaderDetail = this.state.rowDays.map(() => {
      return [
      // <Column header="Resi" />, <Column header="Titik PU" />, 
      <Column header="Titik Manual" />];
    });


    let groupColumnHeader = (
      <ColumnGroup>
        <Row>
          <Column header="Nik" rowSpan={2} />
          <Column header="Nama" rowSpan={2} />
          <Column header="Jabatan" rowSpan={2} />
          <Column header="Cabang" rowSpan={2} />
          <Column header="Region" rowSpan={2} />
          {dateColumnHeader}
          <Column header="Total" rowSpan={2} />
          <Column header="Average" rowSpan={2} />
        </Row>
        <Row>{dateColumnHeaderDetail}</Row>
      </ColumnGroup>
    );

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <CourierPickupSummaryFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary mr-2"
                label="Export Excel"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                onClick={() => this.downloadCsv()}
                disabled={this.state.exportExcelLoading}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            expandedRows={this.state.expandedRows}
            dataKey="nik"
            headerColumnGroup={groupColumnHeader}
            onSort={(e) => {
              this.onSorting(e);
            }}
            onValueChange={() => {
              dateColumnHeaderDetail = null;
              dateColumnHeader = null;
            }}
            footer={footer}
          >
            <Column
              field="nik"
              header="Nik"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="employeeName"
              header="Nama"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="title"
              header="Jabatan"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="branchName"
              header="Cabang"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="region"
              header="Region"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            {dateColumn}
            <Column
              field="totalPick"
              header="Total"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="averagePick"
              header="Average"
              sortable={true}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
          </DataTable>
          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
