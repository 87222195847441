import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";

import qs from "querystring";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import QueryString from "qs";
import { Button } from "primereact/button";
import { observer } from "mobx-react";
import { ExportToCsv } from "export-to-csv";

import { observable } from "mobx";
import { AxiosRequestConfig } from "axios";
@observer
export class CourierPickupDetailManual extends React.Component<
  RouterProps,
  any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  data;
  dt;
  growl;
  dataSource = new DataSource<any>();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  filterParams: any;
  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      total: 0,
    };

    // this.awbNumberFilter = this.awbNumberFilter.bind(this);
  }

  componentDidMount() {
    this.filterParams = QueryString.parse(location.search.replace("?", ""));
    console.log(this.filterParams, "filter params");
    if (this.filterParams.name) {
      let statusName = "";
      let title = "";
      switch (this.filterParams.name.toLowerCase()) {
        //summary
        case "summary jumlah resi kemarin": {
          statusName = "spk_yes";
          title = "Jumlah Resi Kemarin";
          break;
        }
        case "summary jumlah spk hari ini": {
          statusName = "spk_today";
          title = "Jumlah SPK Hari Ini";
          break;
        }
        case "summary total spk hari ini": {
          statusName = "total_awb";
          title = "Total Resi Hari Ini";
          break;
        }
        case "summary pick": {
          statusName = "picked";
          title = "Pick";
          break;
        }
        case "summary unpick": {
          statusName = "unpick";
          title = "Unpick";
          break;
        }

        //detail
        case "detail jumlah resi kemarin": {
          statusName = "spk_yes";
          title = "Jumlah Resi Kemarin";
          break;
        }
        case "detail jumlah spk hari ini": {
          statusName = "spk_today";
          title = "Jumlah SPK Hari Ini";
          break;
        }
        case "detail total spk hari ini": {
          statusName = "total_awb";
          title = "Total Resi Hari Ini";
          break;
        }
        case "detail pick": {
          statusName = "picked";
          title = "Pick";
          break;
        }
        case "detail unpick": {
          statusName = "unpick";
          title = "Unpick";
          break;
        }
        case "detail drop": {
          statusName = "drop";
          title = "Drop";
          break;
        }
        case "detail spk assign": {
          statusName = "assign";
          title = "Assign";
          break;
        }
        case "detail spk not assign": {
          statusName = "not_assign";
          title = "Not Assign";
          break;
        }
        case "detail unpick reschedule": {
          statusName = "unpicked_reschedule";
          title = "Unpick Reschedule";
          break;
        }

        case "detail unpick not reschedule": {
          statusName = "unpicked_not_reschedule";
          title = "Unpick Not Reschedule";
          break;
        }

        //pickup
        case "pickup pick": {
          statusName = "rpu";
          title = "Pick Plan";
          break;
        }

        case "pickup drop": {
          statusName = "rpu_drop";
          title = "Drop Plan";
          break;
        }

        default: {
          statusName = this.filterParams.name;
          break;
        }
      }

      console.log(this.filterParams.name, statusName);
      this.filterParams.status = statusName;
      this.filterParams.title = title;
    }
    this.dataSource.onPageChange.subscribe(() => {
      this.loadData(this.filterParams);
    });

    this.dataSource.setPage(1);
  }

  loadData(filterParams: any) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = Object.assign(
      {},
      filterParams,
      pagingParams,
      sortingParams
    );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    if (fParams.branch) {
      fParams.branchId = fParams.branch.value;
    }

    if (fParams.partner) fParams.partnerId = fParams.partner.value;

    fParams.filterParams = filterParams;

    this.filterParams = filterParams;

    // fParams.fParameters = JSON.stringify(filterParams);

    console.log(fParams, "fparams");
    this.dataSource.loading = true;
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.monitoringRestService
      .post(`/CourierDelivery/list/detail/manual`, fParams, axiosConfig)
      .subscribe(
        (response) => {
          console.log(response, "response");
          this.data = response;
          this.setState({ data: response });
          this.populateDataSource();
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  openCsv(url) {
    const win = window.open(url, "_blank");
    if (win) win.focus();
  }

  awbNumberFilter = (filters) => {
    if (filters.filters.awb_number) {
      this.filterParams.awbNumber = filters.filters.awb_number.value;
    } else {
      this.filterParams.awbNumber = "";
    }
    this.loadData(this.filterParams);
  };

  downloadCsv(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = Object.assign(
      {},
      filterParams,
      pagingParams,
      sortingParams
    );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );

    if (fParams.branch) {
      fParams.branchId = fParams.branch.value;
    }

    if (fParams.partner) fParams.partnerId = fParams.partner.value;

    fParams.filterParams = filterParams;

    this.filterParams = filterParams;
    fParams.format = "csv";
    this.filterParams = filterParams;

    this.dataSource.loading = true;
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };

    console.log(fParams, "filter params");

    this.monitoringRestService
      .post("/CourierDelivery/list/detail/manual", fParams, axiosConfig)
      .subscribe(
        async (response) => {
          if (response) {
            console.log(response, "response");
            this.openCsv(response.url);
            this.dataSource.loading = false;
          }
        },
        () => {
          let msg = {
            severity: "error",
            summary: "Error Message",
            detail: "Export failed",
          };
          this.growl.show(msg);
        },
        () => {
          let msg = {
            severity: "success",
            summary: "Success Message",
            detail: "Export Success",
          };
          this.growl.show(msg);
        }
      );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc().add(7,'hours')
        .format("DD-MM-YYYY")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    this.loadData(this.filterParams);
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;
    console.log(this.dataSource.total, this.dataSource.data, "data source");

    return (
      <div>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <div className="card">
          <div className="row">
            {this.filterParams ? (
              <h3 style={{ float: "left", marginLeft: "10px" }}>
                {this.filterParams.title}
              </h3>
            ) : (
              <h3></h3>
            )}

            <Button
              type="button"
              className="p-button-info p-button-raised"
              style={{ float: "right", marginRight: "10px" }}
              label="Export To Csv"
              onClick={() => this.downloadCsv(this.filterParams)}
            />
          </div>
          <DataTable
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            ref={(el) => (this.dt = el)}
            loading={this.dataSource.loading}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={(e) => {
              this.onSorting(e);
            }}
            onFilter={this.awbNumberFilter}
            footer={footer}
          >
            <Column
              field="work_order_date"
              header="Work Order Date"
              body={this.columnDateFormat}
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="merchant_name"
              header="Merchant"
              style={{
                width: "130px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
            <Column
              field="pickup_address"
              header="Address"
              style={{
                width: "260px",
                textAlign: "center",
                "vertical-align": "text-top",
              }}
            />
          </DataTable>
          <Paginator
            pageLinkSize={10}
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={(e) => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}
