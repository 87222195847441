import mainDropDown from '../mainDropDown';
import { UrlHost } from '../constant/url';

class CityDropDown extends mainDropDown {

    constructor(props: any) {
        super(props);

        let urlProps = UrlHost() + `/master/cities/list`

        const myState = {
            key: props.key !== undefined ? props.key : 'cityId',
            province: props.provinceId,
            limit: 10,
            page: 1,
            sortBy: props.sortBy !== undefined ? props.sortBy : 'cityName',
            sortDir: props.sortDir !== undefined ? props.sortDir : 'asc',
            filters: props.filters !== undefined ? props.filters : {},
            url: urlProps,
            placeholder:
                props.placeholder !== undefined
                    ? props.placeholder
                    : 'Pilih Kota',
            template:
                props.template !== undefined
                    ? props.template
                    : '{{cityName}}',
            selectAll: props.selectAll !== undefined ? props.selectAll : false,
            selectAllLabel:
                props.selectAllLabel !== undefined
                    ? props.selectAllLabel
                    : 'Semua',
        };
        Object.assign(this.state, myState);
    }

    componentWillReceiveProps(props) {

    }
}

export default CityDropDown;
