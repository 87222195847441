import { observable } from "mobx";
import React from "react";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { observer } from "mobx-react";
import EmbedComponent from "./EmbedComponent";
import { getHeaders } from "../../component/constant/Header";
import { Flex } from "reflexbox";
import DateRangePicker from "../../component/DateRangePicker/AppDateRangePicker";
import moment from "moment";

@observer
class MonitoringSTT extends React.Component {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  @observable tabs = {
    id: 2717,
    label: "Monitoring STT",
    url: '',
    token: '',
  };

  @observable filter = {
    dateRange: [new Date(), new Date()],
  };

  getUrlEmbed = () => {
    let params = {
      start_date: moment(this.filter.dateRange[0]).format("YYYY-MM-DD"),
      end_date: this.filter.dateRange[1] ? moment(this.filter.dateRange[1]).format("YYYY-MM-DD") : moment(this.filter.dateRange[0]).format("YYYY-MM-DD"),
    };
    this.monitoringRestService
      .get("embed-new/" + this.tabs.id, {
        params,
        headers: getHeaders(),
      })
      .subscribe((response) => {
        this.tabs.url = response.url
        this.tabs.token = response.token
      });
  }

  filterComponent = () => {
    return (
      <>
        <Flex>
          <Flex column>
            <div className="form-group">
              <label className="col-md-5">Periode:</label>
              <div className="col-md-6">
                <DateRangePicker
                  onChange={(e) => {
                    this.filter.dateRange = e.value;
                  }}
                  value={this.filter.dateRange}
                />
              </div>
            </div>
          </Flex>
        </Flex>
      </>
    );
  }

  render() {
    return (
      <EmbedComponent
        filterComponent={this.filterComponent}
        title={this.tabs.label}
        tabs={this.tabs}
        getUrlEmbed={this.getUrlEmbed}
      />
    )
  }
}

export default MonitoringSTT;