import React from "react";
import { observable } from "mobx";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { observer } from "mobx-react";
import BranchDropdown from "../../component/select/branchDropDown";
import { Flex } from "reflexbox";
import { getHeaders, getUserData } from "../../component/constant/Header";
import EmbedComponent from "./EmbedComponent";
import AppDateRangePicker from "../../component/DateRangePicker/AppDateRangePicker";
import moment from "moment";
import { Chips } from "primereact/chips";
import { Panel } from "primereact/panel";

interface IFilters {
  branchCode: string[],
  branchId: string[],
  dateRange: Date[],
  nik: string[],
  currentValueNIK: string
}

@observer
class MonitoringCourierPickup extends React.Component {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  @observable activeTab = 0;

  yesterday = new Date()

  @observable filters: IFilters = {
    branchCode: [this.userData.branchCode],
    branchId: [this.userData.branchId],
    dateRange: [this.yesterday, this.yesterday],
    nik: [],
    currentValueNIK: ''
  }

  @observable tabs = [
    {
      id: 2807,
      label: "Productivity First Mile",
      url: '',
      token: '',
    },
    {
      id: 2854,
      label: "Detail Resi",
      url: '',
      token: '',
    },
    {
      id: 2855,
      label: "Detail Titik Pickup",
      url: '',
      token: '',
    }
  ]

  get userData() {
    return getUserData()
  };

  get showFilterCabang() {
    return this.userData.branchCode === '3601001'
  }

  get tab() {
    return this.tabs[this.activeTab]
  }

  constructor() {
    super({})
    this.yesterday.setDate(this.yesterday.getDate() - 1)
  }

  componentDidMount(): void {
    this.getUrlEmbed()
  }

  onChangeTab = (index: number) => {
    this.activeTab = index
  }

  getUrlEmbed = () => {
    let params = {
      branch_code: this.filters.branchCode,
      start_date: moment(this.filters.dateRange[0]).format("YYYY-MM-DD"),
      end_date: this.filters.dateRange[1] ? moment(this.filters.dateRange[1]).format("YYYY-MM-DD") : moment(this.filters.dateRange[0]).format("YYYY-MM-DD"),
      nik: this.filters.nik
    };
    this.monitoringRestService.get("embed-new/" + this.tab.id, {
      params,
      headers: getHeaders(),
    }).subscribe((response) => {
      this.tab.url = response.url
      this.tab.token = response.token
    });
  }

  filterComponent = () => {
    return (
      <>
        <Flex className="w-full" column>
          {this.showFilterCabang &&
            <div className="form-group">
              <label className="col-md-1">Cabang:</label>
              <div className="col-md-5">
                <BranchDropdown
                  closeMenuOnSelect={false}
                  isMulti
                  style={{ width: '100%' }}
                  id="branch"
                  filters={null}
                  onChange={(value) => {
                    const branchCodes = (value || []).map((val) => val.metadata.branchCode)
                    const branchIds = (value || []).map((val) => val.metadata.branchId)
                    this.filters = {
                      ...this.filters,
                      branchCode: branchCodes,
                      branchId: branchIds
                    }
                  }}
                  template={`{{branchName}} - ({{branchCode}})`}
                />
              </div>
            </div>
          }

          <div className="form-group">
            <label className="col-md-1">Periode:</label>
            <div className="col-md-5">
              <AppDateRangePicker
                onChange={(e) => {
                  this.filters.dateRange = e.value
                }}
                value={this.filters.dateRange}
              />
            </div>
          </div>

          <div className="form-group">
            <label className="col-md-1">NIK:</label>
            <div className="col-md-5">
                <Chips
                  value={this.filters.nik}
                  onChange={(e) => {
                    this.filters.nik = e.value
                  }}
                />
            </div>
          </div>
        </Flex>
      </>
    );
  }

  componentHowTo=(
  <Flex className="card w-full">
    <Flex className="w-full" column>
      <Panel header="Note">
        <li>Cara menggunakan Filter <b>Nik</b>: Tekan Enter setelah mengetik Nik</li>
        </Panel>
    </Flex>
  </Flex>)

  render() {
    return (
      <EmbedComponent
        componentHowTo={this.componentHowTo}
        title={"Productivity First Mile"}
        filterComponent={this.filterComponent}
        tabs={this.tabs}
        getUrlEmbed={this.getUrlEmbed}
        activeTab={this.activeTab}
        onChangeTab={this.onChangeTab}
      />
    )
  }
}

export default MonitoringCourierPickup;