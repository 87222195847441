import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  ITimeWindowListFilterContainerValue,
  TimeWindowFilterContainer
} from "./TimeWindowFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";
import { TabView, TabPanel } from "primereact/tabview";
import { TimeWindowSummaryContainer } from "./TimeWindowSummaryContainer";
import { TimeWindowDetailContainer } from "./TimeWindowDetailContainer";
import { v4 as uuidv4 } from "uuid";

export interface ITimeWindowListContainerQuery {
  filters: ITimeWindowListFilterContainerValue;
}

@observer
export class TimeWindowContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<TimeWindowFilterContainer> = React.createRef();

  refSummary: RefObject<TimeWindowSummaryContainer> = React.createRef();
  refDetail: RefObject<TimeWindowDetailContainer> = React.createRef();

  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();


  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      showDialog: false,
      filterCollapsed: false,
      activeTabIndex: 1,
      sortField: null,
      sortOrder: null
    };
  }

  getPerc = (obj, div) => {
    return div > 0 ? ((obj / div) * 100).toFixed(2) : "0";
  };

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
    this.dataSource.setPage(1);
  }

  loadData(filterParams: any = null) {
    const fParams = this.refFilter.current!.formValues;
    console.log("master load data ", fParams, this.state.activeTabIndex === 1);
    if ((this.state as any).activeTabIndex === 1) {
      this.refDetail.current!.loadData(fParams);
    } else {
      this.refSummary.current!.loadData(fParams);
    }
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = this.refFilter.current!.formValues;
    if ((this.state as any).activeTabIndex === 1) {
      const filterParam = Object.assign({}, fParams);
      if (filterParam.startDate)
        filterParam.startDate = DateHelper.formatLocalDateForQueryParam(
          filterParam.startDate
        );
      if (filterParam.endDate)
        filterParam.endDate = DateHelper.formatLocalDateForQueryParam(
          filterParam.endDate
        );


      if (fParams.branch) fParams.branchId = fParams.branch.value;
      if (fParams.province) fParams.provinceId = fParams.province.value;
      if (fParams.city) fParams.cityId = fParams.city.value;

      if (fParams.partner) fParams.partnerId = fParams.partner.value;


      const resPath =
        filterParam.slaType! && filterParam.slaType === "external"
          ? "/TimeWindow-pg/List/Summary"
          : "/TimeWindow-pg/List/Summary";

      fParams.format = "xls";
      filterParam.fParameters = JSON.stringify(filterParam);
      console.log(filterParam, "filterparam");

      this.monitoringRestService.post(resPath, fParams).subscribe(
        (response) => {
          if (response) {
            console.log(response, "response");
            const data = response.data.map((row) => {
              return {
                branch_name: row.branch_name,
                sigesit: row.sigesit,
                pickup_point: row.pickup_point,
                spk_yes: row.total_spk_yesterday,
                spk_today: row.total_spk,
                total_awb: row.total_spk_today,
                spk_assign: row.total_assigned,
                spk_not_assign: row.total_not_assigned,
                drop: row.total_dropped,
                dropPCT: this.getPerc(row.total_dropped, row.total_spk_today),
                pick: row.total_picked,
                pickPCT: this.getPerc(row.total_picked, row.total_spk_today),
                pick_on_time: row.total_pick_on_time,
                pick_on_time_pct: this.getPerc(row.total_pick_on_time, row.total_picked),
                pick_late_time: row.total_pick_late_time,
                pick_late_time_pct: this.getPerc(row.total_pick_late_time, row.total_picked),
                unpick: row.total_unpicked,
                unpick_pct: this.getPerc(row.total_unpicked, row.total_spk_today),
                rpu: row.total_pickup_request_type,
                auto_pop: row.total_auto_pop,
                auto_pop_pct: this.getPerc(row.total_auto_pop, row.total_spk_today),
                not_pop: row.total_not_pop,
                manifest: row.total_manifested,
                not_manifest: row.total_not_manifested,
              };
            });
            const date = new Date();
            const options = {
              fieldSeparator: ",",
              filename: `${moment(date).format(
                "YYYY-MM-DD"
              )}:${uuidv4()}_time_window`,
              quoteStrings: '"',
              decimalSeparator: ".",
              showLabels: true,
              useTextFile: false,
              useBom: true,
              headers: [
                "Cabang",
                "Si gesit",
                "Pickup poin",
                "Jumlah resi kemarin",
                "Jumlah spk hari ini",
                "Total spk hari ini",
                "Spk assign",
                "Spk not assign",
                "Drop",
                "%Drop",
                "Pick",
                "%Pick",
                "Pick on time",
                "%Pick on time",
                "Pick late time",
                "%Pick late time",
                "Unpick",
                "%Unpick",
                "RPU plan pickup",
                "Auto pop",
                "%Auto pop",
                "Not pop",
                "Manifest",
                "Not manifest",
              ],
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(data);
          }
        },
        () => {
          this.setState({ exportExcelLoading: false, showExportDialog: false });
          let msg = {
            severity: "error",
            summary: "Error Message",
            detail: "Export failed",
          };
          this.growl.show(msg);
        },
        () => {
          this.setState({ exportExcelLoading: false, showExportDialog: false });
          let msg = {
            severity: "success",
            summary: "Success Message",
            detail: "Export Success",
          };
          this.growl.show(msg);
        }
      );
    }
  }



  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }


  downloadPdf(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("Monitoring/LPB/list", fParams).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
        console.log(response, "response");
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      console.log(this.data.data, "data in data");
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = awbNumber => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };


  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
      </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <TimeWindowFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.loadData();
                }}
              />
              <Button
                className="p-button-primary mr-2"
                label="Export Csv"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                onClick={() => this.downloadCsv()}
                disabled={this.state.exportExcelLoading}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <TabView
            activeIndex={this.state.activeTabIndex}
            onTabChange={(e) => {
              this.setState({ activeTabIndex: e.index });
              setTimeout(() => this.loadData(), 500);
            }}
          >
            <TabPanel header="Summary">
              <TimeWindowSummaryContainer ref={this.refSummary} />
            </TabPanel>

            <TabPanel header="Detail">
              <TimeWindowDetailContainer ref={this.refDetail} />
            </TabPanel>
          </TabView>
        </div>
      </div>);
  }
}
