import React from 'react';
import qs from 'qs';
import { Redirect } from 'react-router-dom';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';

export interface IDirectAccess {
  api_key: any;
  uname: any;
} 

export class DirectAccess extends React.Component<any, any> {
  
  queryParameters;
  loginData
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  constructor(props, context) {
    super(props, context);
    this.state = {
      loading: true,
      redirect: false,
    };
    
    this.login = this.login.bind(this);
  }
  
  componentDidMount() {
    this.queryParameters = qs.parse(
      this.props.history.location.search.substr(1),
    );

    this.login();
  }

  login() {
    this.setState({ loading: true });

    this.monitoringRestService
      .post('auth/login-redirect', {'apiKey': this.queryParameters.api_key})
      .subscribe(
        response => {
          this.loginData = response;
        },
        error => {
          alert('Login gagal, api key tidak terdaftar!');
          this.setState({loading: false, redirect: true});
        },
        () => {
          if (this.loginData) {
            localStorage.setItem('branchCode', this.queryParameters.uname)
            localStorage.setItem('userData', JSON.stringify(this.loginData));
            this.setState({redirect: true});
          }

          this.setState({loading: false});
        },
      );
  }

  render() {
    if(this.state.loading){
      return '';
    }

    if (this.state.redirect || localStorage.getItem('userData')) {
      return (<Redirect to={'/admin/dashboard'}/>);
    } else {
      return (<Redirect to={'/'}/>);
    }
  }
}
