import moment from "moment";

export abstract class DateHelper {
  public static getCurrentWibMomentTime(): moment.Moment {
    return moment.utc().add(420, 'm');
  }

  // checking if a string is empty, null or undefined
  // https://stackoverflow.com/questions/154059/how-to-check-empty-undefined-null-string-in-javascript
  public static formatLocalDateTime(date, format): string {
    if (!date) return null as any;
    const d = moment(date);
    return (d.isValid()) ? moment(date).format(format) : (null as any);
  }

  public static formatLocalDateForQueryParam(date): string {
    return this.formatLocalDateTime(date, 'YYYY-MM-DD');
  }

  public static formatDateTimeAsIs(date, format): string {
    if (!date) return null as any;
    const d = moment(date);
    return (d.isValid()) ? moment(date).utc().format(format) : (null as any);
  }

  public static formatDateAsIsCommon(date): string {
    return moment(date).utc().format('DD-MMM-YYYY');
  }

  public static formatDateTimeAsIsCommon(date): string {
    return moment(date).utc().format('DD-MMM-YYYY hh:mm');
  }
}