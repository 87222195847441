import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  ICapacityPickupFilterContainerValue,
  CapacityPickupFilterContainer
} from "./CapacityPickupFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { DataDemo } from "../../demo/components/DataDemo";
import { ExportToCsv } from "export-to-csv";
import { Formik } from "formik";
import { Grid, FormGroup, Input, FormControl } from "@material-ui/core";
import { observable } from "mobx";
import { AxiosRequestConfig } from "axios";

export interface IAwbListContainerQuery {
  filters: ICapacityPickupFilterContainerValue;
}

@observer
export class CapacityPickupContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;

  @observable public total: number = 0;

  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<CapacityPickupFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      showCapacityDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      modal: null
    };
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
    this.dataSource.setPage(1);
  }

  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }
    if (fParams.branch)
      fParams.branchId = fParams.branch.value;

    if (fParams.partner)
      fParams.partnerId = fParams.partner.value;

    if (fParams.city)
      fParams.cityId = fParams.city.value;

    if (fParams.province)
      fParams.provinceId = fParams.province.value;

    fParams.format = "list";

    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.dataSource.loading = true;
    this.monitoringRestService.post("/CapacityPickup/list", fParams, axiosConfig).subscribe(
      response => {
        this.data = response;
        this.populateDataSource();
      },
      err => {
        this.dataSource.loading = false;
      },
      () => {
        this.dataSource.loading = false;
      }
    );
  }

  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = filterParams
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    fParams.fParameters = JSON.stringify(fParams);
    fParams.format = "xls";
    this.monitoringRestService.post("/CapacityPickup/List", fParams, axiosConfig).subscribe(
      response => {
        if (response) {
          this.openCsv(response);
        }
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success"
        };
        this.growl.show(msg);
        this.setState({ exportExcelLoading: false, showExportDialog: false });
      }
    );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }


  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = awbNumber => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };


  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    await this.loadData();
  }


  getPerc = (obj, div) => {
    return div > 0 ? (obj / div * 100).toFixed(2) : "0";
  };

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <Dialog
          header="Capacity"
          visible={this.state.showCapacityDialog}
          style={{ width: "60vw" }}
          modal={true}
          onHide={() => {
            this.setState({
              showCapacityDialog: false,
              modal: null
            });

            this.dataSource.setPage(1);
          }}
        >

          {this.state.modal ?
            <Formik
              initialValues={{
                PUOpsCap: this.state.modal.PUOpsCap,
                PUCap: this.state.modal.PUCap,
                branchId: this.state.modal.branchId,
                PUOpsCapFreelance: this.state.modal.PUOpsCapFreelance,
                PUOpsCapDriver: this.state.modal.PUOpsCapDriver,
                PUTarget: this.state.modal.PUTarget
              }}
              onSubmit={val => {
                const axiosConfig: AxiosRequestConfig = {
                  headers: {
                    Authorization: "Bearer " + this.userData.accessToken,
                    Accept: 'application/json'
                  },
                };

                let search = window.location.search;
                let params = new URLSearchParams(search);

                let paramsInsert = {
                  PUOpsCap: 0,
                  PUCap: val.PUCap,
                  branchId: val.branchId,
                  PUOpsCapFreelance: val.PUOpsCapFreelance,
                  PUOpsCapDriver: val.PUOpsCapDriver,
                  PUTarget: val.PUTarget
                };

                this.monitoringRestService.post("/CapacityPickup/branchCap", paramsInsert, axiosConfig).subscribe(
                  async response => {

                    if (response.data.message) {
                      this.growl.show({
                        severity: "info",
                        summary: response.data.message
                      });
                    }
                    this.setState({
                      isLoading: false,
                      modal: null,
                      showCapacityDialog: false
                    });
                    setTimeout(function () {
                      dataSource.setPage(1);
                    }, 1000);
                  },
                  err => {
                    this.dataSource.loading = false;
                  },
                  () => {
                    this.dataSource.loading = false;
                  }

                );
                const dataSource = this.dataSource;


              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting
                /* and other lengthes */
              }) => (

                < form onSubmit={handleSubmit}>
                  <Flex wrap w={1}>
                    <Flex column>
                      <Grid container direction="row">
                        <Grid item md={12}>
                          <FormGroup>
                            <label>PU. CAP</label>
                            <Input
                              type="number"
                              onChange={handleChange("PUCap")}
                              style={{
                                border: "1px solid #d2d6de",
                                padding: "9px 12px",
                                borderRadius: "5px"
                              }}
                              value={values.PUCap}
                            />
                          </FormGroup>
                          {/* <FormGroup>
                              <label>PU Ops Cap</label>
                              <Input
                                type="number"
                                onChange={handleChange("PUOpsCap")}
                                style={{
                                  border: "1px solid #d2d6de",
                                  padding: "9px 12px",
                                  borderRadius: "5px"
                                }}
                                value={values.PUOpsCap}
                              />
                            </FormGroup> */}
                          <FormGroup>
                            <label>PU Ops Cap (Freelance)</label>
                            <Input
                              type="number"
                              style={{
                                border: "1px solid #d2d6de",
                                padding: "9px 12px",
                                borderRadius: "5px"
                              }}
                              onChange={handleChange("PUOpsCapFreelance")}
                              value={values.PUOpsCapFreelance}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>PU Ops Cap (Driver)</label>
                            <Input
                              type="number"
                              style={{
                                border: "1px solid #d2d6de",
                                padding: "9px 12px",
                                borderRadius: "5px"
                              }}
                              onChange={handleChange("PUOpsCapDriver")}
                              value={values.PUOpsCapDriver}
                            />
                          </FormGroup>
                          <FormGroup>
                            <label>PU Target</label>
                            <Input
                              type="number"
                              style={{
                                border: "1px solid #d2d6de",
                                padding: "9px 12px",
                                borderRadius: "5px"
                              }}
                              onChange={handleChange("PUTarget")}
                              value={values.PUTarget}
                            />
                          </FormGroup>
                        </Grid>
                      </Grid>
                      <FormControl margin="dense">
                        <Button
                          type="submit"
                          className="p-button-info p-button-raised"
                          label="Submit"
                        />
                      </FormControl>
                    </Flex>

                  </Flex>
                </form>


              )}
            </Formik>
            : null}
        </Dialog>


        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={el => (this.growl = el)} style={{ marginTop: "75px" }} />



        <Flex className="card w-full">
          <Flex className="w-full" column>
            <CapacityPickupFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={el => (this.dt = el)}
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: "100%" }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={e => {
              this.onSorting(e);
            }}
            // onValueChange={() => this.loadData()}
            footer={footer}
          >
            <Column
              header="#"
              style={{ width: "50px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row) => {
                return (<a onClick={async () => {
                  const initModal = {
                    PUOpsCap: row.pu_ops_cap,
                    PUCap: row.pu_cap,
                    branchId: row.branch_id,
                    PUOpsCapFreelance: row.pu_ops_cap_freelance,
                    PUOpsCapDriver: row.pu_ops_cap_driver,
                    PUTarget: row.pu_target,
                    branchName: row.branch_name
                  };


                  await this.setState({
                    modal: initModal,
                    showCapacityDialog: true
                  });

                  setTimeout(function () {
                  }, 3000);

                }}>
                  #
                </a>);
              }}
            />
            <Column
              field="province_name"
              header="Province"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />
            <Column
              field="city_name"
              header="Kota"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />
            <Column
              field="branch_name"
              header="Cabang"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />
            <Column
              field="pu_cap"
              header="PU Cap."
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />

            <Column
              field="pu_ops_cap"
              header="PU OPS Cap."
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                const calculate = row.pu_cap * row.pickuppermanentcheckin +
                  (row.pickuppermanentcheckin / 2) + parseFloat(row.pickupprobationcheckin + row.pickupfreelancecheckin + row.pickupdriverpermanentcheckin);
                return (
                  <p>
                    {calculate}
                  </p>
                );
              }}
            />

            <Column
              field="total_awb"
              header="Jumlah Paket"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />

            <Column
              field="pu_target"
              header="Target"
              sortable={true}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
            />

            <Column
              field="capvspaket"
              header="Cap Vs Paket"
              sortable={false}
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              body={(row, column: ColumnProps) => {
                const calculate = row.pu_cap * row.pickuppermanentcheckin +
                  (row.pickuppermanentcheckin / 2) + parseFloat(row.pickupprobationcheckin + row.pickupfreelancecheckin + row.pickupdriverpermanentcheckin);
                return (
                  <p>
                    {this.getPerc(calculate, row.total_awb)}
                  </p>
                );
              }}
            />
            <Column
              field="pickuppermanent"
              header="Si Gesit Reguler Terdaftar"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickuppermanentcheckin"
              header="Si Gesit Reguler Masuk"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pikcupprobation"
              header="Si Gesit Reguler Probation Terdaftar"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickupprobationcheckin"
              header="Si Gesit Reguler Probation Masuk"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickupfreelance"
              header="Si Gesit Freelance Terdaftar"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickupfreelancecheckin"
              header="Si Gesit Freelance masuk"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickupdriverpermanent"
              header="Driver Terdaftar"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
            <Column
              field="pickupdriverpermanentcheckin"
              header="Driver Masuk"
              style={{ width: "130px", textAlign: "center", "vertical-align": "text-top" }}
              filter={false}
            // style={{ width: "4vw" }}
            />
          </DataTable>
          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={e => this.dataSource.fromPageState(e)}
          />
        </div>
      </div >
    );
  }
}
