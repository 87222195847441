import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import TimePicker from "../../component/time-picker/time-picker";

export interface IPickupListFilterContainerValue {
  startDate?: Date;
  partner: any;
  branch: any;
  spk: any;
  JamStart: any;
  JamEnd: any;
}

export class MonitoringPikcupFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IPickupListFilterContainerValue = {
    startDate: new Date(),
    partner: "",
    branch: "",
    spk: "",
    JamStart: "",
    JamEnd: ""
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionsLayanan = [
    {
      label: "Semua",
      value: ""
    },
    {
      label: "REG",
      value: "REG"
    },
    {
      label: "BEST",
      value: "BEST"
    },
    {
      label: "CARGO",
      value: "CARGO"
    },
    {
      label: "PACK",
      value: "PACK"
    },
    {
      label: "DOK",
      value: "DOK"
    },
    {
      label: "EKO",
      value: "EKO"
    }
  ];

  optionSPK = [
    {
      label: "Semua SPK",
      value: ""
    },
    {
      label: "SPK Malam",
      value: "1"
    }
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={e => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {formikProps => (
            <Flex wrap w={1}>
              <Box w={800}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-3">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange("startDate")}
                          value={formikProps.values.startDate}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Cabang</label>
                    <div className="col-md-7">
                      <BranchDropdown
                        id="branch"
                        value={formikProps.values.branch}
                        onChange={formikProps.handleChange("branch")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Partner</label>
                    <div className="col-md-7">
                      <PartnerDropdown
                        id="partner"
                        value={formikProps.values.partner}
                        onChange={formikProps.handleChange("partner")}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Spk</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionSPK}
                        onChange={formikProps.handleChange("spk")}
                        value={formikProps.values.spk}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-3">Jam Pop</label>
                    <div className="col-md-7">
                      <div className="row">
                        <div className="col-md-5">
                          <TimePicker
                            onChange={formikProps.handleChange("JamStart")}
                            value={formikProps.values.JamStart}
                          />
                        </div>
                        <div className="col-md-1">
                          {" S/D "}
                        </div>
                        <div className="col-md-5">
                          <TimePicker
                            onChange={formikProps.handleChange("JamEnd")}
                            value={formikProps.values.JamEnd}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
