import { Injectable } from '../utils/DependencyInjection';
import { HttpClientAxiosInterceptedService } from './HttpClientAxiosInterceptedService';

@Injectable()
export class MonitoringRestAuthService extends HttpClientAxiosInterceptedService {

  constructor() {
    super(`${process.env.REACT_APP_AUTH_BACKEND_URL!}`);
  }
}
