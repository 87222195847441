import { observer } from "mobx-react";
import React, { RefObject } from "react";

import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DateHelper } from "../../utils/DateHelper";
import { Fieldset } from "primereact/fieldset";
import { TimeWindowSummaryGeneralContainer } from "./TimeWindowSummaryGeneralContainer";
import { TimeWindowSummaryDetailContainer } from "./TimeWindowSummaryDetailContainer";
import { TimeWindowSummaryPickupPlanContainer } from "./TimeWindowSummaryPickupPlanContainer";
import { merge, Subscription } from "rxjs";
import { observable } from "mobx";
import Loader from "react-loader-spinner";
import { Link } from "react-router-dom";
import qs from "qs";
import { tap } from "rxjs/operators";
import { AxiosRequestConfig } from "axios";

@observer
export class TimeWindowSummaryContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;

  loadSubs: Subscription | null;
  @observable data;
  @observable unpickData;
  filterParams;

  loaded: boolean = false;

  refAllSummary: RefObject<TimeWindowSummaryGeneralContainer> = React.createRef();
  // refAllSummaryDetail: RefObject<
  //   TimeWindowSummaryDetailContainer
  // > = React.createRef();
  // refAllPickup: RefObject<TimeWindowSummaryPickupPlanContainer> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      showingPickupPlan: true,
    };
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData(this.filterParams);
    // });
  }

  public async loadData(filterParams: any) {
    if (this.loadSubs) {
      this.loadSubs.unsubscribe();
      this.loadSubs = null;
    }

    this.data = null;
    this.unpickData = null;

    this.filterParams = filterParams;
    const fParams = {
      filters: filterParams,
    };
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    const obs1 = this.monitoringRestService
      .post("time-window-new/summary/general", fParams, axiosConfig)
      .pipe(
        tap((response) => {
          this.data = response;
        })
      );

    const obs2 = this.monitoringRestService
      .post("time-window-new/summary/unpick", fParams, axiosConfig)
      .pipe(
        tap((response) => {
          this.unpickData = response;
        })
      );

    const allObs = merge(obs1, obs2);

    this.setState({ loadingData: true });
    this.loadSubs = allObs.subscribe(
      (responses) => {
        // this.data = response;
      },
      () => {
        this.setState({ loadingData: false });
      },
      () => {
        this.setState({ loadingData: false });
      }
    );
  }

  public reset() {
    console.log("reset");
  }

  public renderFieldset(title, index, body) {
    const fieldsetStateName = `fieldsetCollapsed${index}`;
    return (
      <div className="mt-10">
        <Fieldset
          legend={title}
          toggleable={true}
          collapsed={this.state[fieldsetStateName]}
          onToggle={(e) => {
            let obj: any = {};
            obj[fieldsetStateName] = e.value;
            this.setState(obj);
          }}
        >
          {body}
        </Fieldset>
      </div>
    );
  }

  public renderEmptyFieldset(title, index) {
    const body = (
      <div>
        <p>Tidak ada data</p>
      </div>
    );

    this.renderFieldset(title, index, body);
  }

  public renderLoadingFieldset(title, index) {
    const body = (
      <div>
        <Loader type="ThreeDots" color="#c8c8c8" height="50" width="50" />
      </div>
    );

    this.renderFieldset(title, index, body);
  }

  public renderContentRow(
    title,
    value,
    customPeriod,
    filterValue,
    filterTitle: string | null = null
  ) {
    const strVal = (value ? value : 0).toLocaleString();
    const fTitle = filterTitle ? filterTitle : title;

    return (
      <div>
        <span className="font-bold">{title}: </span>
        <Link
          to={{
            pathname: "/admin/time-window-new/detail/awb",
            search: qs.stringify(
              Object.assign({}, this.filterParams, {
                status: filterValue,
                title: filterTitle,
                customPeriod: customPeriod,
              })
            ),
          }}
          target="_blank"
        >
          {strVal}
        </Link>
      </div>
    );
  }

  public renderUnpickContentRow(
    title,
    value,
    reasonId,
    filterValue,
    filterTitle: string | null = null
  ) {
    const strVal = (value ? value : 0).toLocaleString();
    const fTitle = filterTitle ? filterTitle : title;

    return (
      <div>
        <span className="font-bold">{title}: </span>
        <Link
          to={{
            pathname: "/admin/time-window-new/detail/awb",
            search: qs.stringify(
              Object.assign({}, this.filterParams, {
                status: filterValue,
                title: filterTitle,
                unpick_reason_id: reasonId,
              })
            ),
          }}
          target="_blank"
        >
          {strVal}
        </Link>
      </div>
    );
  }

  public renderRpuToday() {
    const fieldsetTitle = "Request Pickup Hari ini";
    const fieldsetIndex = 1;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (!this.data) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.renderContentRow(
          "SPK",
          this.data.rpu_pickup_today,
          "rpu_pickup_today",
          "rpu_pickup_today",
          "Request Pickup Hari ini"
        )}
        {this.renderContentRow(
          "Pick (Plan Drop + Pickup)",
          this.data.rpu_pickup_today_picked_all,
          "rpu_pickup_today",
          "pick_all",
          "Request Pickup Hari ini - Pick (Plan Drop + Pickup)"
        )}
        {this.renderContentRow(
          "Pick",
          this.data.rpu_pickup_today_picked,
          "rpu_pickup_today",
          "pick",
          "Request Pickup Hari ini - Pick"
        )}
        {this.renderContentRow(
          "Unpick",
          this.data.rpu_pickup_today_unpicked,
          "rpu_pickup_today",
          "unpick",
          "Request Pickup Hari ini - Unpick"
        )}
        {this.renderContentRow(
          "Unpick Reschedule",
          this.data.rpu_pickup_today_unpicked_reschedule,
          "rpu_pickup_today",
          "unpick_reschedule",
          "Request Pickup Hari ini - Unpick Reschedule"
        )}
        {this.renderContentRow(
          "Unpick Not Reschedule",
          this.data.rpu_pickup_today_unpicked_not_reschedule,
          "rpu_pickup_today",
          "unpick_not_reschedule",
          "Request Pickup Hari ini - Unpick Not Reschedule"
        )}
        {this.renderContentRow(
          "Belum POP",
          this.data.rpu_pickup_today_not_pop,
          "rpu_pickup_today",
          "not_pop",
          "Request Pickup Hari ini - Belum POP"
        )}
        {this.renderContentRow(
          "Belum Assign Sigesit",
          this.data.rpu_pickup_today_not_assigned,
          "rpu_pickup_today",
          "not_assign",
          "Request Pickup Hari ini - Belum Assign Sigesit"
        )}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderSpkYesterday() {
    const fieldsetTitle = "SPK Kemarin";
    const fieldsetIndex = 2;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (!this.data) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.renderContentRow(
          "SPK",
          this.data.spk_yesterday,
          "spk_yesterday",
          "spk_yesterday",
          "SPK Kemarin"
        )}
        {this.renderContentRow(
          "Pick (Plan Drop + Pickup)",
          this.data.spk_yesterday_picked_all,
          "spk_yesterday",
          "pick_all",
          "SPK Kemarin - Pick (Plan Drop + Pickup)"
        )}
        {this.renderContentRow(
          "Pick",
          this.data.spk_yesterday_picked,
          "spk_yesterday",
          "pick",
          "SPK Kemarin - Pick"
        )}
        {this.renderContentRow(
          "Unpick",
          this.data.spk_yesterday_unpicked,
          "spk_yesterday",
          "unpick",
          "SPK Kemarin - Unpick"
        )}
        {this.renderContentRow(
          "Unpick Reschedule",
          this.data.spk_yesterday_unpicked_reschedule,
          "spk_yesterday",
          "unpick_reschedule",
          "SPK Kemarin - Unpick Reschedule"
        )}
        {this.renderContentRow(
          "Unpick Not Reschedule",
          this.data.spk_yesterday_unpicked_not_reschedule,
          "spk_yesterday",
          "unpick_not_reschedule",
          "SPK Kemarin - Unpick Not Reschedule"
        )}
        {this.renderContentRow(
          "Belum POP",
          this.data.spk_yesterday_not_pop,
          "spk_yesterday",
          "not_pop",
          "SPK Kemarin - Belum POP"
        )}
        {this.renderContentRow(
          "Belum Assign Sigesit",
          this.data.spk_yesterday_not_assigned,
          "spk_yesterday",
          "not_assign",
          "SPK Kemarin - Belum Assign Sigesit"
        )}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderSpk() {
    const fieldsetTitle = "Detail SPK";
    const fieldsetIndex = 3;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (!this.data) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.renderContentRow(
          "SPK Kemarin",
          this.data.spk_yesterday,
          null,
          "spk_yesterday",
          "SPK Kemarin"
        )}
        {this.renderContentRow(
          "SPK Hari Ini",
          this.data.spk_today,
          null,
          "spk_today",
          "SPK Hari Ini"
        )}
        {this.renderContentRow("Total SPK", this.data.spk, null, "spk", "Total SPK")}
        {this.renderContentRow(
          "Assign Sigesit",
          this.data.assigned,
          null,
          "assign",
          "Assign Sigesit"
        )}
        {this.renderContentRow(
          "Belum Assign Sigesit",
          this.data.not_assigned,
          null,
          "not_assign",
          "Detail SPK - Belum Assign Sigesit"
        )}
        {this.renderContentRow("Pick (Plan Pickup + DROP)", this.data.picked_all, null, "pick_all", "Pick (Plan Pickup + DROP)")}
        {this.renderContentRow("Pick", this.data.picked, null, "pick", "Pick")}
        {this.renderContentRow(
          "Unpick",
          this.data.unpicked,
          null,
          "unpick",
          "Unpick"
        )}
        {this.renderContentRow(
          "Unpick Reschedule",
          this.data.unpicked_reschedule,
          null,
          "unpick_reschedule",
          "Unpick Reschedule"
        )}
        {this.renderContentRow(
          "Unpick Not Reschedule",
          this.data.unpicked_not_reschedule,
          null,
          "unpick_not_reschedule",
          "Unpick Not Reschedule"
        )}
        {this.renderContentRow(
          "Auto POP",
          this.data.auto_pop,
          null,
          "auto_pop",
          "Auto POP"
        )}
        {this.renderContentRow(
          "Manifest",
          this.data.manifested,
          null,
          "manifest",
          "Manifest"
        )}
        {this.renderContentRow(
          "Pick/Drop Belum Manifest",
          this.data.pick_not_manifested,
          null,
          "pick_not_manifested",
          "Pick/Drop Belum Manifest"
        )}
        {this.renderContentRow("Drop", this.data.dropped, "drop", "Drop")}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderPlanMethod() {
    const fieldsetTitle = "Request Pickup Plan";
    const fieldsetIndex = 4;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (!this.data) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.renderContentRow(
          "Pickup",
          this.data.rpu_pickup,
          null,
          "rpu_pickup",
          "Request Pickup Plan - Pickup"
        )}
        {this.renderContentRow(
          "Drop",
          this.data.spk_today,
          null,
          "rpu_drop",
          "Request Pickup Plan - Drop"
        )}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderActualPlanMethod() {
    const fieldsetTitle = "Actual Plan";
    const fieldsetIndex = 4;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (!this.data) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.renderContentRow("Pickup", this.data.picked, null, "pick_all", "Pick (Plan Pickup + Drop)")}
        {this.renderContentRow("Drop", this.data.dropped, null, "drop", "Drop")}
        {this.renderContentRow(
          "Auto POP",
          this.data.auto_pop,
          null,
          "auto_pop",
          "Auto POP"
        )}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderUnpickReschedule() {
    const fieldsetTitle = "Unpick Reschedule";
    const fieldsetIndex = 5;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (
      !this.unpickData ||
      !this.unpickData.reschedules ||
      this.unpickData.reschedules.length <= 0
    ) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.unpickData.reschedules.map((d, index) => (
          <div key={index}>
            {this.renderUnpickContentRow(
              d.reason_name,
              d.count,
              d.reason_id,
              "unpick",
              `Unpick | ${d.reason_name}`
            )}
          </div>
        ))}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  public renderUnpickNotReschedule() {
    const fieldsetTitle = "Unpick Not Reschedule";
    const fieldsetIndex = 6;

    if (this.state.loadingData) {
      return this.renderLoadingFieldset(fieldsetTitle, fieldsetIndex);
    }

    if (
      !this.unpickData ||
      !this.unpickData.unreschedules ||
      this.unpickData.unreschedules.length <= 0
    ) {
      return this.renderEmptyFieldset(fieldsetTitle, fieldsetIndex);
    }

    const body = (
      <div>
        {this.unpickData.unreschedules.map((d, index) => (
          <div key={index}>
            {this.renderUnpickContentRow(
              d.reason_name,
              d.count,
              d.reason_id,
              "unpick",
              `Unpick | ${d.reason_name}`
            )}
          </div>
        ))}
      </div>
    );

    return this.renderFieldset(fieldsetTitle, fieldsetIndex, body);
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-4">
            {this.renderRpuToday()}
            {this.renderSpkYesterday()}
            {this.renderSpk()}
          </div>

          <div className="col-md-4">
            {this.renderPlanMethod()}
            {this.renderActualPlanMethod()}
            {this.renderUnpickReschedule()}
            {this.renderUnpickNotReschedule()}
          </div>

          {/* <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Detail"
                  toggleable={true}
                  collapsed={this.state.lastTrackingSiteCollapsed}
                  onToggle={(e) =>
                    this.setState({ lastTrackingSiteCollapsed: e.value })
                  }
                >
                  <TimeWindowSummaryDetailContainer
                    ref={this.refAllSummaryDetail}
                  />
                </Fieldset>
              </div>
            </div>
          </div> */}

          {/* <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Pickup Plan"
                  toggleable={true}
                  collapsed={this.state.lastTrackingSiteCollapsed}
                  onToggle={(e) =>
                    this.setState({ lastTrackingSiteCollapsed: e.value })
                  }
                >
                  <TimeWindowSummaryPickupPlanContainer ref={this.refAllPickup} />
                </Fieldset>
              </div>
            </div>
          </div> */}

          {/* {this.state.showingPickupPlan ? (
            <div className="col-md-3">
              <div className="row">
                <div className="col-md-12">
                  <Fieldset
                    legend="Pickup Plan"
                    toggleable={true}
                    collapsed={this.state.lastTrackingSiteCollapsed}
                    onToggle={(e) =>
                      this.setState({ lastTrackingSiteCollapsed: e.value })
                    }
                  >
                    <TimeWindowAllPickupPlanContainer ref={this.refAllPickup} />
                  </Fieldset>
                </div>
              </div>
            </div>
          ) : null} */}
        </div>
      </div>
    );
  }
}
