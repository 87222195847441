import { RouterProps } from "react-router";
import React, { RefObject } from "react";
import { Paginator } from "primereact/paginator";
import { Column, ColumnProps } from "primereact/column";
import moment from "moment";

import qs from "querystring";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { DateHelper } from "../../utils/DateHelper";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { DataSource } from "../../models/DataSource";
import QueryString from "qs";
import { observer } from "mobx-react";
import { Button } from "primereact/button";

@observer
export class TimeWindowDetailAwb extends React.Component<RouterProps, any> {
    dataTableFilterService = DependencyContainer.get(DataTableFilterService);
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    data;
    dt;
    growl;
    dataSource = new DataSource<any>();
    refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
    filterParams: any;

    constructor(props) {
        super(props);
        this.state = {
            exportExcelLoading: false,
            showExportDialog: false,
            filterCollapsed: false,
            sortField: null,
            sortOrder: null,
            total: 0,
        };
    }

    componentDidMount() {
        this.filterParams = QueryString.parse(location.search.replace("?", ""));
        console.log(this.filterParams, "filter params");
        if (this.filterParams.name) {
            let statusName = "";
            let title = "";
            switch (this.filterParams.name.toLowerCase()) {
                //summary
                case "summary jumlah resi kemarin": {
                    statusName = "spk_yes";
                    title = "Jumlah Resi Kemarin";
                    break;
                }
                case "summary jumlah spk hari ini": {
                    statusName = "spk_today";
                    title = "Jumlah SPK Hari Ini";
                    break;
                }
                case "summary total spk hari ini": {
                    statusName = "total_awb";
                    title = "Total Resi Hari Ini";
                    break;
                }
                case "summary pick": {
                    statusName = "picked";
                    title = "Pick";
                    break;
                }
                case "summary unpick": {
                    statusName = "unpick";
                    title = "Unpick";
                    break;
                }

                //detail
                case "detail jumlah resi kemarin": {
                    statusName = "spk_yes";
                    title = "Jumlah Resi Kemarin";
                    break;
                }
                case "detail jumlah spk hari ini": {
                    statusName = "spk_today";
                    title = "Jumlah SPK Hari Ini";
                    break;
                }
                case "detail total spk hari ini": {
                    statusName = "total_awb";
                    title = "Total Resi Hari Ini";
                    break;
                }
                case "detail pick": {
                    statusName = "picked";
                    title = "Pick";
                    break;
                }
                case "detail unpick": {
                    statusName = "unpick";
                    title = "Unpick";
                    break;
                }
                case "detail drop": {
                    statusName = "drop";
                    title = "Drop";
                    break;
                }
                case "detail spk assign": {
                    statusName = "assign";
                    title = "Assign";
                    break;
                }
                case "detail spk not assign": {
                    statusName = "not_assign";
                    title = "Not Assign";
                    break;
                }
                case "detail unpick reschedule": {
                    statusName = "unpicked_reschedule";
                    title = "Unpick Reschedule";
                    break;
                }

                case "detail unpick not reschedule": {
                    statusName = "unpicked_not_reschedule";
                    title = "Unpick Not Reschedule";
                    break;
                }

                //pickup
                case "pickup pick": {
                    statusName = "rpu";
                    title = "Pick Plan";
                    break;
                }

                case "pickup drop": {
                    statusName = "rpu_drop";
                    title = "Drop Plan";
                    break;
                }

                default: {
                    statusName = this.filterParams.name;
                    break;
                }
            }

            console.log(this.filterParams.name, statusName);
            this.filterParams.status = statusName;
            this.filterParams.title = title;
        }
        this.dataSource.onPageChange.subscribe(() => {
            this.loadData(this.filterParams);
        });

        this.dataSource.setPage(1);
    }
    loadData(filterParams: any) {
        console.log(filterParams, "filterParams")
        const pagingParams = {
            limit: this.dataSource.rows,
            page: this.dataSource.page
        };
        const sortingParams = {
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
        };

        const fParams = Object.assign(
            {},
            filterParams,
            pagingParams,
            sortingParams
        );

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        if (fParams.branch) {
            fParams.branchId = fParams.branch.value
        }

        if (fParams.partner)
            fParams.partnerId = fParams.partner.value


        fParams.filterParams = filterParams;

        this.filterParams = filterParams;


        // fParams.fParameters = JSON.stringify(filterParams);

        console.log(fParams, "fparams");
        this.dataSource.loading = true;
        this.monitoringRestService.post(`/TimeWindow-pg/list/detail/resi`, fParams).subscribe(
            response => {
                console.log(response, "response");
                this.data = response;
                this.setState({ data: response });
                this.populateDataSource();
            },
            err => {
                this.dataSource.loading = false;
            },
            () => {
                this.dataSource.loading = false;
            }
        );
    }

    downloadCsv(filterParams: any) {
        console.log("xls")
        this.filterParams = filterParams;

        const pagingParams = {
            limit: this.dataSource.rows,
            page: this.dataSource.page
        };
        const sortingParams = {
            sortField: this.state.sortField,
            sortOrder: this.state.sortOrder === -1 ? "desc" : "asc"
        };

        const fParams = Object.assign(
            {},
            filterParams,
            pagingParams,
            sortingParams
        );

        if (fParams.startDate)
            fParams.startDate = DateHelper.formatLocalDateForQueryParam(
                fParams.startDate
            );
        if (fParams.endDate)
            fParams.endDate = DateHelper.formatLocalDateForQueryParam(
                fParams.endDate
            );

        if (fParams.branch) {
            fParams.branchId = fParams.branch.value
        }

        if (fParams.partner)
            fParams.partnerId = fParams.partner.value


        fParams.filterParams = filterParams;


        const resPath =
            this.filterParams.slaType! && this.filterParams.slaType === "external"
                ? "/TimeWindow-pg/list/detail/resi"
                : "/TimeWindow-pg/list/detail/resi";
        console.log(resPath, "res path")
        filterParams.format = "xls";

        this.filterParams = filterParams;
        this.monitoringRestService.post(resPath, this.filterParams).subscribe(
            response => {
                // if (response && response.path) {
                this.openCsv(response.url);
                // }
            },
            () => {
                let msg = {
                    severity: "error",
                    summary: "Error Message",
                    detail: "Export failed"
                };
                this.growl.show(msg);
            },
            () => {
                let msg = {
                    severity: "success",
                    summary: "Success Message",
                    detail: "Export Success"
                };
                this.growl.show(msg);
            }
        );
    }

    openCsv(url) {
        const win = window.open(url, "_blank");
        if (win) win.focus();
    }

    awbNumberFilter = (filters) => {
        console.log(filters, "filters")
        if (filters.filters.ref_awb_number) {
            this.filterParams.ref_awb_number = filters.filters.ref_awb_number.value;
        } else {
            this.filterParams.ref_awb_number = "";
        }

        this.loadData(this.filterParams);
        setTimeout(() => {
            this.dataSource.setPage(0);
        }, 1000)
    };


    populateDataSource() {
        if (this.data.data && this.data.data.length) {
            this.dataSource.setData(this.data.data);
            this.dataSource.setTotal(this.data.count);
        } else {
            this.dataSource.setData([]);
            this.dataSource.setTotal(0);
        }
    }

    onClickAwbNumber = awbNumber => {
        this.refAwbDetailModal.current!.handleShow(awbNumber);
    };

    columnAwbNumber = (rowData, column: ColumnProps) => {
        return (
            <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
                {rowData[column.field!]}
            </a>
        );
    };

    columnDateFormat = (rowData, column: ColumnProps) => {
        return rowData[column.field!] != null
            ? moment(new Date(rowData[column.field!]))
                .format("DD-MM-YYYY HH:mm")
            : "";
    };

    async onSorting(e) {
        if (e && e.sortField) {
            const sortOrder =
                this.state.sortField === e.sortField
                    ? this.state.sortOrder === 1
                        ? -1
                        : 1
                    : 1;
            await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
        } else {
            await this.setState({ sortField: null, sortOrder: null });
        }

        console.log(e);
        console.log(this.state);
        this.loadData(this.filterParams);
    }

    render() {
        const footer = "Total data: " + this.dataSource.total;
        console.log(this.dataSource.total, this.dataSource.data, "data source")

        return (
            <div>
                <AwbDetailModalComponent ref={this.refAwbDetailModal} />
                <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />

                <div className="card">
                    <div className="row">
                        {this.filterParams ? (
                            <h3 style={{ float: "left", marginLeft: "10px" }}>
                                Status - {this.filterParams.title}
                            </h3>
                        ) : (
                                <h3></h3>
                            )}

                        <Button
                            type="button"
                            className="p-button-info p-button-raised"
                            style={{ float: "right", marginRight: "10px" }}
                            label="Export To Csv"
                            onClick={() => this.downloadCsv(this.filterParams)}
                        />
                    </div>
                    <DataTable
                        emptyMessage={"Tidak ada data untuk ditampilkan."}
                        ref={(el) => (this.dt = el)}
                        loading={this.dataSource.loading}
                        scrollable={true}
                        scrollHeight="550px"
                        style={{ width: "100%" }}
                        className="mt-4"
                        value={this.dataSource.data}
                        onSort={(e) => {
                            this.onSorting(e);
                        }}
                        onFilter={this.awbNumberFilter}
                        footer={footer}
                    >
                        <Column
                            field="ref_awb_number"
                            filter
                            filterMatchMode="custom"
                            sortable={true}
                            header="No Resi"
                            body={this.columnAwbNumber}
                            style={{ width: "150px", "vertical-align": "text-top" }}
                        />
                        <Column
                            field="partner_name"
                            header="Partner"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="pickup_request_name"
                            header="Merchant"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="pickup_schedule_date_time"
                            header="Tgl Pickup"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={this.columnDateFormat}
                        />
                        <Column
                            field="time_window"
                            header="Time Window"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="pickup_request_type"
                            header="Plan Pickup Method"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />

                        <Column
                            field="pickup_request_address"
                            header="Alamat"
                            sortable={true}
                            style={{
                                width: "260px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="status_name_pick"
                            header="Status POP"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="pickup_date_time"
                            header="TGL POP"
                            sortable={true}
                            filter={false}
                            body={this.columnDateFormat}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />

                        <Column
                            field="status_name"
                            header="Status Saat Ini"
                            filter={false}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="updated_time"
                            header="Tgl Status Saat ini"
                            filter={false}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={this.columnDateFormat}
                        />
                        <Column
                            field="is_manifested"
                            header="Manifested"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            filter={false}
                        />
                        <Column
                            field="manifested_date"
                            header="TGL Manifest"
                            filter={false}
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                            body={this.columnDateFormat}
                        />
                        <Column
                            field="gabung_paket"
                            header="Gabung Paket"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                        <Column
                            field="out_first_mile"
                            header="Out First Mile"
                            sortable={true}
                            style={{
                                width: "130px",
                                textAlign: "center",
                                "vertical-align": "text-top",
                            }}
                        />
                    </DataTable>
                    <Paginator
                        pageLinkSize={10}
                        totalRecords={this.dataSource.total}
                        first={this.dataSource.offset}
                        rows={this.dataSource.rows}
                        rowsPerPageOptions={this.dataSource.rowsOptions}
                        onPageChange={(e) => this.dataSource.fromPageState(e)}
                    />
                </div>
            </div>
        );
    }
}
