import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Box, Flex } from "reflexbox";
import { Panel } from "primereact/panel";
import BranchDropdown from "../../component/select/branchDropDown";
import PartnerDropdown from "../../component/select/partnerDropDown";
import ProvinceDropdown from "../../component/select/provinceDropDown";
import CityDropDown from "../../component/select/cityDropDown";

export interface ICapacityPickupFilterContainerValue {
  startDate?: Date;
  partner: any;
  branch: any;
  tipeGerai: any;
  city: any;
  province: any;

}

export class CapacityPickupFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: ICapacityPickupFilterContainerValue = {
    startDate: new Date(),
    partner: "",
    branch: "",
    tipeGerai: "",
    city: "",
    province: "",
  };

  refFormik: RefObject<Formik> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }


  setFormValues(values) {
    const currentFormValues = this.refFormik.current
      ? this.refFormik.current.state.values
      : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  optionTipeGerai = [
    { label: "semua", value: "", selected: true },
    { label: "Branch", value: "branch" },
    { label: "Shop", value: "shop" },
    { label: "Transit", value: "transit" },

  ]

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={e => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {formikProps => (
            <Flex wrap w={1}>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-3">Periode</label>
                  <div className="col-md-7">
                    <Flex column>
                      <Calendar
                        name="startDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        maxDate={formikProps.values.endDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange("startDate")}
                        value={formikProps.values.startDate}
                      />
                    </Flex>
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-3">Kota</label>
                  <div className="col-md-7">
                    <CityDropDown
                      id="branch"
                      value={formikProps.values.city}
                      onChange={formikProps.handleChange("city")}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-3">Province</label>
                  <div className="col-md-7">
                    <ProvinceDropdown
                      id="branch"
                      value={formikProps.values.province}
                      onChange={formikProps.handleChange("province")}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-3">Cabang</label>
                  <div className="col-md-7">
                    <BranchDropdown
                      id="branch"
                      value={formikProps.values.branch}
                      onChange={formikProps.handleChange("branch")}
                    />
                  </div>
                </div>

                <div className="form-group">
                  <label className="col-md-3">Tipe Gerai</label>
                  <div className="col-md-7">
                    <Dropdown
                      options={this.optionTipeGerai}
                      onChange={formikProps.handleChange('tipeGerai')}
                      value={formikProps.values.tipeGerai}
                    />
                  </div>
                </div>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
