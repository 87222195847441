import { observer } from "mobx-react";
import moment from "moment";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { RefObject } from "react";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { observable } from "mobx";
import { AxiosRequestConfig } from "axios";
@observer
export class TimeWindowBranchSummaryContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;
  data;
  growl;
  dataSource = new DataSource<any>();
  loaded: boolean = false;

  filterParams: any;

  refDataTable;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
    };

    this.exportCsv = this.exportCsv.bind(this);
  }

  componentDidMount() {
    // this.dataSource.onPageChange.subscribe(() => {
    //   this.loadData(this.refFilter);
    // });
    // this.dataSource.setPage(1);
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    const fParams = {
      filters: filterParams,
    };
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.dataSource.loading = true;
    this.monitoringRestService
      .post("/time-window-new/branch-summary", fParams, axiosConfig)
      .subscribe(
        (response) => {
          this.data = response;
          this.dataSource.setData(this.data);
          this.dataSource.setTotal(this.data.length);
          this.loaded = true;
        },
        () => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  public reset() {
    try {
      this.data = null;
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    } finally {
      this.loaded = false;
    }
  }

  async exportCsv() {
    this.setState({ exportExcelLoading: true });
    try {
      await this.refDataTable.exportCSV();
    } catch (error) {
      console.log(error);
      throw error;
    } finally {
      this.setState({ exportExcelLoading: false });
    }
  }

  gotoBranchDetail = (row, column, params, rowVal = null) => {
    const mParams = Object.assign(
      {},
      !!this.filterParams ? this.filterParams : {},
      !!params ? params : {}
    );

    mParams.branchName = row.branch_name;
    mParams.branchId = row.branch_id;

    const qParams = qs.stringify(mParams);

    const url = `/admin/time-window-new/branch-detail?${qParams}`;

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {rowVal ? rowVal : row[column.field!]}
      </a>
    );
  };

  gotoDriverDetail = (row, column) => {
    const mParams = Object.assign(
      {},
      !!this.filterParams ? this.filterParams : {}
    );

    mParams.branchId = row["branch_id"];
    mParams.branchName = row["branch_name"];
    const timeWindowStartDate = row["time_window_start_date"];
    if (timeWindowStartDate && moment(timeWindowStartDate).isValid()) {
      mParams.timeWindowStartDate = moment(timeWindowStartDate).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    const qParams = qs.stringify(mParams);

    const url = `/admin/time-window-new/driver-detail/?${qParams}`;

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  gotoAwbDetail = (row, column, params, rowVal = null) => {
    const mParams = Object.assign(
      {},
      !!this.filterParams ? this.filterParams : {},
      !!params ? params : {}
    );
    mParams.branchName = row.branch_name;
    mParams.branchId = row.branch_id;
    const qParams = qs.stringify(mParams);
    const url = `/admin/time-window-new/detail/awb`;
    const fullUrl = `${url}?${qParams}`;

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(fullUrl, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {rowVal ? rowVal : row[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return !!rowData[column.field!]
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  getPerc = (obj) => {
    // return !!obj ? obj.toFixed(2) : "0";
    return obj ? obj.toFixed(2) : 0;
  };

  render() {
    var header = (
      <div style={{ textAlign: "left" }}>
        <Button
          type="button"
          icon="pi pi-external-link"
          iconPos="left"
          label="CSV"
          onClick={this.exportCsv}
        ></Button>
      </div>
    );

    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        {/* <div className="row">
          {this.filterParams ? (
            <h3 style={{ float: "left", marginLeft: "10px" }}>
              Status - {this.filterParams.branch.branch_name}
            </h3>
          ) : (
            <h3></h3>
          )}
        </div> */}

        <DataTable
          ref={(el) => (this.refDataTable = el)}
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          style={{ width: "100%" }}
          className="mt-4"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          header={header}
          footer={footer}
        >
          <Column
            field="branch_name"
            header="Cabang"
            filter={true}
            filterMatchMode="contains"
            sortable={true}
            style={{ width: "150px", "vertical-align": "text-top" }}
            body={(row, column: ColumnProps) => {
              return this.gotoBranchDetail(row, column, null, null);
            }}
          />
          <Column
            field="branch_code"
            header="Kode Analytics Gerai"
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="sigesit"
            header="Si Gesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoDriverDetail(row, column);
            }}
          />

          <Column
            field="pickup_point"
            header="Titik Pickup"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="rpu"
            header="Plan Pickup + Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "rpu",
                title: "RPU (Pickup + Drop)",
              });
            }}
          />

          <Column
            field="spk_yesterday"
            header="SPK Kemarin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk_yesterday",
                title: "SPK Kemarin",
              });
            }}
          />

          <Column
            field="spk_today"
            header="SPK Hari ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk_today",
                title: "SPK Hari Ini",
              });
            }}
          />

          <Column
            field="spk"
            header="Total SPK"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "spk",
                title: "Total SPK",
              });
            }}
          />
          <Column
            field="assigned"
            header="SPK Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "assign",
                title: "SPK Assign Sigesit",
              });
            }}
          />

          <Column
            field="not_assigned"
            header="SPK Belum Assign Sigesit"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "not_assign",
                title: "SPK Belum Assign Sigesit",
              });
            }}
          />

          <Column
            field="picked_all"
            header="Pick (Plan Pickup + Drop)"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick_all",
                title: "Pick (Plan Pickup + Drop)",
              });
            }}
          />
          <Column
            field="picked"
            header="Pick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick",
                title: "Pick",
              });
            }}
          />
          <Column
            field="pick_percentage"
            header="% Pick"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="unpicked"
            header="Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "unpick",
                title: "Unpick",
              });
            }}
          />
          <Column
            field="unpick_percentage"
            header="% Unpick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="not_pop"
            header="Belum POP"
            sortable={true}
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "not_pop",
                title: "Belum POP",
              });
            }}
          />
          <Column
            field="not_pop_percentage"
            header="% Belum POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="auto_pop"
            header="Auto POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "auto_pop",
                title: "Auto POP",
              });
            }}
          />
          <Column
            field="auto_pop_percentage"
            header="% Auto POP"
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="pop_on_time"
            header="POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pop_on_time",
                title: "POP On Time",
              });
            }}
          />
          <Column
            field="pop_on_time_percentage"
            header="% POP On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="pop_late_time"
            header="POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pop_late_time",
                title: "POP Tidak On Time",
              });
            }}
          />

          <Column
            field="pop_late_time_percentage"
            header="% POP Tidak On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="dropped"
            header="Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "drop",
                title: "Drop",
              });
            }}
          />
          <Column
            field="drop_percentage"
            header="% Drop"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={true}
            // style={{ width: "4vw" }}
            body={(row, column) => {
              return <p>{this.getPerc(row[column.field!])}</p>;
            }}
          />

          <Column
            field="manifested"
            header="Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "manifest",
                title: "Manifest",
              });
            }}
          />

          <Column
            field="pick_not_manifested"
            header="Pick/Drop Belum Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "pick_not_manifested",
                title: "Pick/Drop Belum Manifest",
              });
            }}
          />

          <Column
            field="oversla"
            header="Oversla 1x24"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return this.gotoAwbDetail(row, column, {
                status: "oversla",
                title: "Oversla 1x24",
              });
            }}
          />
        </DataTable>
        {/* <Paginator
          pageLinkSize={10}
          totalRecords={this.dataSource.total}
          first={this.dataSource.offset}
          rows={this.dataSource.rows}
          rowsPerPageOptions={this.dataSource.rowsOptions}
          onPageChange={(e) => this.dataSource.fromPageState(e)}
        /> */}
      </div>
    );
  }
}
