import { observer } from "mobx-react";
import moment from "moment";
import { Column, ColumnProps } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";

import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { DateHelper } from "../../utils/DateHelper";
import { Growl } from "primereact/growl";
import { TimeWindowFilterContainer } from "./TimeWindowFilterContainer";

@observer
export class TimeWindowDetailContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl;
  dataSource = new DataSource<any>();

  refFilter: any;
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  filterParams;

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    };
  }

  componentDidMount() {
    // const queryFilter = this.props.history.location.search
    //     ? qs.parse(this.props.history.location.search.substr(1))
    //     : {};

    // if (this.refFilter.current) {
    //     const newFilters = this.refFilter.current.setFormValues(
    //         queryFilter.filters
    //     );
    //     this.loadData(newFilters);
    // }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData(this.refFilter);
    });
    this.dataSource.setPage(1);
  }

  loadData(filterParams: any) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign({}, filterParams, pagingParams, sortingParams);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }

    if (fParams.branch) fParams.branchId = fParams.branch.value;
    if (fParams.province) fParams.provinceId = fParams.province.value;
    if (fParams.city) fParams.cityId = fParams.city.value;

    if (fParams.partner) fParams.partnerId = fParams.partner.value;

    fParams.format = "list";

    this.refFilter = fParams;
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("/TimeWindow-pg/list/summary", fParams)
      .subscribe(
        (response) => {
          this.data = response;
          this.populateDataSource();
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  downloadCsv(filterParams: any) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign({}, filterParams, pagingParams, sortingParams);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }

    if (fParams.branch) fParams.branchId = fParams.branch.value;
    if (fParams.province) fParams.provinceId = fParams.province.value;
    if (fParams.city) fParams.cityId = fParams.city.value;

    if (fParams.partner) fParams.partnerId = fParams.partner.value;


    this.monitoringRestService.post("/TimeWindow-pg/list/summary", this.filterParams).subscribe(
      (response) => {
        if (response && response.path) {
          this.openCsv(response.path);
        }
      },
      () => {
        let msg = {
          severity: "error",
          summary: "Error Message",
          detail: "Export failed",
        };
        this.growl.show(msg);
      },
      () => {
        let msg = {
          severity: "success",
          summary: "Success Message",
          detail: "Export Success",
        };
        this.growl.show(msg);
      }
    );
  }

  openCsv(url) {
    const win = window.open(url, "_blank");
    if (win) win.focus();
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  detailMonitoringNav = (row, column, url, rowVal = null) => {
    url += "&branchName=" + row.branch_name;

    url +=
      this.refFilter.branchId === undefined ? "&branchId=" + row.branch_id : "";

    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {rowVal ? rowVal : row[column.field!]}
      </a>
    );
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!]))
        .utc()
        .format("DD-MM-YYYY HH:mm")
      : "";
  };

  getPerc = (obj, div) => {
    return div > 0 ? ((obj / div) * 100).toFixed(2) : "0";
  };

  async onSorting(e) {
    console.log("onSorting code", this.refFilter);
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    this.loadData(this.refFilter);
  }

  render() {
    const footer = "Total data: " + this.dataSource.total;

    return (
      <div>
        <div className="row">
          {this.filterParams ? (
            <h3 style={{ float: "left", marginLeft: "10px" }}>
              Status - {this.filterParams.branch.branch_name}
            </h3>
          ) : (
              <h3></h3>
            )}
        </div>
        <DataTable
          ref={(el) => (this.dt = el)}
          emptyMessage={"Tidak ada data untuk ditampilkan."}
          scrollable={true}
          scrollHeight="550px"
          style={{ width: "100%" }}
          className="mt-4"
          value={this.dataSource.data}
          loading={this.dataSource.loading}
          sortField={this.state.sortField}
          sortOrder={this.state.sortOrder}
          onSort={(e) => {
            this.onSorting(e);
          }}
          // onValueChange={() => this.loadData()}
          footer={footer}
        >
          <Column
            field="branch_name"
            header="Cabang"
            sortable={true}
            style={{ width: "150px", "vertical-align": "text-top" }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/branch";
              let filterString = qs.stringify(this.refFilter);
              url += "?" + filterString;
              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="sigesit"
            header="Si Gesit"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url: string;

              console.log(this.refFilter.jamSpk, "jamspk");
              if (this.refFilter.jamSpk === "")
                url = "/admin/time_window/detail/branch/driver";
              else url = "/admin/time_window/detail/branch/driver";

              let filterString = qs.stringify(this.refFilter);
              url += "?" + filterString;
              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="pickup_point"
            header="Pickup Poin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
          />

          <Column
            field="total_spk_yesterday"
            header="Jumlah Resi Kemarin"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb";
              let filterString = qs.stringify(this.refFilter);
              url += "?status=spk_yes&title=SPK Yesterday&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_spk"
            header="Jumlah SPK Hari ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb";

              let filterString = qs.stringify(this.refFilter);

              url += "?status=spk_today&title=SPK Today&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_spk_today"
            header="Total SPK Hari Ini"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=total_awb&title=Total AWB&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="total_assigned"
            header="SPK Assign"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=assign&title=Assign&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_not_assigned"
            header="SPK Not Assign"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=not_assign&title=Not Assign&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_dropped"
            header="Drop"
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=drop&title=Drop&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="drop_percentage"
            header="% Drop"
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            sortable={false}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_dropped, row.total_spk_today)}</p>;
            }}
          // style={{ width: "4vw" }}
          />
          <Column
            field="total_picked"
            header="Pick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=pick&title=Pick&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="pick_percentage"
            header="% Pick"
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            filter={false}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_picked, row.total_spk_today)}</p>;
            }}
          />
          <Column
            field="total_pick_on_time"
            header="Pick On Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=pick_on_time&title=Pick On Time&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="pick_on_time_percentage"
            header="% Pick On Time"
            filter={false}
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_pick_on_time, row.total_picked)}</p>;
            }}
          />

          <Column
            field="total_pick_late_time"
            header="Pick Late Time"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url +=
                "?status=pick_late_time&title=Pick Late Time&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="pick_late_time_percentage"
            header="% Pick Late Time"
            filter={false}
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_pick_late_time, row.total_picked)}</p>;
            }}
          />
          <Column
            field="total_unpicked"
            header="UnPick"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/unpick";
              let filterString = qs.stringify(this.refFilter);
              url += "?status=unpick&title=Unpick&" + filterString;
              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="unpick_percentage"
            header="% Unpick"
            filter={false}
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_unpicked, row.total_spk_today)}</p>;
            }}
          />

          <Column
            field="total_pickup_request_type"
            header="RPU Plan Pickup"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=rpu&title=RPU Plan Pickup&" + filterString;
              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="total_auto_pop"
            header="Auto POP"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"

              let filterString = qs.stringify(this.refFilter);
              url += "?status=auto_pop&title=Auto POP&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
          <Column
            field="auto_pop_percentage"
            header="% Auto POP"
            filter={false}
            sortable={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              return <p>{this.getPerc(row.total_auto_pop, row.total_spk_today)}</p>;
            }}
          />
          <Column
            field="total_not_pop"
            header="Not POP"
            sortable={true}
            filter={false}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"

              let filterString = qs.stringify(this.refFilter);
              url += "?status=not_pop&title=Not POP&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_manifested"
            header="Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=manifest&title=Manifest&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />

          <Column
            field="total_not_manifested"
            header="Not Manifest"
            filter={false}
            sortable={true}
            style={{
              width: "130px",
              textAlign: "center",
              "vertical-align": "text-top",
            }}
            body={(row, column: ColumnProps) => {
              let url = "/admin/time_window/detail/awb"
              let filterString = qs.stringify(this.refFilter);
              url += "?status=not_manifest&title=Not Manifest&" + filterString;

              return this.detailMonitoringNav(row, column, url);
            }}
          />
        </DataTable>
        <Paginator
          pageLinkSize={10}
          totalRecords={this.dataSource.total}
          first={this.dataSource.offset}
          rows={this.dataSource.rows}
          rowsPerPageOptions={this.dataSource.rowsOptions}
          onPageChange={(e) => this.dataSource.fromPageState(e)}
        />
      </div>
    );
  }
}
