import classNames from "classnames";
import React from "react";
import { Redirect } from "react-router-dom";

export class LayoutAdminInlineProfile extends React.Component<any, any> {
  userData;

  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      redirect: false,
    };
    this.onClick = this.onClick.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentWillMount() {
    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    ///check if login still active if not  log off
    if (this.userData) {
      const decodedJwt = this.parseJwt(this.userData.accessToken);
      if (decodedJwt.exp * 1000 < Date.now()) {
        this.logout();
        window.location.reload();
      }
    }
  }

  parseJwt = (token) => {
    try {
      return JSON.parse(atob(token.split('.')[1]));
    } catch (e) {
      return null;
    }
  };

  onClick(event) {
    this.setState({ expanded: !this.state.expanded });
    event.preventDefault();
  }

  logout() {
    localStorage.setItem("userData", "");
    localStorage.setItem("branchCode", "");

    localStorage.clear();
    this.setState({ redirect: true });
    window.location.reload();

  }

  render() {
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    if (!!this.userData) {
      if (this.userData.user != null) {
        this.userData.displayName = this.userData.user.displayName;
        this.userData.isHeadOffice = this.userData.user.isHeadOffice;
        this.userData.branchName = this.userData.user.branchName;
        this.userData.branchCode = this.userData.user.branchCode;
        this.userData.branchId = this.userData.role.branchId;
        this.userData.roleId = this.userData.role.roleId;
        this.userData.platform = "tms";
      }
      else {
        this.userData.isHeadOffice = true;
        this.userData.platform = "monitoring";
      }
      localStorage.setItem("userData", JSON.stringify(this.userData));
    }

    return (
      <div className="profile">
        <div>
          <img alt="" style={{ margin: "10px auto" }} />
        </div>
        <button className="p-link profile-link" onClick={this.onClick}>
          <span className="username">{this.userData!.displayName}</span>
          <i className="pi pi-fw pi-cog" />
        </button>
        <ul className={classNames({ "profile-expanded": this.state.expanded })}>
          {/* <li>
            <button className="p-link">
              <i className="pi pi-fw pi-user" />
              <span>Account</span>
            </button>
          </li> */}
          <li>
            <button className="p-link" onClick={this.logout}>
              <i className="pi pi-fw pi-power-off" onClick={this.logout} />
              <span>Logout</span>
            </button>
          </li>
        </ul>
      </div>
    );
  }
}
