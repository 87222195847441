import { observer } from 'mobx-react';
import moment from 'moment';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { RefObject } from 'react';

import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { DateHelper } from '../../utils/DateHelper';
import { Growl } from 'primereact/growl';
import { Fieldset } from 'primereact/fieldset';
import { TimeWindowAllSummaryContainer } from './TimeWindowAllSummaryContainer';
import { TimeWindowAllSummaryDetailContainer } from './TimeWindowAllSummaryDetailContainer';
import { TimeWindowAllPickupPlanContainer } from './TimeWindowAllPickupPlanContainer';

@observer
export class TimeWindowSummaryContainer extends React.Component<any, any> {
    dataTableFilterService = DependencyContainer.get(DataTableFilterService);
    monitoringRestService = DependencyContainer.get(MonitoringRestService);

    data;
    growl;
    dataSource = new DataSource<any>();
    refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
    filterParams;

    refAllSummary: RefObject<TimeWindowAllSummaryContainer> = React.createRef();
    refAllSummaryDetail: RefObject<TimeWindowAllSummaryDetailContainer> = React.createRef();
    refAllPickup: RefObject<TimeWindowAllPickupPlanContainer> = React.createRef();


    constructor(props) {
        super(props);
        this.state = {
            exportExcelLoading: false,
            showExportDialog: false,
            filterCollapsed: false,
            sortField: null,
            sortOrder: null,
            showingPickupPlan: true
        }
    }

    componentDidMount() {
        this.dataSource.onPageChange.subscribe(() => {
            this.loadData(this.filterParams);
        });


    }

    loadData(filterParams: any) {
        console.log("in filter summary window container")
        const pagingParams = { limit: this.dataSource.rows, page: this.dataSource.page };
        const sortingParams = { sortField: this.state.sortField, sortOrder: (this.state.sortOrder === -1) ? 'desc' : 'asc' }

        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams, pagingParams, sortingParams);

        if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);
        if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);




        const brId = this.filterParams.branchId ? false : true;
        this.setState({ showingPickupPlan: brId })


        console.log(fParams, "fparams in list")

        this.refAllSummary.current!.loadData(this.filterParams);
        this.refAllSummaryDetail.current!.loadData(this.filterParams);

        if (brId) {
            this.refAllPickup.current!.loadData(this.filterParams);
        }
    }

    downloadCsv(filterParams: any) {
        this.filterParams = filterParams;
        const fParams = Object.assign({}, filterParams);
        if (fParams.branch)
            fParams.branchId = fParams.branch.value

        if (fParams.partner)
            fParams.partnerId = fParams.partner.value

        if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);
        if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);

        const resPath = (this.filterParams.slaType! && this.filterParams.slaType === 'external')
            ? 'oversla-csv'
            : 'oversla-csv';

        this.monitoringRestService
            .post(resPath, this.filterParams)
            .subscribe(
                response => {
                    if (response && response.path) {
                        this.openCsv(response.path);
                    }
                },
                () => {
                    let msg = { severity: 'error', summary: 'Error Message', detail: 'Export failed' };
                    this.growl.show(msg);
                },
                () => {
                    let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
                    this.growl.show(msg);
                },
            );
    }

    openCsv(url) {
        const win = window.open(url, '_blank');
        if (win) win.focus();
    }

    populateDataSource() {
        if (this.data.data && this.data.data.length) {
            this.dataSource.setData(this.data.data);
            this.dataSource.setTotal(this.data.count);
        } else {
            this.dataSource.setData([]);
            this.dataSource.setTotal(0);
        }
    }

    onClickAwbNumber = (awbNumber) => {
        this.refAwbDetailModal.current!.handleShow(awbNumber);
    }

    columnAwbNumber = (rowData, column: ColumnProps) => {
        return (
            <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
        );
    }

    columnDateFormat = (rowData, column: ColumnProps) => {
        return (
            (rowData[column.field!] != null) ? moment(new Date(rowData[column.field!])).utc().format('DD-MM-YYYY HH:mm') : ''
        );
    }

    detailMonitoringNav = (row, column, url) => {

        url += "&branchName=" + row.branch_name;

        return (
            <a
                href="javascript:void(0);"
                onClick={async () => {
                    window.open(url, "_blank");
                }}
                style={{ textAlign: "center" }}
            >
                {row[column.field!]}
            </a>
        );
    }



    async onSorting(e) {
        if (e && e.sortField) {
            const sortOrder = (this.state.sortField === e.sortField)
                ? (this.state.sortOrder === 1) ? -1 : 1
                : 1;
            await this.setState({ sortField: e.sortField, sortOrder: sortOrder })
        } else {
            await this.setState({ sortField: null, sortOrder: null })
        }

        console.log(e);
        console.log(this.state);
        this.loadData(this.filterParams);
    }

    render() {
        return (
            <div>
                <div className="row">
                    <div className="col-md-3">
                        <Fieldset legend="Summary" toggleable={true} collapsed={this.state.tujuanCollapsed} onToggle={(e) => this.setState({ tujuanCollapsed: e.value })}>
                            <TimeWindowAllSummaryContainer ref={this.refAllSummary} />
                        </Fieldset>
                    </div>

                    <div className="col-md-3">
                        <div className="row">
                            <div className="col-md-12">
                                <Fieldset legend="Detail" toggleable={true} collapsed={this.state.lastTrackingSiteCollapsed} onToggle={(e) => this.setState({ lastTrackingSiteCollapsed: e.value })}>
                                    <TimeWindowAllSummaryDetailContainer ref={this.refAllSummaryDetail} />
                                </Fieldset>
                            </div>
                        </div>
                    </div>

                    {this.state.showingPickupPlan ? (
                        <div className="col-md-3">
                            <div className="row">
                                <div className="col-md-12">
                                    <Fieldset legend="Pickup Plan" toggleable={true} collapsed={this.state.lastTrackingSiteCollapsed} onToggle={(e) => this.setState({ lastTrackingSiteCollapsed: e.value })}>
                                        <TimeWindowAllPickupPlanContainer ref={this.refAllPickup} />
                                    </Fieldset>
                                </div>
                            </div>
                        </div>
                    ) : null}

                </div>
            </div>
        );
    }
}
