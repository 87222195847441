import { observer } from "mobx-react";
import moment from "moment";
import { Button } from "primereact/button";
import { Column, ColumnProps } from "primereact/column";
import { Growl } from "primereact/growl";
import { DataTable } from "primereact/datatable";
import { Paginator } from "primereact/paginator";
import React, { RefObject } from "react";
import { RouterProps } from "react-router";
import { Flex } from "reflexbox";
import _ from "lodash";
import { ColumnGroup } from 'primereact/columngroup';
import { Row } from 'primereact/row';
import { Link } from "react-router-dom";
import qs from "qs";
import { DataSource } from "../../models/DataSource";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { DependencyContainer } from "../../utils/DependencyInjection";
import {
  ICapacityPickupFilterContainerValue,
  CapacityPickupFilterContainer,
} from "./CapacityPickupFilterContainer";
import { AwbDetailModalComponent } from "../awb/AwbDetailModalComponent";
import { Dialog } from "primereact/dialog";
import { DateHelper } from "../../utils/DateHelper";
import { saveAs } from 'file-saver';
import { ExportToCsv } from "export-to-csv";
import { Formik } from "formik";
import { Grid, FormGroup, Input, FormControl } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { observable } from "mobx";
import { InputText } from "primereact/inputtext";
import { FileUpload } from "primereact/fileupload";
import { default as NumberFormat } from 'react-number-format';
import { Panel } from "primereact/panel";
import { AxiosRequestConfig } from "axios";


export interface IAwbListContainerQuery {
  filters: ICapacityPickupFilterContainerValue;
}

@observer
export class CapacityPickupMongoContainer extends React.Component<
RouterProps,
any
> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  @observable public total: number = 0;
  color = { yellow: 'FFE598', lGreen: 'C5E0B3', green: '70AD47', brown: 'ED7D31', red: "FF0000" };
  stylePPOpup = {
    margin: "0 0 10px"
  };
  data;
  dt;
  growl: any;
  dataSource = new DataSource<any>();
  refFilter: RefObject<CapacityPickupFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();
  userData;
  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      showUploadDialog: false,
      showCapacityDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
      modal: null,
      expandedRows: null,
      footerTotal: {}
    };
    this.userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    this.onRowToggle = this.onRowToggle.bind(this);
    this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
  }

  componentDidMount() {
    const queryFilter = this.props.history.location.search
      ? qs.parse(this.props.history.location.search.substr(1))
      : {};

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(
          queryFilter.filters
        );
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      setTimeout(() => {
        this.loadData();

      }, 1000);

    });
    this.dataSource.setPage(1);
  }

  loadData(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    this.setState({ expandedRows: null });

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }
    if (fParams.branch) fParams.branchId = fParams.branch.value;

    if (fParams.partner) fParams.partnerId = fParams.partner.value;

    if (fParams.city) fParams.cityId = fParams.city.value;

    if (fParams.province) fParams.provinceId = fParams.province.value;

    fParams.format = "list";

    this.dataSource.loading = true;
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };


    this.monitoringRestService
      .post("/CapacityPickup/list/mongo", fParams, axiosConfig)
      .subscribe(
        (response) => {
          this.data = response;

          this.populateDataSource();
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  calculateTotals(dataField) {
    const footerTotal = {
      total_pu_cap: _.sumBy(dataField, function (o: any) {
        return o.pu_cap;
      }),
      total_pu_ops_cap: _.sumBy(dataField, function (o: any) {
        return o.pu_ops_cap;
      }),
      total_pu_ops_cap_assign: _.sumBy(dataField, function (o: any) {
        return o.pu_ops_cap_assign;
      }),
      total_yes: _.sumBy(dataField, function (o: any) {
        return o.yesterday;
      }),
      total_manifest: _.sumBy(dataField, function (o: any) {
        return o.manifest;
      }),
      total_jumlah_paket: _.sumBy(dataField, function (o: any) {
        return o.total_pick + o.dropped + o.total_cash;
      }),
      total_vol_pick: _.sumBy(dataField, function (o: any) {
        return o.total_pick;
      }),
      total_vol_drop: _.sumBy(dataField, function (o: any) {
        return o.dropped;
      }),
      total_cash: _.sumBy(dataField, function (o: any) {
        return o.total_cash;
      }),
      total_pick: _.sumBy(dataField, function (o: any) {
        return o.total_pick;
      }),
      total_drop: _.sumBy(dataField, function (o: any) {
        return o.dropped;
      }),
      total_volume: _.sumBy(dataField, function (o: any) {
        return o.total_volume;
      }),
      total_pu: _.sumBy(dataField, function (o: any) {
        return o.pickup_point;
      }),
      total_target: _.sumBy(dataField, function (o: any) {
        return o.pu_target;
      }),
      total_target_driver: _.sumBy(dataField, function (o: any) {
        return o.pu_ops_cap_driver;
      }),
      total_target_freelance: _.sumBy(dataField, function (o: any) {
        return o.pu_ops_cap_freelance;
      }),
      total_sigesit: _.sumBy(dataField, function (o: any) {
        return parseInt(o.pickuppermanent.toString());
      }),
      total_sigesit_assign: _.sumBy(dataField, function (o: any) {
        return parseInt(o.permanent_assign_count.toString());
      }),

      total_probation: _.sumBy(dataField, function (o: any) {
        return parseInt(o.pikcupprobation.toString());
      }),
      total_probation_assign_count: _.sumBy(dataField, function (o: any) {
        return parseInt(o.probation_assign_count.toString());
      }),

      total_freelance: _.sumBy(dataField, function (o: any) {
        return parseInt(o.pickupfreelance.toString());
      }),
      total_freelance_assign: _.sumBy(dataField, function (o: any) {
        return parseInt(o.freelance_assign_count.toString());
      }),

      total_driver: _.sumBy(dataField, function (o: any) {
        return parseInt(o.pickupdriverpermanent.toString());
      }),
      total_driver_assign: _.sumBy(dataField, function (o: any) {
        return parseInt(o.driver_assign_count.toString());
      }),

      total_driver_probation: _.sumBy(dataField, function (o: any) {
        return parseInt(o.pickupdriverprobation.toString());
      }),
      total_driver_probation_assign: _.sumBy(dataField, function (o: any) {
        return parseInt(o.driver_assign_probation_count.toString());
      }),
    };
    console.log(footerTotal, "footer total");
    this.setState({ footerTotal: footerTotal });
  }

  prepareUploadData(filterParams: any = null) {
    this.setState({ showUploadDialog: true });
  }

  downloadTemplateXls(filterParams: any = null) {
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
      responseType: 'blob'
    };

    this.monitoringRestService
      .post("/CapacityPickup/getXlsUploadFile", {}, axiosConfig)
      .subscribe((response) => {
        let filename = 'TemplateCapacityPickupBulk.xlsx';
        let url = window.URL
          .createObjectURL(new Blob([response], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }));
        saveAs(url, filename);
      });
  }

  uploadFileXls = ({ files }) => {
    const [file] = files;

    this.uploadFile(file);

    // const fileReader = new FileReader();
    // fileReader.onload = (e: any) => {
    //   this.uploadFile(e.target.result);
    // };
    // fileReader.readAsBinaryString(file);
  };

  uploadFile = async (File) => {
    let formData = new FormData();
    formData.append('file', File);
    formData.append('user_id', this.userData.userId);
    formData.append('user_name', this.userData.username);
    const headers = {
      "accept": "*/*",
      "Content-Type": "multipart/form-data",
      "Authorization": "Bearer " + this.userData.accessToken
    };
    const dataSource = this.dataSource;
    const growl = this.growl;
    this.monitoringRestService
      .post("/CapacityPickup/PostUploadFile", formData, { headers: headers }).subscribe((d) => {
        growl.show({
          severity: "info",
          summary: d.data.message,
        });
        setTimeout(() => {
          dataSource.setPage(1);
          this.setState({ showUploadDialog: false });

        }, 1000);

      });

    // const url = process.env.REACT_APP_BACKEND_URL
    // const response = await fetch(`${url}/CapacityPickup/PostUploadFile`,
    //   {
    //     headers,
    //     method: 'POST',
    //     body: formData
    //   },
    // );

  };

  downloadCsv(filterParams: any = null) {
    const pagingParams = {
      limit: this.dataSource.rows,
      page: this.dataSource.page,
    };
    this.setState({ expandedRows: null });

    const sortingParams = {
      sortField: this.state.sortField,
      sortOrder: this.state.sortOrder === -1 ? "desc" : "asc",
    };

    const fParams = filterParams
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign(
        {},
        this.refFilter.current!.formValues,
        pagingParams,
        sortingParams
      );

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate) {
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    }
    if (fParams.branch) fParams.branchId = fParams.branch.value;

    if (fParams.partner) fParams.partnerId = fParams.partner.value;

    if (fParams.city) fParams.cityId = fParams.city.value;

    if (fParams.province) fParams.provinceId = fParams.province.value;

    fParams.format = "xls";
    const axiosConfig: AxiosRequestConfig = {
      headers: {
        Authorization: "Bearer " + this.userData.accessToken,
        Accept: 'application/json'
      },
    };
    this.dataSource.loading = true;
    this.monitoringRestService
      .post("/CapacityPickup/list/mongo", fParams, axiosConfig)
      .subscribe(
        (response) => {
          const data = response.data.map((row) => {
            const jumlahPaket = row.total_pick + row.dropped + row.total_cash;

            return {
              province_name: row.province_name,
              city_name: row.city_name,
              branch_name: row.branch_name,
              kecamatan: row.district_name,
              branch_code: "'" + row.branch_code,
              pu_cap: row.pu_cap,
              is_active: row.is_active,
              pu_ops_cap: row.pu_ops_cap,
              pu_ops_cap_assign: row.pu_ops_cap_assign,
              jumlah_paket: jumlahPaket,
              total_volume: row.total_volume,
              manifest: row.manifest,
              yesterday: row.yesterday,
              total_cash: row.total_cash,
              total_pick: row.total_pick,
              dropped: row.dropped,
              pickup_point: row.pickup_point,
              pu_target: row.pu_target,
              pu_ops_cap_driver: row.pu_ops_cap_driver,
              pu_ops_cap_freelance: row.pu_ops_cap_freelance,
              capvspaket: row.cap_tempat_pct,
              opsvspaket: row.cap_person_pct,
              pickuppermanent: row.pickuppermanent,
              permanent_assign_count: row.permanent_assign_count,
              pikcupprobation: row.pikcupprobation,
              probation_assign_count: row.probation_assign_count,
              pickupfreelance: row.pickupfreelance,
              freelance_assign_count: row.freelance_assign_count,
              pickupdriverpermanent: row.pickupdriverpermanent,
              driver_assign_count: row.driver_assign_count,
              pickupdriverprobation: row.pickupdriverprobation,
              driver_assign_probation_count: row.driver_assign_probation_count,
            };
          });

          const date = new Date();
          const options = {
            fieldSeparator: ",",
            filename: `${moment(date).format(
              "YYYY-MM-DD"
            )}:${uuidv4()}_capacity_pickup`,
            quoteStrings: '"',
            decimalSeparator: ".",
            showLabels: true,
            useTextFile: false,
            useBom: true,
            headers: [
              "Province",
              "Kota",
              "Cabang",
              "Kecamatan",
              "Kode Analitic",
              "PU Cap.",
              "Active",
              "PU OPS Cap Terdaftar",
              "PU OPS Cap Assign",
              "Jumlah Paket",
              "Total Volume",
              "Total Cash",
              "Total Pick",
              "Total Drop",
              "TITIK PU",
              "Target Sigesit",
              "Target Driver",
              "Target Freelance",
              "Over/Under Cap",
              "Over/Under Ops",
              "Si Gesit Reguler Terdafatar",
              "Si Gesit Reguler Assign",
              "Si Gesit Reguler Probation Terdaftar",
              "Si Gesit Reguler Probation Assign",
              "Si Gesit Freelance Terdaftar",
              "Si Gesit Freelance Assign",
              "Driver Terdaftar",
              "Driver Assign",
              "Driver Probation Terdaftar",
              "Driver Probation Assign",
            ],
          };
          const csvExporter = new ExportToCsv(options);
          csvExporter.generateCsv(data);
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }

  openCsv(url) {
    // const win = window.open(url, '_blank');
    // if (win) win.focus();
    window.location = url;
  }

  async exportCourierCsv(podData) {
    this.setState({ exportExcelLoading: true });


    if (podData && podData.exportTableData) {
      await podData.exportTableData.exportCSV();
      this.setState({ exportExcelLoading: false });
      let msg = {
        severity: "success",
        summary: "Success Message",
        detail: "Export Success",
      };
      this.growl.show(msg);
    }
  }

  populateDataSource() {
    for (const dt of this.data.data) {
      dt.courierDataLoaded = false;
      dt.courierDataSource = this.createCourierDataSource();
    }
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);

      this.calculateTotals(this.data.data);

    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);

    }
  }

  createCourierDataSource() {
    const dataSource = new DataSource<any>();
    dataSource.setData([]);
    dataSource.setTotal(0);
    return dataSource;
  }

  //row expand

  loadKurirData(podData) {
    const formFilters = Object.assign({}, this.refFilter.current!.formValues, {
      branchId: podData.branch_id,
    });
    if (formFilters.startDate)
      formFilters.startDate = DateHelper.formatLocalDateForQueryParam(
        formFilters.startDate
      );

    const datasource = podData.courierDataSource;
    const _this = this;

    return new Promise((resolve, reject) => {
      const axiosConfig: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + this.userData.accessToken,
          Accept: 'application/json'
        },
      };

      datasource.loading = true;
      _this.monitoringRestService
        .post("/CapacityPickup/list/detail", formFilters, axiosConfig)
        .subscribe(
          (response) => {
            let dataForEach: any[] = [];
            response.employee.forEach((row) => {

              const d = {
                nik: row.nik,
                fullname: row.fullname,
                totalSpk: row.totalSpk,
                pickupPoin: row.pickupPoint,
                branch_id: response.branch.branch_id,
                branch_name: response.branch.branch_name,
                branch_type_name: response.branch.branch_type,
                pu_cap: row.pu_cap ? row.pu_cap : 0,
                pu_target: row.pu_cap ? row.pu_cap : 0,
                pu_ops_cap_driver: response.branch.pu_ops_cap_driver,
                pu_ops_cap_freelance: response.branch.pu_ops_cap_freelance,
                cap_vs_paket: this.getPerc(row.totalSpk, row.pu_cap) + " %",
              };
              dataForEach.push(d);
            });
            datasource.setData(dataForEach);
            datasource.setTotal(dataForEach.length);
            podData.courierDataLoaded = true;
            resolve(response);
          },
          (err) => {
            reject(err);
          },
          () => {
            datasource.loading = false;
          }
        );
    });
  }

  async onRowToggle(e) {
    // this.setState({ expandedRows: e.data });

    const promises: any[] = [];
    if (e.data) {
      for (var key in e.data) {
        if (e.data.hasOwnProperty(key)) {
          var value = e.data[key];
          if (value) {
            const podData = _.find(
              this.data.data,
              (d) => d.branch_id === parseInt(key)
            );
            // if (podData && !podData.courierDataLoaded) {
            promises.push(this.loadKurirData(podData));
            // await this.loadCourierData(podData);
            // }
          }
        }
      }
    }
    await Promise.all(promises);
    this.setState({ expandedRows: e.data });
  }

  rowExpansionTemplate(data) {
    const podData = _.find(this.data.data, (d: any) => {
      return d.branch_id === data.branch_id;
    });
    const datasource = podData.courierDataSource;
    const footer = (
      <div style={{ textAlign: "left" }}>
        <Button
          style={{ width: "80px" }}
          type="button"
          icon={
            this.state.exportExcelLoading
              ? "pi pi-spin pi-spinner"
              : "pi pi-external-link"
          }
          iconPos="left"
          label="CSV"
          onClick={() => this.exportCourierCsv(podData)}
        ></Button>
        <span
          style={{ textAlign: "left", marginLeft: "8px", marginTop: "6px" }}
        >
          Total Kurir: {datasource ? datasource.total : 0}{" "}
        </span>
      </div>
    );

    return (
      <div className="p-grid p-fluid" style={{ padding: "1em 1em 1em 1em" }}>
        <div className="p-col-12 p-md-12">
          <DataTable
            emptyMessage={"Tidak ada data untuk ditampilkan."}
            scrollable={true}
            scrollHeight="200px"
            className="mt-4"
            value={datasource.data}
            loading={datasource.loading}
            footer={footer}
            ref={(el) => {
              podData.exportTableData = el;
            }}
          >
            <Column
              field="nik"
              header="NIK"
              filter={true}
              filterMatchMode="contains"
              sortable={true}
              style={{ width: "20px" }}
            />
            <Column
              field="fullname"
              header="Nama"
              filter={true}
              sortable={true}
              style={{ width: "20px" }}
            />
            <Column
              field="pu_cap"
              header="PU CAP"
              sortable={true}
              style={{ width: "15px" }}
            />
            <Column
              field="totalSpk"
              header="jumlah paket"
              style={{ width: "15px" }}
            />

            <Column
              field="pickupPoin"
              header="Pickup Poin"
              style={{ width: "15px" }}
            />
            <Column
              field="cap_vs_paket"
              header="Cap vs Paket"
              style={{ width: "20px" }}
            />
          </DataTable>
        </div>
      </div>
    );
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  };

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  columnBranch = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>
        {rowData[column.field!]}
      </a>
    );
  };

  detailMonitoringNav = (row, column, url) => {
    return (
      <a
        href="javascript:void(0);"
        onClick={async () => {
          window.open(url, "_blank");
        }}
        style={{ textAlign: "center" }}
      >
        {row[column.field!]}
      </a>
    );
  };

  columnDateFormat = (rowData, column: ColumnProps) => {
    return rowData[column.field!] != null
      ? moment(new Date(rowData[column.field!])).format("DD-MM-YYYY HH:mm")
      : "";
  };

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder =
        this.state.sortField === e.sortField
          ? this.state.sortOrder === 1
            ? -1
            : 1
          : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder });
    } else {
      await this.setState({ sortField: null, sortOrder: null });
    }

    await this.loadData();
  }

  getPerc = (obj, div) => {
    return div > 0 ? ((obj / div) * 100).toFixed(2) : "0";
  };

  render() {
    const { modal } = this.state;

    let headerGroup = (
      <ColumnGroup>
        <Row>
          <Column header={"Totals:" + (this.dataSource.total).toLocaleString()} colSpan={11} />
          <Column header={this.state.footerTotal.total_jumlah_paket ? this.state.footerTotal.total_jumlah_paket.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_volume ? this.state.footerTotal.total_volume.toLocaleString() : ""} />

          <Column header={this.state.footerTotal.total_yes ? this.state.footerTotal.total_yes.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_manifest ? this.state.footerTotal.total_manifest.toLocaleString() : ""} />

          <Column header={this.state.footerTotal.total_cash ? this.state.footerTotal.total_cash.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_pick ? this.state.footerTotal.total_pick.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_drop ? this.state.footerTotal.total_drop.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_pu ? this.state.footerTotal.total_pu.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_target ? this.state.footerTotal.total_target.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_target_driver ? this.state.footerTotal.total_target_driver.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_target_freelance ? this.state.footerTotal.total_target_freelance.toLocaleString() : ""} />
          <Column header="" />
          <Column header="" />

          <Column header={this.state.footerTotal.total_sigesit ? this.state.footerTotal.total_sigesit.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_sigesit_assign ? this.state.footerTotal.total_sigesit_assign.toLocaleString() : ""} />

          <Column header={this.state.footerTotal.total_probation ? this.state.footerTotal.total_probation.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_probation_assign_count ? this.state.footerTotal.total_probation_assign_count.toLocaleString() : ""} />

          <Column header={this.state.footerTotal.total_freelance ? this.state.footerTotal.total_freelance.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_freelance_assign ? this.state.footerTotal.total_freelance_assign.toLocaleString() : ""} />


          <Column header={this.state.footerTotal.total_driver ? this.state.footerTotal.total_driver.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_driver_assign ? this.state.footerTotal.total_driver_assign.toLocaleString() : ""} />


          <Column header={this.state.footerTotal.total_driver_probation ? this.state.footerTotal.total_driver_probation.toLocaleString() : ""} />
          <Column header={this.state.footerTotal.total_driver_probation_assign ? this.state.footerTotal.total_driver_probation_assign.toLocaleString() : ""} />
        </Row>
        <Row>
          <Column header="" rowSpan={2} />
          <Column header="" rowSpan={2} />
          <Column
            header="Province"
            field="province_name"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Kota"
            field="city_name"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Cabang"
            field="branch_name"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />
          <Column
            header="Kecamatan"
            field="district_name"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />

          <Column
            header="Kode Analytic"
            field="branch_code"
            rowSpan={2}
            filter={true}
            filterMatchMode="contains"
            sortable={true}
          />

          <Column
            header="PU Cap."
            field="pu_cap"
            rowSpan={2}
            sortable={true}
          />

          <Column
            header="is_active"
            field="Active"
            rowSpan={2}
            sortable={true}
          />
          <Column header="Pu OPS Cap" colSpan={2} />
          <Column
            header="Jumlah Paket"
            field="jumlahPaket"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Total Volume"
            field="total_volume"
            rowSpan={2}
            sortable={true}
          />

          <Column
            header="Total Yesterday"
            field="total_yes"
            rowSpan={2}
            sortable={true}
          />

          <Column
            header="Total Manifest"
            field="total_manifest"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Total Cash"
            field="total_cash"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Total Pick"
            field="total_pick"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Total Drop"
            field="dropped"
            rowSpan={2}
            sortable={true}
          />

          <Column
            header="Titik PU"
            field="pickup_point"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Target Sigesit"
            field="target_sigesit"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Target Driver"
            field="target_driver"
            rowSpan={2}
            sortable={true}
          />
          <Column
            header="Target Freelance"
            field="target_freelance"
            rowSpan={2}
            sortable={true}
          />


          <Column
            header="Over/Under Capacity"
            field="capvspaket"
            rowSpan={2}
            sortable={true}
          />

          <Column
            header="Over/Under Ops"
            field="opsvspaket"
            rowSpan={2}
            sortable={true}
          />


          <Column header="SiGesit Reguler" colSpan={2} />
          <Column header="SiGesit Reguler (Probation)" colSpan={2} />
          <Column header="SiGesit Freelance" colSpan={2} />
          <Column header="SiGesit Driver" colSpan={2} />
          <Column header="SiGesit Driver (Probation)" colSpan={2} />
        </Row>
        <Row>
          <Column
            field="pu_ops_cap"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />

          <Column
            field="pu_ops_cap_assign"
            header="Assign"
            rowSpan={2}
            sortable={true}
          />

          <Column
            field="dlvOpsCapacityOverUnderByCheckin"
            header="Terdaftar"
            sortable={true}
          />
          <Column
            field="dlvOpsCapacityOverUnderPercByCheckin"
            header="Assign"
            sortable={true}
          />

          <Column
            field="employeeAntarPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarPermanentCheckin"
            header="Assign"
            sortable={true}
          />
          <Column
            field="employeeAntarProbation"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarProbationCheckin"
            header="Assign"
            sortable={true}
          />

          <Column
            field="employeeAntarDriverPermanent"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarDriverPermanentCheckin"
            header="Assign"
            sortable={true}
          />
          <Column
            field="employeeAntarDriverProbation"
            header="Terdaftar"
            rowSpan={2}
            sortable={true}
          />
          <Column
            field="employeeAntarDriverProbationCheckin"
            header="Assign"
            sortable={true}
          />

        </Row>
      </ColumnGroup>
    );

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />
        <Dialog
          header="Capacity"
          visible={this.state.showCapacityDialog}
          style={{ width: "60vw" }}
          modal={true}
          onHide={() => {
            this.setState({
              showCapacityDialog: false,
              modal: null,
            });

            this.dataSource.setPage(1);
          }}
        >
          {modal ? (
            <Formik
              initialValues={{
                pu_ops_cap: modal.pu_ops_cap,
                pu_cap: modal.pu_cap,
                branch_id: modal.branch_id,
                pu_ops_cap_freelance: modal.pu_ops_cap_freelance,
                pu_ops_cap_driver: modal.pu_ops_cap_driver,
                pu_target: modal.pu_target,
                branch_code: modal.branch_code,
                branch_name: modal.branch_name,
                province_name: modal.province_name,
                city_name: modal.city_name,
                district_name: modal.district_name,
                is_active: modal.is_active == "Yes" ? true : false,
              }}
              onSubmit={(val) => {
                let search = window.location.search;
                let params = new URLSearchParams(search);

                let paramsInsert = {
                  PUOpsCap: val.pu_ops_cap,
                  PUCap: val.pu_cap,
                  branchId: val.branch_id,
                  PUOpsCapFreelance: val.pu_ops_cap_freelance,
                  PUOpsCapDriver: val.pu_ops_cap_driver,
                  PUTarget: val.pu_target,
                };
                const axiosConfig: AxiosRequestConfig = {
                  headers: {
                    Authorization: "Bearer " + this.userData.accessToken,
                    Accept: 'application/json'
                  },
                };
                this.monitoringRestService
                  .post("/CapacityPickup/branchCap", paramsInsert, axiosConfig)
                  .subscribe(
                    async (response) => {
                      if (response.data.message) {
                        this.growl.show({
                          severity: "info",
                          summary: response.data.message,
                        });
                      }
                      this.setState({
                        isLoading: false,
                        modal: null,
                        showCapacityDialog: false,
                      });
                      setTimeout(function () {
                        dataSource.setPage(1);
                      }, 1000);
                    },
                    (err) => {
                      this.dataSource.loading = false;
                    },
                    () => {
                      this.dataSource.loading = false;
                    }
                  );
                const dataSource = this.dataSource;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other lengthes */
              }) => (
                <form onSubmit={handleSubmit}>
                  <Flex wrap w={1}>
                    <Flex column>
                      <Grid item md={12}>
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">Kode Analitic</label>
                            <div className="col-md-8">
                              <InputText
                                type="text"
                                disabled={true}
                                value={values.branch_code}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">Nama</label>
                            <div className="col-md-8">
                              <InputText
                                type="text"
                                disabled={true}
                                value={values.branch_name}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">Province</label>
                            <div className="col-md-8">
                              <InputText
                                type="text"
                                disabled={true}
                                value={values.province_name}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">Kota</label>
                            <div className="col-md-8">
                              <InputText
                                type="text"
                                disabled={true}
                                value={values.city_name}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">

                          <div className="form-group">
                            <label className="col-md-4">Kecamatan</label>
                            <div className="col-md-8">
                              <InputText
                                type="text"
                                disabled={true}
                                value={values.district_name}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">

                          <div className="form-group">
                            <label className="col-md-4">Active</label>
                            <div className="col-md-8">
                              {values.is_active ? <label>true</label> : <label>false</label>}

                            </div>
                          </div>
                        </div>
                      </Grid>
                    </Flex>
                    <Flex column>
                      <Grid item md={12}>
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">PU. CAP</label>
                            <div className="col-md-8">
                              <NumberFormat
                                className="p-inputtext p-component p-filled"
                                thousandSeparator={true}
                                onChange={handleChange("pu_cap")}
                                value={values.pu_cap}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">

                          <div className="form-group">
                            <label className="col-md-4">Target Freelance</label>
                            <div className="col-md-8">
                              <NumberFormat
                                thousandSeparator={true}
                                className="p-inputtext p-component p-filled"
                                onChange={handleChange("pu_ops_cap_freelance")}
                                value={values.pu_ops_cap_freelance}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">

                          <div className="form-group">
                            <label className="col-md-4">Target Driver</label>
                            <div className="col-md-8">
                              <NumberFormat
                                thousandSeparator={true}
                                className="p-inputtext p-component p-filled"
                                onChange={handleChange("pu_ops_cap_driver")}
                                value={values.pu_ops_cap_driver}
                              />
                            </div>
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="form-group">
                            <label className="col-md-4">Target Sigesit</label>
                            <div className="col-md-8">
                              <NumberFormat
                                thousandSeparator={true}
                                className="p-inputtext p-component p-filled"
                                onChange={handleChange("pu_target")}
                                value={values.pu_target}
                              />
                            </div>
                          </div>
                        </div>
                      </Grid>

                    </Flex>
                  </Flex>
                  <FormControl margin="dense">
                    <Button
                      type="submit"
                      className="p-button-info p-button-raised"
                      label="Submit"
                    />
                  </FormControl>
                </form>
              )}
            </Formik>
          ) : null}
        </Dialog>

        <Dialog
          header="Informasi"
          visible={this.state.showExportDialog}
          style={{ width: "40vw" }}
          modal={true}
          onHide={() => this.setState({ showExportDialog: false })}
        >
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>
        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />
        <Dialog
          header="Informasi"
          visible={this.state.showUploadDialog}
          style={{ width: "50vw" }}
          modal={true}
          onHide={() => {
            this.setState({ showUploadDialog: false });
            this.dataSource.setPage(1);
          }}
        >
          <Flex wrap w={1} style={{ display: "flex", flexWrap: "wrap", flexDirection: "column", alignContent: "center" }}>
            <Flex column>
              <Grid item md={12}>

                <div className="p-panel p-component">

                  <div className="row">
                    <h2 style={this.stylePPOpup}>Upload File data</h2>
                    <p style={this.stylePPOpup}>Silahkan upload file tersebut dalam bentuk <b>.xls</b> atau <b>.xlsx</b>.</p>
                    <div className="row" style={{ float: "right" }}>
                      <FileUpload name="UploadXls"
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        customUpload={true}
                        uploadHandler={this.uploadFileXls}
                        mode="basic"
                        auto={true}
                        chooseLabel="Upload Xls" />
                    </div>
                  </div>
                  <hr />
                  <div className="row">
                    <h2 style={this.stylePPOpup}  >Salin data anda</h2>
                    <p style={this.stylePPOpup}>Gunakan aplikasi yang compatible untuk melakukan edit file berformat Excel</p>
                    <p style={this.stylePPOpup}>(cth Microsoft Excel, dll)</p>
                    <p style={this.stylePPOpup}>Pastikan anda mengisi pada kolom yang tepat.</p>
                  </div>

                  <hr />
                  <div className="row">
                    <h2>Download File template</h2>
                    <p style={this.stylePPOpup}>Template ini memiliki kolom heading yang anda butuhkan untuk mengimpor</p>
                    <p style={this.stylePPOpup}>data kapasitas tempat dan kapasitas ops gerai</p>
                    <div className="row" style={{ float: "right" }}>
                      <Button
                        className="p-button-primary mr-2"
                        label="Template Data"
                        onClick={() => this.downloadTemplateXls()}
                      />
                    </div>
                  </div>

                </div>
              </Grid>
            </Flex>

          </Flex>
        </Dialog>

        <Growl ref={(el) => (this.growl = el)} style={{ marginTop: "75px" }} />


        <Flex className="card w-full">
          <Flex className="w-full" column>
            <CapacityPickupFilterContainer
              ref={this.refFilter}
            // footerTotal={this.state.footerTotal}
            />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary mr-2"
                label="Export Csv"
                icon={
                  this.state.exportExcelLoading ? "pi pi-spin pi-spinner" : ""
                }
                onClick={() => this.downloadCsv()}
                disabled={this.state.exportExcelLoading}
              />

              <Button
                className="p-button-primary mr-2"
                label="Upload Data"
                onClick={() => this.prepareUploadData()}
                disabled={this.state.exportExcelLoading}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card monitor-pod-table-container">
          <div className="row">
            <div className="col-md-12">
              <DataTable
                ref={(el) => (this.dt = el)}
                emptyMessage={"Tidak ada data untuk ditampilkan."}
                scrollable={true}
                paginator={true}
                rows={10}
                rowsPerPageOptions={[5, 10, 20, 50, 100]}
                scrollHeight="550px"
                className="mt-4"
                value={this.dataSource.data}
                loading={this.dataSource.loading}
                sortField={this.state.sortField}
                sortOrder={this.state.sortOrder}
                expandedRows={this.state.expandedRows}
                onRowToggle={(e) => this.onRowToggle(e)}
                rowExpansionTemplate={this.rowExpansionTemplate}
                headerColumnGroup={headerGroup}
                dataKey="branch_id"
                onSort={(e) => {
                  this.onSorting(e);
                }}
              >
                <Column
                  expander={true}
                  // frozen={true}
                  style={{ width: "50px", height: "40px" }}
                />
                <Column
                  header="#"
                  style={{
                    width: "50px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  body={(row) => {
                    return (
                      <Button type="button" icon="pi pi-pencil" className="p-button-warning"
                        onClick={async () => {
                          const initModal = row;
                          await this.setState({
                            modal: initModal,
                            showCapacityDialog: true,
                          });

                          setTimeout(function () { }, 3000);
                        }}>
                      </Button>);
                  }}
                />
                <Column
                  field="province_name"
                  header="Province"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="city_name"
                  header="Kota"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="branch_name"
                  header="Cabang"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />

                <Column
                  field="district_name"
                  header="kecamatan"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />

                <Column
                  field="branch_code"
                  header="Kode Analitic"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="pu_cap"
                  header="PU Cap."
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />

                <Column
                  field="is_active"
                  header="Active"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="pu_ops_cap"
                  header="PU OPS Cap Terdaftar"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="pu_ops_cap_assign"
                  header="PU OPS Cap Assign"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}

                />

                <Column
                  field="jumlahPaket"
                  header="Jumlah Paket"
                  sortable={false}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top"
                  }}
                  body={(row, column: ColumnProps) => {
                    const pctFloat = row.total_pick + row.dropped + row.total_cash;
                    return <p>{pctFloat}</p>;
                  }}
                />
                <Column
                  field="total_volume"
                  header="Volume Total"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="yesterday"
                  header="Resi Kemarin"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="manifest"
                  header="Manifest"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />


                <Column
                  field="total_cash"
                  header="Total Cash"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  field="total_pick"
                  header="Total Pick"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />

                <Column
                  field="dropped"
                  header="Total Drop"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />


                <Column
                  field="pickup_point"
                  header="Titik PU"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />

                <Column
                  field="pu_target"
                  header="Target"
                  sortable={true}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  header="Target Driver"
                  field="pu_ops_cap_driver"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />
                <Column
                  header="Target Freelance"
                  field="pu_ops_cap_freelance"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                />


                <Column
                  field="capvspaket"
                  header="Over/Under Capacity"
                  sortable={false}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top"
                  }}
                  body={(row, column: ColumnProps) => {

                    const pctFloat = row.cap_tempat_pct;
                    let backgroundColorStyle = {};
                    if (pctFloat < 81) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.yellow };
                    } else if (pctFloat >= 81 && pctFloat <= 90) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.lGreen };
                    } else if (pctFloat > 90 && pctFloat <= 110) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.green };
                    } else if (pctFloat > 110 && pctFloat <= 120) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.brown };
                    } else if (pctFloat > 120) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.red };
                    }

                    return <p style={backgroundColorStyle}>{pctFloat} %</p>;
                  }}
                />
                <Column
                  field="opsvspaket"
                  header="Over/Under Ops"
                  sortable={false}
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  body={(row, column: ColumnProps) => {

                    const pctFloat = row.cap_person_pct;

                    let backgroundColorStyle = {};

                    if (pctFloat < 81) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.red };
                    } else if (pctFloat > 81 && pctFloat <= 90) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.brown };
                    } else if (pctFloat > 90 && pctFloat <= 106) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.green };
                    } else if (pctFloat > 106 && pctFloat <= 110) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.lGreen };
                    } else if (pctFloat > 110) {
                      backgroundColorStyle = { backgroundColor: "#" + this.color.yellow };
                    }



                    return <p style={backgroundColorStyle}>{pctFloat} %</p>;
                  }}
                />

                <Column
                  field="pickuppermanent"
                  header="Si Gesit Reguler Terdaftar"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="permanent_assign_count"
                  header="Si Gesit Reguler Assign"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="pikcupprobation"
                  header="Si Gesit Reguler Probation Terdaftar"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="probation_assign_count"
                  header="Si Gesit Reguler Probation Assign"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="pickupfreelance"
                  header="Si Gesit Freelance Terdaftar"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="freelance_assign_count"
                  header="Si Gesit Freelance Assign"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="pickupdriverpermanent"
                  header="Driver Terdaftar"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="driver_assign_count"
                  header="Driver Assign"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="pickupdriverprobation"
                  header="Driver Probation Terdaftar"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                // style={{ width: "4vw" }}
                />
                <Column
                  field="driver_assign_probation_count"
                  header="Driver Probation Assign"
                  style={{
                    width: "130px",
                    textAlign: "center",
                    "vertical-align": "text-top",
                  }}
                  filter={false}
                />
              </DataTable>
            </div>
          </div>
        </div>
      </div >
    );
  }
}
